import React, { useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  useTheme,
} from "@mui/material";
import {
  Search,
  GetApp,
  Print,
  ViewColumn,
  FilterList,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";
import { tokensDark, tokensLight } from "theme";
import { ToastContainer } from "react-toastify";
import { useGetFoodItemsQuery } from "state/api";

const FoodItems = () => {
  const [selectedFoodItem, setSelectedFoodItem] = useState(null);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const tableRef = useRef(null);
  const theme = useTheme();

  const [filterCriteria, setFilterCriteria] = useState({
    name: "",
    measuringUnit: "",
    quantity: "",
    mealType: "",
    veg: "",
    gluten: "",
    dairy: "",
    soy: "",
    peanuts: "",
    fishPrawns: "",
    diabetesOk: "",
    thyroidOk: "",
  });

  const { data: tableData = [], isLoading } = useGetFoodItemsQuery();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [filteredFoodItems, setFilteredFoodItems] = useState(tableData);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const handleRowClick = (foodItem) => {
    if (selectedFoodItem === foodItem) {
      setSelectedFoodItem(null);
    } else {
      setSelectedFoodItem(foodItem);
    }
  };

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleFilterCriteriaChange = (event, field) => {
    const { value } = event.target;
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      [field]: value,
    }));

    const filteredData = tableData.filter((foodItem) => {
      const {
        name,
        measuringUnit,
        quantity,
        mealType,
        veg,
        gluten,
        dairy,
        soy,
        peanuts,
        fishPrawns,
        diabetesOk,
        thyroidOk,
      } = foodItem;
      const lowerCaseSearchQuery = value.toLowerCase();

      return (
        name.toLowerCase().includes(lowerCaseSearchQuery) ||
        measuringUnit.toLowerCase().includes(lowerCaseSearchQuery) ||
        quantity.toLowerCase().includes(lowerCaseSearchQuery) ||
        mealType.toLowerCase().includes(lowerCaseSearchQuery) ||
        veg.toLowerCase().includes(lowerCaseSearchQuery) ||
        gluten.toLowerCase().includes(lowerCaseSearchQuery) ||
        dairy.toLowerCase().includes(lowerCaseSearchQuery) ||
        soy.toLowerCase().includes(lowerCaseSearchQuery) ||
        peanuts.toLowerCase().includes(lowerCaseSearchQuery) ||
        fishPrawns.toLowerCase().includes(lowerCaseSearchQuery) ||
        diabetesOk.toLowerCase().includes(lowerCaseSearchQuery) ||
        thyroidOk.toLowerCase().includes(lowerCaseSearchQuery)
      );
    });

    setFilteredFoodItems(filteredData);
    setCurrentPage(1);
  };

  const handleResetFilterCriteria = () => {
    setFilterCriteria({
      name: "",
      measuringUnit: "",
      quantity: "",
      mealType: "",
      veg: "",
      gluten: "",
      dairy: "",
      soy: "",
      peanuts: "",
      fishPrawns: "",
      diabetesOk: "",
      thyroidOk: "",
    });
    setFilteredFoodItems(tableData);
    setCurrentPage(1);
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleExport = () => {
    const table = tableRef.current;
    const csv = [];
    const rows = table.getElementsByTagName("tr");

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const cols = row.querySelectorAll("td, th");
      const csvRow = [];

      for (let j = 0; j < cols.length; j++) {
        const col = cols[j].innerText
          .replace(/(\r\n|\n|\r)/gm, "")
          .replace(/(\s\s)/gm, " ");
        csvRow.push(col);
      }

      csv.push(csvRow.join(","));
    }

    const csvContent = csv.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "food_items.csv");
  };

  const handlePrint = () => {
    const content = tableRef.current.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value);

    const filteredData = tableData.filter((foodItem) => {
      const {
        name,
        measuringUnit,
        quantity,
        mealType,
        veg,
        gluten,
        dairy,
        soy,
        peanuts,
        fishPrawns,
        diabetesOk,
        thyroidOk,
      } = foodItem;

      const lowerCaseSearchQuery = event.target.value;

      return (
        (name &&
          name.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (measuringUnit &&
          measuringUnit
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (quantity &&
          quantity.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (mealType &&
          mealType.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (veg && veg.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (gluten &&
          gluten.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (dairy &&
          dairy.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (soy && soy.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (peanuts &&
          peanuts.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (fishPrawns &&
          fishPrawns.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (diabetesOk &&
          diabetesOk.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (thyroidOk &&
          thyroidOk.toString().toLowerCase().includes(lowerCaseSearchQuery))
      );
    });
    console.log(filteredData);
    setFilteredFoodItems(filteredData);
    setCurrentPage(1);
  };

  const handleColumnVisibility = () => {
    // Code to handle column visibility
  };

  const handleNavigateToCreateFoodItem = () => {
    navigate("/food-items/create");
  };

  const isDarkMode = theme.palette.mode === "dark";
  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredFoodItems.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredFoodItems.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="flex flex-col justify-between mb-4">
      <ToastContainer
        containerId="toast"
        autoClose={2000}
        position="top-center"
        hideProgressBar={true}
      />
      <ButtonGroup>
        <IconButton>
          <Search />
        </IconButton>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearch}
        />
        <IconButton onClick={handleExport}>
          <GetApp />
        </IconButton>
        <IconButton onClick={handlePrint}>
          <Print />
        </IconButton>
        <IconButton onClick={handleFilterDialogOpen}>
          <FilterList />
        </IconButton>

        <IconButton onClick={() => navigate("/feedbacks/table")}>
          <ViewColumn />
        </IconButton>
      </ButtonGroup>

      <TableContainer ref={tableRef} component={Paper} className="mt-10">
        <Table>
          <TableHead>
            <TableRow
              style={{
                cursor: "pointer",
                backgroundColor: "#06334B",
                color: isDarkMode
                  ? tokensDark.secondary[800]
                  : tokensDark.secondary[700],
              }}
            >
              <TableCell>Name</TableCell>
              <TableCell>Measuring Unit</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Meal Type</TableCell>
              <TableCell>Veg</TableCell>
              <TableCell>Gluten</TableCell>
              <TableCell>Dairy</TableCell>
              <TableCell>Soy</TableCell>
              <TableCell>Peanuts</TableCell>
              <TableCell>Fish/Prawns</TableCell>
              <TableCell>Diabetes OK</TableCell>
              <TableCell>Thyroid OK</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
                        {currentItems.map((foodItem, index) => (
              <TableRow
                key={index}
                onClick={() => handleRowClick(foodItem)}
                className={selectedFoodItem === foodItem ? "selected-row" : ""}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#095271",
                  color: isDarkMode
                    ? tokensDark.secondary[800]
                    : tokensDark.secondary[700],
                }}
              >
                <TableCell>{foodItem.name}</TableCell>
                <TableCell>{foodItem.measuringUnit}</TableCell>
                <TableCell>{foodItem.quantity}</TableCell>
                <TableCell>{foodItem.mealType}</TableCell>
                <TableCell>{foodItem.veg}</TableCell>
                <TableCell>{foodItem.gluten}</TableCell>
                <TableCell>{foodItem.dairy}</TableCell>
                <TableCell>{foodItem.soy}</TableCell>
                <TableCell>{foodItem.peanuts}</TableCell>
                <TableCell>{foodItem.fishPrawns}</TableCell>
                <TableCell>{foodItem.diabetesOk}</TableCell>
                <TableCell>{foodItem.thyroidOk}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <ButtonGroup>
          <Button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </Button>
          {Array.from({ length: totalPages }, (_, i) => (
            <Button
              key={i + 1}
              variant={currentPage === i + 1 ? "contained" : "outlined"}
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </Button>
          ))}
          <Button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </Button>
        </ButtonGroup>
      </div>

      <Dialog
        open={openFilterDialog}
        onClose={handleFilterDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Filter Food Items</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={filterCriteria.name}
            onChange={(e) => handleFilterCriteriaChange(e, "name")}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Measuring Unit"
            value={filterCriteria.measuringUnit}
            onChange={(e) => handleFilterCriteriaChange(e, "measuringUnit")}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Quantity"
            value={filterCriteria.quantity}
            onChange={(e) => handleFilterCriteriaChange(e, "quantity")}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Meal Type"
            value={filterCriteria.mealType}
            onChange={(e) => handleFilterCriteriaChange(e, "mealType")}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Veg"
            value={filterCriteria.veg}
            onChange={(e) => handleFilterCriteriaChange(e, "veg")}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Gluten"
            value={filterCriteria.gluten}
            onChange={(e) => handleFilterCriteriaChange(e, "gluten")}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Dairy"
            value={filterCriteria.dairy}
            onChange={(e) => handleFilterCriteriaChange(e, "dairy")}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Soy"
            value={filterCriteria.soy}
            onChange={(e) => handleFilterCriteriaChange(e, "soy")}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Peanuts"
            value={filterCriteria.peanuts}
            onChange={(e) => handleFilterCriteriaChange(e, "peanuts")}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Fish/Prawns"
            value={filterCriteria.fishPrawns}
            onChange={(e) => handleFilterCriteriaChange(e, "fishPrawns")}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Diabetes OK"
            value={filterCriteria.diabetesOk}
            onChange={(e) => handleFilterCriteriaChange(e, "diabetesOk")}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Thyroid OK"
            value={filterCriteria.thyroidOk}
            onChange={(e) => handleFilterCriteriaChange(e, "thyroidOk")}
            variant="outlined"
            fullWidth
            margin="normal"
          />

          <Button variant="contained" onClick={handleFilterDialogClose}>
            Apply Filter
          </Button>

          <Button
            variant="contained"
            style={{ marginLeft: "8px" }}
            onClick={handleResetFilterCriteria}
          >
            Reset Filters
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FoodItems;
