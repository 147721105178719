import React from "react";
import { Box, Button, useTheme } from "@mui/material";
import {
  useGetCustomersQuery,
  useGetDoctorsQuery,
  useGetPhysioQuery,
} from "state/api";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import rows from "utils/customers";
import { useNavigate, useOutletContext } from "react-router-dom";
import PatientTable from "components/PatientTable";

const PhysiosScreen = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [userData] = useOutletContext();

  const { data, isLoading } = useGetPhysioQuery();
  // console.log("dataCustomers", data)

  const rows = [];
  if (data) {
    data.map((d, index) => {
      const row = {
        id: d._id,
        system_id: index + 1,
        countryName: "India",
        mobile: d.mobile,
        name: d.name,
        role: d.role,
        email: d.email,
      };
      rows.push(row);
    });
  }
  const columns = [
    {
      field: "system_id",
      headerName: "Index",
      flex: 0.5,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "mobile",
      headerName: "Mobile No.",
      flex: 0.5,
    },

    {
      field: "countryName",
      headerName: "Country",
      flex: 0.4,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 0.5,
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="PHYSIOS" subtitle="Find all Physios here!" />
      {/* <Button
         sx={{marginTop: '18px'}}
         variant='contained'
         size='medium'
         onClick={()=>{navigate(`/patient/add`)}}
        >
         Add New Patient
   </Button> */}
      <Box
        mt="20px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        {data && (
          <PatientTable
            shouldExpandRow={false}
            patients={data}
            shouldShowEdit={true}
          />
        )}
      </Box>
    </Box>
  );
};

export default PhysiosScreen;
