import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  useTheme,
} from "@mui/material";
import {
  Search,
  GetApp,
  Print,
  ViewColumn,
  FilterList,
} from "@mui/icons-material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";
import { tokensDark, tokensLight } from "theme";
import { ToastContainer, toast } from "react-toastify";
import { useGetOrdersQuery, useUpdateOrderMutation } from "state/api";
import { ArrowUpward } from "@mui/icons-material";
import { ArrowDownward } from "@mui/icons-material";
import moment from "moment";

const Orders = () => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const tableRef = useRef(null);
  const theme = useTheme();

  const [filterCriteria, setFilterCriteria] = useState({
    user: "",
    products: "",
    status: "",
    couponId: "",
    paymentId: "",
    addons: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const [userData] = useOutletContext();

  const { data: tableData = [], isLoading } = useGetOrdersQuery();
  const [filteredOrders, setFilteredOrders] = useState(tableData);
  const [sortOrder, setSortOrder] = useState("asc");
  const [showEditAmount, setShowEditAmount] = useState(false);
  const [updateOrder] = useUpdateOrderMutation();
  const backgroundColor = theme.palette.primary.light;
  useEffect(() => {
    if (tableData) {
      setFilteredOrders(tableData);
    }
  }, [tableData]);

  const navigate = useNavigate();

  const handleRowClick = (order) => {
    if (selectedOrder === order) {
      setSelectedOrder(null);
    } else {
      setSelectedOrder(order);
    }
  };
  const handleSortOrderChange = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
  };
  const sortTableData = () => {
    const sortedData = [...tableData].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });

    setFilteredOrders(sortedData);
  };

  useEffect(() => {
    sortTableData();
  }, [sortOrder]);

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleFilterCriteriaChange = (event, field) => {
    const { value } = event.target;
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      [field]: value,
    }));

    const filteredData = tableData.filter((order) => {
      const { user, products, status, couponId, paymentId, addons } = order;
      const lowerCaseSearchQuery = event.target.value.toLowerCase();

      return (
        (user &&
          user.name.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        // (products && products.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (status &&
          status.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (couponId &&
          couponId.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (paymentId &&
          paymentId.toString().toLowerCase().includes(lowerCaseSearchQuery))
        // (addons && addons.toString().toLowerCase().includes(lowerCaseSearchQuery))
      );
    });

    setFilteredOrders(filteredData);
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value);

    const filteredData = tableData.filter((order) => {
      const { user, products, status, couponId, paymentId, addons } = order;
      const lowerCaseSearchQuery = event.target.value.toLowerCase();

      return (
        (user &&
          user.name.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        // (products && products.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (status &&
          status.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (couponId &&
          couponId.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (paymentId &&
          paymentId.toString().toLowerCase().includes(lowerCaseSearchQuery))
        // (addons && addons.toString().toLowerCase().includes(lowerCaseSearchQuery))
      );
    });

    setFilteredOrders(filteredData);
  };

  const handleDownload = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," + convertToCSV(filteredOrders);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "orders.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  };

  const convertToCSV = (data) => {
    const header = [
      "User",
      "Products",
      "Status",
      "Coupon ID",
      "Payment ID",
      "Add-Ons",
    ];
    const rows = data.map((order) => [
      order.user,
      order.products,
      order.status,
      order.couponId,
      order.paymentId,
      order.addons,
    ]);

    const csvData = [header, ...rows]
      .map((row) => row.map((value) => `"${value}"`).join(","))
      .join("\n");
    return csvData;
  };

  const handlePrint = () => {
    const content = tableRef.current.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleResetFilterCriteria = () => {
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      user: "",
      products: "",
      status: "",
      couponId: "",
      paymentId: "",
      addons: "",
    }));
    setFilteredOrders(tableData);
  };

  const handleUpdateAmount = (order, amount) => {
    updateOrder({ id: order._id, amountReceived: amount })
      .unwrap()
      .then((d) => {
        setSelectedOrder(null);
        const updatedArray = filteredOrders.map((item) =>
          item._id === order._id ? { ...item, amountReceived: amount } : item
        );

        setFilteredOrders(updatedArray);
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };
  const isDarkMode = theme.palette.mode === "dark";

  // Pagination logic
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedOrders = filteredOrders.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredOrders.length / pageSize);

  const EditAmountModal = ({ order }) => {
    const [amount, setAmount] = useState(order.amount ? order.amount : 0);

    return (
      <div className='fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50'>
        <div className='bg-white rounded-lg p-6'>
          <h2 className='text-lg text-gray-700 font-semibold mb-4'>
            Amount Update
          </h2>
          <input
            type='number'
            placeholder='Enter Amount'
            className='w-full border rounded-lg px-3 py-2 text-gray-700 mt-2 mb-2'
            min={0}
            onChange={(e) => {
              setAmount(Number.parseInt(e.target.value));
            }}
          />

          {/* <select>
                       <option disabled value="choose">Choose</option>
           {
            data && data.map((p)=>{
              return <option key={p._id} value={JSON.stringify(p)}>{p.account ? p.account.name : p.name}</option>
            })
           }
          </select> */}

          <div className='flex gap-2 justify-end mt-4'>
            <button
              onClick={() => {
                setSelectedOrder(null);
              }}
              className='bg-red-500 hover:bg-red-600 text-white rounded-lg px-4 py-2'>
              Cancel
            </button>
            <button
              onClick={() => {
                handleUpdateAmount(order, amount);
              }}
              className='bg-blue-500 hover:bg-blue-600 text-white rounded-lg px-4 py-2'>
              Update Amount
            </button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className='flex flex-col w-full h-full justify-between mb-4 p-4'>
      <ToastContainer
        containerId='toast'
        autoClose={2000}
        position='top-center'
        hideProgressBar={true}
      />
      {selectedOrder && <EditAmountModal order={selectedOrder} />}
      <ButtonGroup>
        <IconButton>
          <Search />
        </IconButton>
        <TextField
          label='Search'
          variant='outlined'
          size='small'
          value={searchQuery}
          onChange={handleSearch}
        />
        <IconButton onClick={handleDownload}>
          <GetApp />
        </IconButton>
        <IconButton onClick={handlePrint}>
          <Print />
        </IconButton>
        <IconButton>
          <ViewColumn />
        </IconButton>
        <IconButton onClick={handleFilterDialogOpen}>
          <FilterList />
        </IconButton>
      </ButtonGroup>

      {/* Table */}
      <TableContainer
        ref={tableRef}
        style={{ marginTop: "16px", height: "500px", scrollbarWidth: 0 }}
        component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: backgroundColor,
                color: isDarkMode
                  ? tokensDark.secondary[800]
                  : tokensDark.secondary[700],
                ":hover": {
                  backgroundColor: isDarkMode
                    ? tokensDark.primary[300]
                    : tokensDark.grey[200],
                },
              }}>
              <TableCell>User</TableCell>
              <TableCell>Products</TableCell>
              <TableCell>Status</TableCell>
              {/* <TableCell>Coupon ID</TableCell> */}
              <TableCell>Payment ID</TableCell>
              <TableCell>Amount Received</TableCell>
              <TableCell>Add-Ons </TableCell>
              <TableCell onClick={handleSortOrderChange}>
                Created At{" "}
                {sortOrder === "asc" ? <ArrowDownward /> : <ArrowUpward />}{" "}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedOrders.map((order, index) => (
              <TableRow
                sx={{
                  backgroundColor: backgroundColor,
                  color: isDarkMode
                    ? tokensDark.secondary[800]
                    : tokensDark.secondary[700],
                  ":hover": {
                    backgroundColor: isDarkMode
                      ? tokensDark.primary[300]
                      : tokensDark.grey[200],
                  },
                }}
                key={order._id}
                onClick={() => handleRowClick(order)}>
                <TableCell>
                  {order.user ? order.user.name : "No Account"}
                </TableCell>
                <TableCell>
                  {order.products.map((p) => p.name).join(", ")}
                </TableCell>
                <TableCell>{order.status}</TableCell>
                {/* <TableCell>{order.couponId}</TableCell> */}
                <TableCell>
                  {order.paymentId ? order.paymentId : "No Transaction"}
                </TableCell>
                <TableCell>
                  {order.amountReceived ? order.amountReceived : 0}
                </TableCell>
                <TableCell>
                  {order.addons.map((a) => a.itemName).join(", ")}
                </TableCell>
                <TableCell>
                  {moment(order.createdAt).format("DD/MM/YYYY HH:mm")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <div className='fjustify-center mt-4 overflow-scroll'>
        <ButtonGroup>
          <Button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}>
            Previous
          </Button>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map(
            (pageNumber) => (
              <Button
                key={pageNumber}
                disabled={currentPage === pageNumber}
                onClick={() => handlePageChange(pageNumber)}>
                {pageNumber}
              </Button>
            )
          )}
          <Button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}>
            Next
          </Button>
        </ButtonGroup>
      </div>

      {/* Filter Dialog */}
      <Dialog
        open={openFilterDialog}
        onClose={handleFilterDialogClose}
        maxWidth='md'
        fullWidth>
        <DialogTitle>Filter Table Data</DialogTitle>
        <DialogContent>
          <TextField
            margin='dense'
            label='User'
            fullWidth
            variant='outlined'
            value={filterCriteria.user}
            onChange={(e) => handleFilterCriteriaChange(e, "user")}
          />

          {/* <TextField
            margin="dense"
            label="Products"
            fullWidth
            variant="outlined"
            value={filterCriteria.products}
            onChange={(e) => handleFilterCriteriaChange(e, 'products')}
          /> */}

          <TextField
            margin='dense'
            label='Status'
            fullWidth
            variant='outlined'
            value={filterCriteria.status}
            onChange={(e) => handleFilterCriteriaChange(e, "status")}
          />

          {/* <TextField
            margin="dense"
            label="Coupon ID"
            fullWidth
            variant="outlined"
            value={filterCriteria.couponId}
            onChange={(e) => handleFilterCriteriaChange(e, 'couponId')}
          /> */}

          <TextField
            margin='dense'
            label='Payment ID'
            fullWidth
            variant='outlined'
            value={filterCriteria.paymentId}
            onChange={(e) => handleFilterCriteriaChange(e, "paymentId")}
          />

          {/* <TextField
            margin="dense"
            label="Add-Ons"
            fullWidth
            variant="outlined"
            value={filterCriteria.addons}
            onChange={(e) => handleFilterCriteriaChange(e, 'addons')}
          /> */}

          <Button variant='contained' onClick={handleFilterDialogClose}>
            Apply Filter
          </Button>

          <Button
            variant='contained'
            style={{ marginLeft: "8px" }}
            onClick={handleResetFilterCriteria}>
            Reset Filters
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Orders;
