import { useTheme } from "@mui/material";
import { useState } from "react";
import moment from "moment";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetPartnerQuery, useUpdatePartnerProfileMutation } from "state/api";
import { AccountCircle } from "@mui/icons-material";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "firebaseConfig";

const PartnerProfile = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const styles = {
    container: {
      background: `${isDarkMode ? theme.palette.background.alt : "#f3f3f3"}`,
      padding: "20px",
      borderRadius: "10px",
      margin: "14px",
    },
    form: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridGap: "20px",
      margin: "20px 0",
    },
    label: {
      display: "block",
      marginBottom: "2px",
      gridColumn: "span 1",
      fontWeight: "bold",
      fontSize: "14px",
    },
    input: {
      width: "100%",
      padding: "10px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      color: "black",
      marginBottom: "10px",
    },
    inputTimings: {
      margin: "4px",
      padding: "10px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      color: `${!isDarkMode ? "black" : "#f3f3f3"}`,
      marginBottom: "10px",
    },
    button: {
      padding: "10px 20px",
      background: "#4CAF50",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    buttonCancel: {
      padding: "10px 20px",
      background: "red",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      marginLeft:"8px"
    },
    labelMain: {
      display: "block",
      marginBottom: "10px",
      gridColumn: "span 1",
      fontWeight: "bold",
      fontSize: "18px",
    },
  };

  const [additionalDegree, setAdditionalDegree] = useState([]);
  const [additionalSpecialization, setAdditionalSpecialization] = useState([]);
  const [additionalConsultationTimings, setAdditionalConsultationTimings] =
    useState([]);
  const [updatePartner] = useUpdatePartnerProfileMutation()
    const [status, setStatus] = useState("")
 const [image, setImage] = useState()

    const navigate = useNavigate()
  // Event handler for "Add More" button click
  const handleAddMoreDegree = () => {
    setAdditionalDegree([...additionalDegree, ""]);
  };

  const handleAddMoreSpecialization = () => {
    setAdditionalSpecialization([...additionalSpecialization, ""]);
  };

  const handleAddMoreConsultationTimings = () => {
    setAdditionalConsultationTimings([...additionalConsultationTimings, ""]);
  };

  const handleAdditionalDegreeChange = (index, value) => {
    const updatedDegree = [...additionalDegree];
    updatedDegree[index] = value;
    setAdditionalDegree(updatedDegree);
  };

  const handleAdditionalSpecializationChange = (index, value) => {
    
    const updatedSpecialization = [...additionalSpecialization];
    updatedSpecialization[index] = value;
    setAdditionalSpecialization(updatedSpecialization);
  };

  const handleAdditionalConsultationTimingsChange = (index, value) => {
    const updatedConsultationTimings = [...additionalConsultationTimings];
    updatedConsultationTimings[index] = value;
    setAdditionalConsultationTimings(updatedConsultationTimings);
  };

  const handleSubmit = () => {
    if (image) {
      const storageRef = ref(storage, `/Doctors/${partner.name}`)
      const uploadTask = uploadBytesResumable(storageRef, image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
            const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
        },
        (err) => console.log(err),
        () => {
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              const partner = {
                ...data,
                photoUrl: url,
                degree:additionalDegree.length > 0
                ? [...data.degree, ...additionalDegree]
                : data.degree,
                specialization: additionalSpecialization.length > 0
                ? [...data.specialization, ...additionalSpecialization]
                : data.specialization,
                consultationTimings:
                  additionalConsultationTimings.length > 0
                    ? [...data.consultationTimings, ...additionalConsultationTimings]
                    : data.consultationTimings,
              }
                updatePartner(partner).unwrap().then((data) => {
                    
                  alert("Profile updated!")
                    window.location.replace('/')

                }).catch((err) => {
                  alert("Could not update the profile!")
                })
            });
        }
    );
    } else {
      const partner = {
        ...data,
       
        degree:additionalDegree.length > 0
        ? [...data.degree, ...additionalDegree]
        : data.degree,
        specialization: additionalSpecialization.length > 0
        ? [...data.specialization, ...additionalSpecialization]
        : data.specialization,
        consultationTimings:
          additionalConsultationTimings.length > 0
            ? [...data.consultationTimings, ...additionalConsultationTimings]
            : data.consultationTimings,
      }
        updatePartner(partner).unwrap().then((data) => {
            
          alert("Profile updated!")
            window.location.replace('/')

        }).catch((err) => {
            alert("Could not update the profile!")
        })
    }

  };

  const location = useLocation();
  const partner = location.state.account;
  const { data } = useGetPartnerQuery(partner._id);
  const handleChange = (e) => {
    // Handle input change here
  };
  console.log(data);

  
  return (
    <div style={styles.container}>
      {data && (
        <div style={styles.form}>
          <label style={styles.label}>
          {
            data.photoUrl &&   <img
            src={data.photoUrl}
            // style={{height:"100px", width:"100px", marginBottom:"8px"}}
          />
          }
          {
            !data.photoUrl && 
            <AccountCircle style={{height:"100px", width:"100px"}}/>
          }

            <input
              accept="image/png, image/jpeg"
              type="file"
              name="name"
              onChange={(e)=>{setImage(e.target.files[0])}}
              style={styles.input}
            />
          </label>
          <div style={styles.label}>
            <label style={styles.label}>
              Name:
              <input
                type="text"
                name="account"
                placeholder="Enter name"
                defaultValue={partner.name}
                onChange={handleChange}
                style={styles.input}
              />
            </label>
            <label style={styles.label}>
              Mobile:
              <input
                type="tel"
                name="mobile"
                defaultValue={partner.mobile}
                placeholder="Enter mobile number with country code"
                onChange={handleChange}
                style={styles.input}
              />
            </label>
            <label style={styles.label}>
              WhatsApp:
              <input
                type="text"
                name="whatsapp"
                defaultValue={partner.mobile}
                placeholder="Enter WhatsApp number"
                onChange={handleChange}
                style={styles.input}
              />
            </label>
            <label style={styles.label}>
              Status:
              <select
                name="status"
                defaultValue={data.status}
                onChange={handleChange}
                style={styles.input}
              >
                <option value="" disabled>
                  Select status
                </option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
                <option value="hidden">Hidden</option>
              </select>
            </label>

            <label style={styles.label}>
              Email:
              <input
                type="email"
                name="email"
                placeholder="Enter email"
                onChange={handleChange}
                defaultValue={partner.email}
                style={styles.input}
              />
            </label>

            <label style={styles.label}>
              Date of Birth:
              <br />
              <span>{moment(data.dateOfBirth).format("DD MMMM YYYY")}</span>
              <input
                type="date"
                name="dob"
                placeholder="Change date of birth"
                defaultValue={partner.dateOfBirth}
                onChange={handleChange}
                style={styles.input}
              />
            </label>

            <label style={styles.label}>
              Description:
              <textarea
                name="description"
                placeholder="Enter a description"
                onChange={handleChange}
                style={styles.input}
              ></textarea>
            </label>
          </div>
          {/* Add more input fields for the remaining fields */}
          {/* WhatsApp, Mobile, AdhaarUrl, CurrentAddress, City, Pincode, State, Degree, DegreeUrl, YearOfGraduation, MCIREG, LicenseNumber, YearsOfExperience, Specialization, CurrentEngagement, Pan, PanUrl, AccountHolderName, BankName, BranchName, AccountNumber, BankIFSC, OnlineEvaluationFees, TelePhysioSessionFee, HomeVisits, HomeVisitFee, ConsultationTimings, Facebook, Twitter, LinkedIn, Instagram */}
          <div style={{ gridColumn: "span 2" }}>
            <label style={styles.labelMain}>Profile Info</label>
          </div>

          <div style={{ gridColumn: "span 2" }}>
            <label style={styles.labelMain}>Degree:</label>
            <div className="grid grid-cols-6 text-center">
              {data.degree.map((degree, index) => (
                <span key={index} style={styles.inputTimings}>
                  {degree}
                </span>
              ))}
              {additionalDegree.map((degree, index) => (
                <input
              
                  key={index}
                  type="text"
                  placeholder="Enter degree"
                  value={degree}
                  onChange={(e) =>
                    handleAdditionalDegreeChange(index, e.target.value)
                  }
                  style={styles.input}
                />
              ))}
            </div>
            <div className="mt-2">
              <span
                className="cursor-pointer border rounded-md p-2 border-gray-600"
                onClick={handleAddMoreDegree}
              >
                Add More
              </span>
            </div>
          </div>

          <label style={styles.label}>
            Degree URL:
            <input
              type="text"
              name="degreeUrl"
              placeholder="Enter degree URL"
              onChange={handleChange}
              defaultValue={data.degreeUrl}
              style={styles.input}
            />
          </label>

          <label style={styles.label}>
            Year of Graduation:
            <input
              type="text"
              name="yearOfGraduation"
              placeholder="Enter year of graduation"
              onChange={handleChange}
              defaultValue={data.yearOfGraduation}
              style={styles.input}
            />
          </label>

          <label style={styles.label}>
            MCI Registration:
            <input
              type="text"
              name="mcireg"
              placeholder="Enter MCI registration number"
              onChange={handleChange}
              defaultValue={data.mciReg}
              style={styles.input}
            />
          </label>

          <label style={styles.label}>
            License Number:
            <input
              type="text"
              name="licenseNumber"
              placeholder="Enter license number"
              onChange={handleChange}
              defaultValue={data.licenseNumber}
              style={styles.input}
            />
          </label>

          <label style={styles.label}>
            Years of Experience:
            <input
              type="text"
              name="yearsOfExperience"
              placeholder="Enter years of experience"
              onChange={handleChange}
              defaultValue={data.yearsOfExperience}
              style={styles.input}
            />
          </label>

          <div style={{ gridColumn: "span 2" }}>
            <label style={styles.labelMain}>Specialization:</label>
            <div className="grid grid-cols-6 text-center">
              {data.specialization.map((specialization, index) => (
                <span key={index} style={styles.inputTimings}>
                  {specialization}
                </span>
              ))}
              {additionalSpecialization.map((specialization, index) => (
                <input
                  key={index}
                  type="text"
                  placeholder="Enter specialization"
                  value={specialization}
                  onChange={(e) =>
                    handleAdditionalSpecializationChange(index, e.target.value)
                  }
                  style={styles.input}
                />
              ))}
            </div>
            <div className="mt-2">
              <button
                className="cursor-pointer border rounded-md p-2 border-gray-600"
                onClick={handleAddMoreSpecialization}
              >
                Add More
              </button>
            </div>
          </div>

          <label style={styles.label}>
            Current Engagement:
            <input
              type="text"
              name="currentEngagement"
              placeholder="Enter current engagement"
              onChange={handleChange}
              defaultValue={data.currentEngagement}
              style={styles.input}
            />
          </label>

          {/* Personal Details */}

          <div style={{ gridColumn: "span 2" }}>
            <label style={styles.labelMain}>Personal Details</label>
          </div>

          <label style={styles.label}>
            Aadhaar URL:
            <input
              type="text"
              name="adhaarUrl"
              placeholder="Enter Aadhaar URL"
              defaultValue={data.adhaarUrl}
              onChange={handleChange}
              style={styles.input}
            />
          </label>
          <label style={styles.label}>
            PAN URL:
            <input
              type="text"
              name="panUrl"
              placeholder="Enter PAN URL"
              onChange={handleChange}
              defaultValue={data.panUrl}
              style={styles.input}
            />
          </label>
          <label style={styles.label}>
            State:
            <input
              type="text"
              name="state"
              placeholder="Enter state"
              onChange={handleChange}
              defaultValue={data.state}
              style={styles.input}
            />
          </label>

          <label style={styles.label}>
            City:
            <input
              type="text"
              name="city"
              placeholder="Enter city"
              defaultValue={data.city}
              onChange={handleChange}
              style={styles.input}
            />
          </label>

          <label style={styles.label}>
            Pincode:
            <input
              type="text"
              name="pincode"
              placeholder="Enter pincode"
              onChange={handleChange}
              defaultValue={data.pincode}
              style={styles.input}
            />
          </label>

          <label style={styles.label}>
            Current Address:
            <textarea
              name="currentAddress"
              placeholder="Enter current address"
              onChange={handleChange}
              defaultValue={data.currentAddress}
              style={styles.input}
            ></textarea>
          </label>

          {/* Add more input fields for the remaining fields */}
          {/* Degree, DegreeUrl, YearOfGraduation, MCIREG, LicenseNumber, YearsOfExperience, Specialization, CurrentEngagement, Pan, PanUrl, AccountHolderName, BankName, BranchName, AccountNumber, BankIFSC, OnlineEvaluationFees, TelePhysioSessionFee, HomeVisits, HomeVisitFee, ConsultationTimings, Facebook, Twitter, LinkedIn, Instagram */}

          {/* Add more input fields for the remaining fields */}
          {/* Pan, PanUrl, AccountHolderName, BankName, BranchName, AccountNumber, BankIFSC, OnlineEvaluationFees, TelePhysioSessionFee, HomeVisits, HomeVisitFee, ConsultationTimings, Facebook, Twitter, LinkedIn, Instagram */}

          {/* Bank Details */}
          {/* <label style={styles.label}>
        Account Holder Name:
        <input
          type="text"
          name="accountHolderName"
          placeholder="Enter account holder name"
          onChange={handleChange}
          style={styles.input}
        />
      </label>

      <label style={styles.label}>
        Bank Name:
        <input
          type="text"
          name="bankName"
          placeholder="Enter bank name"
          onChange={handleChange}
          style={styles.input}
        />
      </label>

      <label style={styles.label}>
        Branch Name:
        <input
          type="text"
          name="branchName"
          placeholder="Enter branch name"
          onChange={handleChange}
          style={styles.input}
        />
      </label>

      <label style={styles.label}>
        Account Number:
        <input
          type="text"
          name="accountNumber"
          placeholder="Enter account number"
          onChange={handleChange}
          style={styles.input}
        />
      </label>

      <label style={styles.label}>
        Bank IFSC:
        <input
          type="text"
          name="bankIFSC"
          placeholder="Enter bank IFSC"
          onChange={handleChange}
          style={styles.input}
        />
      </label> */}

          <label style={styles.label}>
            Online Evaluation Fees:
            <input
              type="text"
              name="onlineEvaluationFees"
              placeholder="Enter online evaluation fees"
              defaultValue={"Rs." + data.onlineEvaluationFees}
              onChange={handleChange}
              style={styles.input}
            />
          </label>

          <label style={styles.label}>
            Tele Physio Session Fee:
            <input
              type="text"
              name="telePhysioSessionFee"
              placeholder="Enter tele physio session fee"
              onChange={handleChange}
              defaultValue={"Rs." + data.telePhysioSessionFee}
              style={styles.input}
            />
          </label>

          <label style={styles.label}>
            Home Visits:
            <input
              type="text"
              name="homeVisits"
              defaultValue={data.homeVisits}
              placeholder="Enter home visits"
              onChange={handleChange}
              style={styles.input}
            />
          </label>

          <label style={styles.label}>
            Home Visit Fee:
            <input
              type="text"
              name="homeVisitFee"
              placeholder="Enter home visit fee"
              defaultValue={"Rs." + data.homeVisitFee}
              onChange={handleChange}
              style={styles.input}
            />
          </label>

          <div style={{ gridColumn: "span 2" }}>
            <label style={styles.label}>Consultation Timings:</label>
            <div className="grid grid-cols-6 text-center">
              {data.consultationTimings.map((t,i) => {
                return <span key={i} style={styles.inputTimings}>{t}</span>;
              })}
              {
                additionalConsultationTimings.map((t, i)=>{
                 return <input
                  type="text"
                  name="homeVisitFee"
                  placeholder="10:00 am - 11:00 am"
                  key={i}
                  onChange={(e)=>{handleAdditionalConsultationTimingsChange(i, e.target.value)}}
                  style={styles.input}
                />
                    
                })
              }
            </div>
            <div className="mt-2">
              <span onClick={handleAddMoreConsultationTimings} className=" cursor-pointer border rounded-md p-2 border-gray-600">
                Add More
              </span>
            </div>
          </div>

          {/* Social Links */}
          {/* <label style={styles.label}>
        Facebook:
        <input
          type="text"
          name="facebook"
          placeholder="Enter Facebook URL"
          onChange={handleChange}
          style={styles.input}
        />
      </label>

      <label style={styles.label}>
        Twitter:
        <input
          type="text"
          name="twitter"
          placeholder="Enter Twitter URL"
          onChange={handleChange}
          style={styles.input}
        />
      </label>

      <label style={styles.label}>
        LinkedIn:
        <input
          type="text"
          name="linkedin"
          placeholder="Enter LinkedIn URL"
          onChange={handleChange}
          style={styles.input}
        />
      </label>

      <label style={styles.label}>
        Instagram:
        <input
          type="text"
          name="instagram"
          placeholder="Enter Instagram URL"
          onChange={handleChange}
          style={styles.input}
        />
      </label> */}
        </div>
     
     )}
 {
   data && <button style={styles.button} onClick={handleSubmit}>
        Submit
      </button>}

     { !data && <p className="mb-8">No Data Found!</p>}
      <button style={styles.buttonCancel} onClick={()=>{navigate(-1)}}>
        Back
      </button>
    </div>
  );
};

export default PartnerProfile;
