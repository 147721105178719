import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  useTheme,
  CircularProgress,
} from "@mui/material";
import {
  Search,
  GetApp,
  Print,
  ViewColumn,
  FilterList,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";
import { tokensDark, tokensLight } from "theme";
import { ToastContainer } from "react-toastify";
import { useGetPricingQuery } from "state/api"; // Assuming this is the correct import path
import { ArrowUpward } from "@mui/icons-material";
import { ArrowDownward } from "@mui/icons-material";

const Pricing = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const tableRef = useRef(null);
  const theme = useTheme();
  const navigate = useNavigate();

  const { data: pricingData, isLoading } = useGetPricingQuery(); // Fetch pricing data using the query hook

  const [filterCriteria, setFilterCriteria] = useState({
    inventoryId: "",
    basePrice: "",
    currency: "",
  });

  const [filteredItems, setFilteredItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    if (pricingData) {
      setFilteredItems(pricingData);
    }
  }, [pricingData]);

  const handleRowClick = (item) => {
    if (selectedItem === item) {
      setSelectedItem(null);
    } else {
      setSelectedItem(item);
    }
  };

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleSortOrderChange = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
  };
  const sortTableData = () => {
    const sortedData = [...pricingData].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
    console.log(sortedData);
    setFilteredItems(sortedData);
  };

  useEffect(() => {
    sortTableData();
  }, [sortOrder]);

  const handleFilterCriteriaChange = (event, field) => {
    const { value } = event.target;
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      [field]: value,
    }));
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value);

    const filteredData = pricingData.filter((item) => {
      const { inventoryId, basePrice, currency } = item;
      const lowerCaseSearchQuery = value.toLowerCase();

      return (
        inventoryId.toString().toLowerCase().includes(lowerCaseSearchQuery) ||
        basePrice.toString().toLowerCase().includes(lowerCaseSearchQuery) ||
        currency.toString().toLowerCase().includes(lowerCaseSearchQuery)
      );
    });

    setFilteredItems(filteredData);
    setCurrentPage(1); // Reset to the first page
  };

  const handleDownload = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," + convertToCSV(filteredItems);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "pricing.csv");
    document.body.appendChild(link);
    link.click();
  };

  const convertToCSV = (data) => {
    const header = ["Inventory ID", "Base Price", "Currency"];
    const rows = data.map((item) => [
      item.inventoryId,
      item.basePrice,
      item.currency,
    ]);

    const csvData = [header, ...rows]
      .map((row) => row.map((value) => `"${value}"`).join(","))
      .join("\n");
    return csvData;
  };

  const handlePrint = () => {
    const content = tableRef.current.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleResetFilterCriteria = () => {
    setFilterCriteria({
      inventoryId: "",
      basePrice: "",
      currency: "",
    });
    setFilteredItems(pricingData);
    setCurrentPage(1); // Reset to the first page
  };

  const isDarkMode = theme.palette.mode === "dark";

  // Calculate total pages
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  // Calculate current page items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="flex flex-col justify-between mb-4">
      <ToastContainer
        containerId="toast"
        autoClose={2000}
        position="top-center"
        hideProgressBar={true}
      />
      <ButtonGroup>
        <IconButton>
          <Search />
        </IconButton>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearch}
        />
        <IconButton onClick={handleDownload}>
          <GetApp />
        </IconButton>
        <IconButton onClick={handlePrint}>
          <Print />
        </IconButton>
        <IconButton>
          <ViewColumn />
        </IconButton>
        <IconButton onClick={handleFilterDialogOpen}>
          <FilterList />
        </IconButton>
      </ButtonGroup>

      {/* Table */}
      {isLoading ? (
        <CircularProgress /> // Show a loading indicator while data is being fetched
      ) : (
        <TableContainer
          ref={tableRef}
          component={Paper}
          sx={{
            backgroundColor: theme.palette.background.paper,
            marginTop: "50px",
          }}
        >
          <Table>
            <TableHead>
              <TableRow
                style={{
                  cursor: "pointer",
                  backgroundColor: "#06334B",
                  color: isDarkMode
                    ? tokensDark.secondary[800]
                    : tokensDark.secondary[700],
                }}
              >
                <TableCell>Inventory ID</TableCell>
                <TableCell>Base Price</TableCell>
                <TableCell onClick={handleSortOrderChange}>
                  Currency{" "}
                  {sortOrder === "asc" ? <ArrowDownward /> : <ArrowUpward />}{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentItems.map((item) => (
                <TableRow
                  key={item.inventoryId}
                  onClick={() => handleRowClick(item)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#095271",
                    color: isDarkMode
                      ? tokensDark.secondary[800]
                      : tokensDark.secondary[700],
                  }}
                >
                  <TableCell>{item.inventoryId}</TableCell>
                  <TableCell>{item.basePrice}</TableCell>
                  <TableCell>{item.currency}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <ButtonGroup>
          <Button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </Button>
          {Array.from({ length: totalPages }, (_, index) => (
            <Button
              key={index + 1}
              disabled={currentPage === index + 1}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Button>
          ))}
          <Button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </Button>
        </ButtonGroup>
      </div>

      {/* Filter Dialog */}
      <Dialog open={openFilterDialog} onClose={handleFilterDialogClose}>
        <DialogTitle>Filter Table Data</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Inventory ID"
            fullWidth
            value={filterCriteria.inventoryId}
            onChange={(event) =>
              handleFilterCriteriaChange(event, "inventoryId")
            }
          />
          <TextField
            margin="dense"
            label="Base Price"
            fullWidth
            value={filterCriteria.basePrice}
            onChange={(event) => handleFilterCriteriaChange(event, "basePrice")}
          />
          <TextField
            margin="dense"
            label="Currency"
            fullWidth
            value={filterCriteria.currency}
            onChange={(event) => handleFilterCriteriaChange(event, "currency")}
          />

          <Button variant="contained" onClick={handleFilterDialogClose}>
            Apply Filter
          </Button>

          <Button
            variant="contained"
            style={{ marginLeft: "8px" }}
            onClick={handleResetFilterCriteria}
          >
            Reset Filters
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Pricing;
