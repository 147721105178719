import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "firebaseConfig";
import { useEffect, useState } from "react";
import { useUpdateProfile } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreateProfileMutation, useCreateUserProfileMutation, useGetUserProfileQuery, useUpdateUserProfileMutation } from "state/api";

export default function PatientProfile() {
    const query = useLocation().search;
    const user = new URLSearchParams(query).get("patient");
    const name = new URLSearchParams(query).get("name");
    const navigate = useNavigate()
    const userProfile = useGetUserProfileQuery(user)
    const [createUserProfile] = useCreateUserProfileMutation();
    const [updateProfile] = useUpdateUserProfileMutation()
    const [photo, setPhoto] = useState('');
    const [img, setImage] = useState('');
    const [gender, setGender] = useState('');
    const [age, setAge] = useState(0);
    const [heightMeasure, setHeightMeasure] = useState('cm');
    const [height, setHeight] = useState(0);
    const [weightMeasure, setWeightMeasure] = useState('kg');
    const [spo2, setSpo2] = useState('');
    const [bp, setBp] = useState('');
    const [pulse, setPulse] = useState('');
    const [heartRate, setHeartRate] = useState('');
    const [comorbidities, setComorbidities] = useState([])
    const [weight, setWeight] = useState('');
    const [natureOfWork, setNatureOfWork] = useState('sedentary');
    const [progress, setProgress] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [message, setMessage] = useState()
  
    
    const handleSubmit = (event) => {
        setProgress(true)
        event.preventDefault();
        if (gender.trim().length === 0) {
            setProgress(false)
            setError(true)
            setMessage("Please enter gender")
        } else if (age <= 0) {
            setProgress(false)
            setError(true)
            setMessage("Please enter correct age")
        } else if (height <= 0) {
            setProgress(false)
            setError(true)
            setMessage("Please enter correct height")
        } else if (weight <= 0) {
            setProgress(false)
            setError(true)
            setMessage("Please enter correct weight")
        }else if (natureOfWork.trim().length === 0) {
            setProgress(false)
            setError(true)
            setMessage("Please enter correct lifestyle")
            
        }  else {
          if (userProfile.data) {
            if (photo) {
                const storageRef = ref(storage, `/images/${name}`)
                const uploadTask = uploadBytesResumable(storageRef, photo);
                 
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                },
                (err) => {
                    setError(true)
                     setMessage(err.message)},
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        const userData = {id: userProfile.data._id, name: name,  heightMeasure: heightMeasure, weightMeasure:weightMeasure, height: height, weight: weight, natureOfWork: natureOfWork, photo: url, gender:gender, age:age, bp:bp, spo2:spo2, heartRate:heartRate, pulse:pulse, comorbidities:comorbidities  }
                        updateProfile(userData).unwrap().then((data) => {
                            setProgress(false)
                            setSuccess(true)
                            window.location.replace('/patients')
    
                        }).catch((err) => {
                            // console.log("err", err)
                            setError(true)
                            setProgress(false)
                            setMessage(err.data.message)
                        })
                    });
                }
            );
            } else {
                const userData = {id: userProfile.data._id, name: name,  heightMeasure: heightMeasure, weightMeasure:weightMeasure, height: height, weight: weight, natureOfWork: natureOfWork, gender:gender, age:age,bp:bp, spo2:spo2, heartRate:heartRate, pulse:pulse, comorbidities:comorbidities  }
                updateProfile(userData).unwrap().then((data) => {
                    setProgress(false)
                    setSuccess(true)
                    window.location.replace('/patients')

                }).catch((err) => {
                    // console.log("err", err)
                    setError(true)
                    setProgress(false)
                    setMessage(err.data.message)
                })
            }
          } else {
            if (photo) {
                const storageRef = ref(storage, `/images/${name}`)
                const uploadTask = uploadBytesResumable(storageRef, photo);
                 
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                },
                (err) => {
                    setProgress(false)
                    setError(true)
                     setMessage(err.message)},
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        const userData = { accountId:user, name: name,  heightMeasure: heightMeasure, weightMeasure:weightMeasure, height: height, weight: weight, natureOfWork: natureOfWork, photo: url, gender:gender, age:age,bp:bp, spo2:spo2, heartRate:heartRate, pulse:pulse, comorbidities:comorbidities  }
                        createUserProfile(userData).unwrap().then((data) => {
                            setProgress(false)
                            setSuccess(true)
                            window.location.replace('/patients')
    
                        }).catch((err) => {
                            // console.log("err", err)
                            setError(true)
                            setProgress(false)
                            setMessage(err.data.message)
                        })
                    });
                }
            );
            } else {
                const userData = {accountId:user, name: name,  heightMeasure: heightMeasure, weightMeasure:weightMeasure, height: height, weight: weight, natureOfWork: natureOfWork, gender:gender, age:age,bp:bp, spo2:spo2, heartRate:heartRate, pulse:pulse, comorbidities:comorbidities  }
                createUserProfile(userData).unwrap().then((data) => {
                    setProgress(false)
                    setSuccess(true)
                    window.location.replace('/patients')

                }).catch((err) => {
                    // console.log("err", err)
                    setError(true)
                    setProgress(false)
                    setMessage(err.data.message)
                })
            }
          }
        }
    };

    useEffect(()=>{
        if (userProfile.data) {
            setImage(userProfile.data.photo)
            setGender(userProfile.data.gender)
            setAge(userProfile.data.age)
            setHeightMeasure(userProfile.data.heightMeasure )
            setHeight(userProfile.data.height)
            setWeightMeasure(userProfile.data.weightMeasure)
            setWeight(userProfile.data.weight)
            setNatureOfWork(userProfile.data.natureOfWork)
            setPulse(userProfile.data.pulse)
            setComorbidities(userProfile.data.comorbidities)
            setBp(userProfile.data.bp)
            setSpo2(userProfile.data.spo2)
            setHeartRate(userProfile.data.heartRate)
        }
       },[userProfile.data])

   useEffect(()=>{
    if (error || success) {
        setTimeout(()=>{
            setError(false)
            setSuccess(false)
        },2000)
    }
   },[error, success])
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            minHeight: '100vh',


        }}>
            {
                success && <Box display="flex" borderRadius={2} mt={2} padding="8px" bgcolor="#d7ffe8" justifyContent="center" alignItems="center"><Typography align='center' variant='h6' color="#01b850">Profile updated successfully!</Typography></Box>
            }
             {
                    error && <Box display="flex" borderRadius={2} mt={2} padding="8px" bgcolor="#ff9999" justifyContent="center" alignItems="center"><Typography align='center' variant='h6' color="#eb0a0a">{message}</Typography></Box>
             }
            <form onSubmit={handleSubmit} className="max-w-md w-full mx-auto p-6 mt-4 bg-white rounded-lg shadow-md text-black">
               
            <div className="mb-4">
                   
                   {img && img.trim().length !== 0 &&  <img   className="border border-gray-300 p-2 rounded-lg h-52" src={img}/>}
                </div>
                <div className="mb-4">
                    <label htmlFor="photo" className="block font-medium text-gray-700 mb-2">User photo</label>
                    <input accept="image/png, image/gif, image/jpeg"  type="file" id="photo" name="photo" className="border border-gray-300 p-2 rounded-lg w-full" onChange={(event) => setPhoto(event.target.files[0])} />
                </div>

                <div className="mb-4">
                    <label htmlFor="name" className="block font-medium text-gray-700 mb-2">User</label>
                    <input type="text" disabled  id="name" name="name" className="border border-gray-300 p-2 rounded-lg w-full" value={name} />
                </div>
                
                <div className="mb-4">
                    <label htmlFor="gender" className="block font-medium text-gray-700 mb-2">Gender</label>
                    <select id="gender" name="gender" className="border border-gray-300 p-2 rounded-lg w-full" value={gender} onChange={(event) => setGender(event.target.value)}>
                        <option disabled selected value="">Select gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>

                    </select>
                </div>
                <div className="mb-4">
                    <label htmlFor="age" className="block font-medium text-gray-700 mb-2">Age</label>
                    <input type="number" min={0} id="age" name="age" className="border border-gray-300 p-2 rounded-lg w-full" value={age} onChange={(event) => setAge(Number.parseInt(event.target.value))} />
                </div>
                <div className="mb-4">
                    <label htmlFor="height" className="block font-medium text-gray-700 mb-2">Height</label>
                    <div className="flex items-center">
                        <input type="number" id="height" name="height" className="border border-gray-300 p-2 rounded-lg w-1/2 mr-2" value={height} onChange={(event) => setHeight(Number.parseInt(event.target.value))} />
                        <select id="heightMeasure" defaultValue="cm" name="heightMeasure" className="border border-gray-300 p-2 rounded-lg w-1/2" value={heightMeasure} onChange={(event) => setHeightMeasure(event.target.value)}>
                            {/* <option value="ft">ft</option> */}
                            <option value="cm">cm</option>
                        </select>
                    </div>
                </div>
                <div className="mb-4">
                    <label htmlFor="weight" className="block font-medium text-gray-700 mb-2">Weight</label>
                    <div className="flex items-center">
                        <input type="number" id="weight" name="weight" className="border border-gray-300 p-2 rounded-lg w-1/2 mr-2" value={weight} onChange={(event) => setWeight(event.target.value)} />
                        <select id="weightMeasure" name="weightMeasure" className="border border-gray-300 p-2 rounded-lg w-1/2" value={weightMeasure} onChange={(event) => setWeightMeasure(event.target.value)}>
                            <option value="kg">kg</option>
                            <option value="lbs">lbs</option>

                        </select>
                    </div>
                </div>
                <div className="mb-4">
                    <label htmlFor="natureOfWork" className="block font-medium text-gray-700 mb-2">Nature of work</label>
                    <select id="natureOfWork" name="natureOfWork" className="border border-gray-300 p-2 rounded-lg w-full" value={natureOfWork} onChange={(event) => setNatureOfWork(event.target.value)}>
                        <option value="sedentary">Sedentary</option>
                        <option value="household_work">Household Work</option>
                        <option value="athletic">Athletic</option>
                        <option value="heavy_manual_labour">Heavy Manual Labour</option>
                    </select>

                </div>
                <div className="mb-4">
                    <label htmlFor="name" className="block font-medium text-gray-700 mb-2">SPO2</label>
                    <input type="text" onChange={(e)=>{setSpo2(e.target.value)}}  id="name" name="name" className="border border-gray-300 p-2 rounded-lg w-full" value={spo2} />
                </div>
                <div className="mb-4">
                    <label htmlFor="name" className="block font-medium text-gray-700 mb-2">B.P</label>
                    <input onChange={(e)=>{setBp(e.target.value)}} type="text"  id="name" name="name" className="border border-gray-300 p-2 rounded-lg w-full" value={bp} />
                </div>
                <div className="mb-4">
                    <label htmlFor="name" className="block font-medium text-gray-700 mb-2">Heart Rate</label>
                    <input onChange={(e)=>{setHeartRate(e.target.value)}} type="text"  id="name" name="name" className="border border-gray-300 p-2 rounded-lg w-full" value={heartRate} />
                </div>
                <div className="mb-4">
                    <label htmlFor="name" className="block font-medium text-gray-700 mb-2">Pulse</label>
                    <input onChange={(e)=>{setPulse(e.target.value)}} type="text"  id="name" name="name" className="border border-gray-300 p-2 rounded-lg w-full" value={pulse} />
                </div>
                {/* <div className="mb-4">
                    <label htmlFor="name" className="block font-medium text-gray-700 mb-2">Sleep Quality</label>
                    <input type="text"  id="name" name="name" className="border border-gray-300 p-2 rounded-lg w-full" value={name} />
                </div> */}
                <div className="mb-4">
                    <label htmlFor="comorbidities" className="block font-medium text-gray-700 mb-2">Comorbidities</label>
                    <label htmlFor="comorbidities" className="block font-small text-gray-700 mb-2">Use comma (,) separator to add more</label>
                     <div className="flex align-middle items-center">
                     <input onChange={(e)=>{setComorbidities(e.target.value.trim().split(","))}} type="text"  id="name" name="name" className="border border-gray-300 p-2 rounded-lg w-full" defaultValue={comorbidities && comorbidities.join(",")} />
     
                     </div>
                </div>
                
                {!progress && <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors duration-300 ease-in-out">Save Changes</button>}
                {
                    progress && <Box display="flex" justifyContent="center" alignItems="center"><CircularProgress sx={{ width: '100%', alignSelf: 'center' }} /></Box>
                }
                <button type="button" onClick={()=>{navigate(-1)}} className="bg-purple-700 text-white py-2 px-5 rounded-lg hover:bg-purple-900 ml-4  transition-colors duration-300 ease-in-out">Go Back</button>
            </form>
           
        </Box>
    );
}
