import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from "@mui/material";
import {
  Search,
  GetApp,
  Print,
  ViewColumn,
  FilterList,
  Clear,
  UpdateRounded,
  Delete,
  Schedule,
  EditAttributes,
  ExpandCircleDownTwoTone,
  ArrowForwardIos,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  useCreateAppointmentMutation,
  useGetVideoCallTokenQuery,
} from "state/api";
import { useTheme } from "@mui/material/styles";
import { tokensDark, tokensLight } from "theme";
import {
  Assignment,
  Dashboard,
  Edit,
  Share,
  Update,
} from "@mui/icons-material";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ArrowUpward } from "@mui/icons-material";
import { ArrowDownward } from "@mui/icons-material";
import { getCountryCallingCode } from "react-phone-number-input";
import parsePhoneNumberFromString from "libphonenumber-js";

const PatientTable = ({ patients, shouldExpandRow, shouldShowEdit }) => {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const tableRef = useRef(null);
  const [showSchedule, setShowSchedule] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [filterCriteria, setFilterCriteria] = useState({
    name: "",
    email: "",
    mobile: "",
    country: "",
    role: "",
  });
  const [filteredPatients, setFilteredPatients] = useState(patients);
  // New state for filtered results
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query

  const handleRowClick = (patient) => {
    if (selectedPatient === patient) {
      setSelectedPatient(null);
    } else {
      setSelectedPatient(patient);
    }
  };

  const handleSortOrderChange = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
  };
  const sortTableData = () => {
    const sortedData = [...patients].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });

    setFilteredPatients(sortedData);
  };

  useEffect(() => {
    sortTableData();
  }, [sortOrder]);

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleFilterCriteriaChange = (event, field) => {
    const { value } = event.target;
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      [field]: value,
    }));

    const filteredData = patients.filter((patient) => {
      const { name, email, mobile, country, role } = patient;
      const {
        name: nameFilter,
        email: emailFilter,
        mobile: mobileFilter,
        country: countryFilter,
        role: roleFilter,
      } = filterCriteria;

      const isNameMatch =
        !nameFilter || name.toLowerCase().includes(nameFilter.toLowerCase());
      const isEmailMatch =
        !emailFilter || email.toLowerCase().includes(emailFilter.toLowerCase());
      const isMobileMatch = !mobileFilter || mobile.includes(mobileFilter);
      const isCountryMatch =
        !countryFilter ||
        country.toLowerCase().includes(countryFilter.toLowerCase());
      const isRoleMatch =
        !roleFilter || role.toLowerCase().includes(roleFilter.toLowerCase());

      return (
        isNameMatch &&
        isEmailMatch &&
        isMobileMatch &&
        isCountryMatch &&
        isRoleMatch
      );
    });

    setFilteredPatients(filteredData);
    setCurrentPage(1);
  };

  const handleResetFilterCriteria = () => {
    setFilterCriteria({
      name: "",
      email: "",
      mobile: "",
      country: "",
      role: "",
    });
    setFilteredPatients(patients); // Reset filtered results to original data
    setCurrentPage(1);
  };

  const handlePrint = () => {
    const content = tableRef.current.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleDownload = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," + convertToCSV(filteredPatients);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "patients.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  };

  const convertToCSV = (data) => {
    const header = ["Name", "Email", "Phone Number", "Country", "Role"];
    const rows = data.map((patient) => [
      patient.name,
      patient.email,
      `"${patient.mobile}"`, // Treat phone number as a string
      patient.country ? patient.country : "India",
      patient.role,
    ]);

    const csvData = [header, ...rows]
      .map((row) => row.map((value) => `"${value}"`).join(","))
      .join("\n");
    return csvData;
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query.trim() === "") {
      setFilteredPatients(patients); // Restore the original table if search query is empty
    } else {
      const searchedData = filteredPatients.filter((patient) =>
        Object.values(patient)
          .join(" ")
          .toLowerCase()
          .includes(query.toLowerCase())
      );

      setFilteredPatients(searchedData);
      setCurrentPage(1);
    }
  };

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredPatients.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredPatients.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const CountryNameByPhoneCode = ({ phoneNumber }) => {
    const country = parsePhoneNumberFromString(phoneNumber, "IN");
    const countryName = country ? country.country : "Country not found";

    return countryName;
  };
  const renderRow = (patient, idx) => {
    const { name, country, mobile, email, createdAt } = patient;

    const backgroundColor = theme.palette.primary.light;

    return (
      <TableRow
        sx={{
          backgroundColor: backgroundColor,
          color: isDarkMode
            ? tokensDark.secondary[800]
            : tokensDark.secondary[700],
          ":hover": {
            backgroundColor: isDarkMode
              ? tokensDark.primary[300]
              : tokensDark.grey[200],
          },
        }}
        key={patient._id}
        onClick={() => handleRowClick(patient)}>
        <TableCell>{name}</TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>{mobile}</TableCell>
        <TableCell>
          {mobile ? CountryNameByPhoneCode({ phoneNumber: mobile }) : ""}
        </TableCell>
        <TableCell>{moment(createdAt).format("DD MMMM YYYY HH:mm")}</TableCell>
        <TableCell>
          {shouldShowEdit && (
            <ArrowForwardIos
              onClick={() => {
                navigate(`/partner/profile`, { state: { account: patient } });
              }}
            />
          )}
        </TableCell>
      </TableRow>
    );
  };

  const HandleDashboard = (patient) => {
    navigate(`/patient?patient=${patient._id}&mobile=${patient.mobile}`);
  };

  const HandleAssignPlan = (patient) => {
    navigate(
      `/patient/assign-plan?patient=${patient._id}&mobile=${patient.mobile}`
    );
  };

  const renderExpandedRow = (patient, idx) => {
    const backgroundColor = theme.palette.background.alt;
    return (
      <TableRow
        sx={{
          cursor: "pointer",
          backgroundColor: backgroundColor,
          color: isDarkMode
            ? tokensDark.secondary[800]
            : tokensDark.primary[700],
        }}
        key={`${patient.id}-expanded`}>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, cursor: "pointer" }}
          colSpan={6}>
          <div className='expanded-row'>
            <Button
              startIcon={<Dashboard />}
              variant='outlined'
              style={{
                margin: "8px",
                color: isDarkMode ? "white" : "black",
                borderColor: isDarkMode ? "white" : "black",
              }}
              onClick={() => {
                HandleDashboard(patient);
              }}>
              View Dashboard
            </Button>
            <Button
              startIcon={<Assignment />}
              variant='outlined'
              style={{
                margin: "8px",
                color: isDarkMode ? "white" : "black",
                borderColor: isDarkMode ? "white" : "black",
              }}
              onClick={() => {
                HandleAssignPlan(patient);
              }}>
              Assign Plan
            </Button>
            <Button
              startIcon={<Edit />}
              variant='outlined'
              style={{
                margin: "8px",
                color: isDarkMode ? "white" : "black",
                borderColor: isDarkMode ? "white" : "black",
              }}
              onClick={() => {
                navigate(
                  `/patient/profile?patient=${patient._id}&mobile=${patient.mobile}&name=${patient.name}`
                );
              }}>
              Edit Patient Profile
            </Button>
            <Button
              startIcon={<Update />}
              variant='outlined'
              style={{
                margin: "8px",
                color: isDarkMode ? "white" : "black",
                borderColor: isDarkMode ? "white" : "black",
              }}
              onClick={() => {
                navigate(
                  `/patient/update-stats?patient=${patient._id}&mobile=${
                    patient.mobile
                  }?&name=${patient.name}?&plan=${null}`,
                  {
                    state: {
                      case: 1,
                      session: 1,
                      painPoints: [],
                      isPlanSelectionDisable: false,
                    },
                  }
                );
                // navigate(
                //   `/patient/update-stats?patient=${patient._id}&mobile=${patient.mobile}&name=${patient.name}`
                // );
              }}>
              Add Session Records
            </Button>
            <Button
              startIcon={<Schedule />}
              variant='outlined'
              style={{
                margin: "8px",
                color: isDarkMode ? "white" : "black",
                borderColor: isDarkMode ? "white" : "black",
              }}
              onClick={() => {
                setShowSchedule(true);
              }}>
              Schedule Appointment
            </Button>
            {/* <Button
              startIcon={<Delete/>}
                variant='outlined'
                style={{ margin: '8px', color:"white" }}
             
            >
              Delete Patient
            </Button> */}
          </div>
        </TableCell>
      </TableRow>
    );
  };
  const DateTimePickerModal = ({ patient }) => {
    const [selectedDateTime, setSelectedDateTime] = useState("");
    const [appointmentType, setAppointmentType] = useState("Physio Evaluation");
    const [department, setDepartment] = useState("orthopedic");
    const [bookAppointment] = useCreateAppointmentMutation();
    const handleDateTimeChange = (e) => {
      setSelectedDateTime(e.target.value);
    };

    const handleSave = (patient) => {
      if (selectedDateTime.trim().length === 0) {
        alert("Please enter the date and time for the appointment");
      } else {
        const appointment = {
          user: patient._id,
          schedule: moment(selectedDateTime)
            .utc(true)
            .format("YYYY-MM-DD HH:mm"),
          appointmentType: appointmentType,
          department: department,
        };
        bookAppointment(appointment)
          .unwrap()
          .then((d) => {
            alert(d.message);
            setShowSchedule(false);
          })
          .catch((e) => {
            alert(e.data.message);
          });
      }
    };

    return (
      <div className='fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50'>
        <div className='bg-white rounded-lg p-6'>
          <h2 className='text-lg text-gray-700 font-semibold mb-4'>
            Select a date and time
          </h2>
          <input
            type='datetime-local'
            value={selectedDateTime}
            onChange={handleDateTimeChange}
            className='border rounded-lg px-3 py-2 mb-4 text-gray-700'
          />
          <h2 className='text-md text-gray-700 font-semibold mt-4'>
            Choose Appointment For
          </h2>
          <select
            onChange={(e) => {
              setAppointmentType(e.target.value);
            }}
            className='w-full text-gray-700'>
            <option value={"Physio Evaluation"}>Physio Evaluation</option>
            <option value={"Physio Session"}>Physio Session</option>
            <option value={"Doctor Consultation"}>Doctor Consultation</option>
            {/* <option>Diet</option>
            <option>Dietician</option> */}
            <option value={"Home Visit"}>Home Visit</option>
          </select>
          <h2 className='text-md text-gray-700 font-semibold mt-4'>
            Choose Department
          </h2>
          <select
            onChange={(e) => {
              setDepartment(e.target.value);
            }}
            className='w-full text-gray-700'>
            <option value={"orthopedic"}>Orthopedic</option>
            <option value={"sports_injury"}>Sports</option>
            <option value={"gynaecology"}>Gynae</option>
            {/* <option>Diet</option>
            <option>Dietician</option> */}
            <option value={"pcod"}>PCOD</option>
          </select>

          <div className='flex gap-2 justify-end mt-4'>
            <button
              onClick={() => {
                setShowSchedule(false);
              }}
              className='bg-red-500 hover:bg-red-600 text-white rounded-lg px-4 py-2'>
              Cancel
            </button>
            <button
              onClick={() => {
                handleSave(patient);
              }}
              className='bg-blue-500 hover:bg-blue-600 text-white rounded-lg px-4 py-2'>
              Schedule
            </button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className='flex gap-2 flex-col md:flex-row justify-between mb-4'>
        {showSchedule && <DateTimePickerModal patient={selectedPatient} />}
        <ToastContainer
          containerId='toast'
          autoClose={2000}
          position='top-center'
          hideProgressBar={true}
        />
        <div className='w-full'>
          <Button
            sx={{}}
            variant='contained'
            onClick={() => {
              navigate(`/patient/add`);
            }}>
            Add New
          </Button>
        </div>
        <div className='w-full items-end flex justify-end'>
          <ButtonGroup>
            <TextField
              label='Search'
              variant='outlined'
              size='small'
              value={searchQuery}
              onChange={handleSearch}
            />

            <IconButton onClick={handleDownload}>
              <GetApp />
            </IconButton>
            <IconButton onClick={handlePrint}>
              <Print />
            </IconButton>
            <IconButton>
              <ViewColumn />
            </IconButton>
            <IconButton onClick={handleFilterDialogOpen}>
              <FilterList />
            </IconButton>
          </ButtonGroup>
        </div>
      </div>
      <TableContainer
        ref={tableRef}
        style={{ marginTop: "16px", height: "500px", scrollbarWidth: 0 }}
        component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: isDarkMode
                  ? "#06334B"
                  : tokensDark.secondary[500],
                color: isDarkMode
                  ? tokensDark.secondary[800]
                  : tokensDark.secondary[700],
                fontWeight: "bold",
              }}>
              <TableCell
                style={{ backgroundColor: theme.palette.background.alt }}>
                Name
              </TableCell>
              <TableCell
                style={{ backgroundColor: theme.palette.background.alt }}>
                Email
              </TableCell>
              <TableCell
                style={{ backgroundColor: theme.palette.background.alt }}>
                Phone Number
              </TableCell>
              <TableCell
                style={{ backgroundColor: theme.palette.background.alt }}>
                Country
              </TableCell>
              <TableCell
                style={{ backgroundColor: theme.palette.background.alt }}
                onClick={handleSortOrderChange}>
                Created At{" "}
                {sortOrder === "asc" ? <ArrowDownward /> : <ArrowUpward />}{" "}
              </TableCell>
              <TableCell
                style={{ backgroundColor: theme.palette.background.alt }}>
                {shouldShowEdit ? "Edit Details" : ""}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentItems.map((patient, idx) => (
              <>
                {renderRow(patient, idx)}
                {shouldExpandRow === true &&
                  selectedPatient === patient &&
                  renderExpandedRow(patient, idx)}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <div className='justify-center mt-4   overflow-x-auto'>
        <ButtonGroup style={{ color: "#755a0e" }}>
          <Button
            style={{ color: isDarkMode ? "white" : "#755a0e", border: "solid" }}
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}>
            Previous
          </Button>
          {Array.from({ length: totalPages }, (_, i) => (
            <Button
              key={i + 1}
              style={{
                color: isDarkMode ? "white" : "#755a0e",
                border: "solid",
              }}
              variant={currentPage === i + 1 ? "contained" : "outlined"}
              onClick={() => handlePageChange(i + 1)}>
              {i + 1}
            </Button>
          ))}
          <Button
            style={{ color: isDarkMode ? "white" : "#755a0e", border: "solid" }}
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}>
            Next
          </Button>
        </ButtonGroup>
      </div>

      <Dialog open={openFilterDialog} onClose={handleFilterDialogClose}>
        <DialogTitle>Filter Table Data</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            label='Name'
            type='text'
            fullWidth
            variant='outlined'
            value={filterCriteria.name}
            onChange={(e) => handleFilterCriteriaChange(e, "name")}
          />
          <TextField
            margin='dense'
            label='Email'
            type='email'
            fullWidth
            variant='outlined'
            value={filterCriteria.email}
            onChange={(e) => handleFilterCriteriaChange(e, "email")}
          />
          <TextField
            margin='dense'
            label='Phone Number'
            type='tel'
            fullWidth
            variant='outlined'
            value={filterCriteria.mobile}
            onChange={(e) => handleFilterCriteriaChange(e, "mobile")}
          />
          <TextField
            margin='dense'
            label='Country'
            type='text'
            fullWidth
            variant='outlined'
            value={filterCriteria.country}
            onChange={(e) => handleFilterCriteriaChange(e, "country")}
          />
          <TextField
            margin='dense'
            label='Role'
            type='text'
            fullWidth
            variant='outlined'
            value={filterCriteria.role}
            onChange={(e) => handleFilterCriteriaChange(e, "role")}
          />

          <Button variant='contained' onClick={handleFilterDialogClose}>
            Apply Filter
          </Button>

          <Button
            variant='contained'
            style={{ marginLeft: "8px" }}
            onClick={handleResetFilterCriteria}>
            Reset Filters
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PatientTable;
