const data = [
    {
      "id": "ALB",
      "value": 1
    },
    {
      "id": "BFA",
      "value": 1
    },
    {
      "id": "BGD",
      "value": 2
    },
    {
      "id": "BWA",
      "value": 1
    },
    {
      "id": "CAN",
      "value": 10
    },
    {
      "id": "DEU",
      "value": 5
    },
    {
      "id": "FIN",
      "value": 1
    },
    {
      "id": "FRA",
      "value": 1
    },
    {
      "id": "GGY",
      "value": 10
    },
    {
      "id": "IDN",
      "value": 2
    },
    {
      "id": "IND",
      "value": 2476
    },
    {
      "id": "IRN",
      "value": 1
    },
    {
      "id": "MMR",
      "value": 2
    },
    {
      "id": "NGA",
      "value": 6
    },
    {
      "id": "NPL",
      "value": 1
    },
    {
      "id": "PAK",
      "value": 1
    },
    {
      "id": "SOM",
      "value": 1
    },
    {
      "id": "VEN",
      "value": 1
    },
    {
      "id": "ZAF",
      "value": 1
    },
    {
      "id": "ZWE",
      "value": 1
    }
  ]

  export default data