import React, { useState, useRef } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  useTheme,
} from '@mui/material';
import { Search, GetApp, Print, ViewColumn, Edit, Delete, Visibility, FilterList } from '@mui/icons-material';
import { saveAs } from 'file-saver';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { tokensDark, tokensLight } from 'theme';

const Users = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const tableRef = useRef(null);
  const theme = useTheme();

  const [filterCriteria, setFilterCriteria] = useState({
    name: '',
    countryCode: '',
    mobile: '',
    referralCode: '',
    userReferralCode: '',
    role: '',
    mobileVerified: '',
    status: '',
    deletedAt: '',
    otp: '',
    otpSentAt: '',
    otpVerifiedAt: '',
    offer: '',
    userFCMToken: '',
    latitude: '',
    longitude: '',
    canAssignPlan: '',
    timestamps: '',
  });

  const [tableData, setTableData] = useState([
    {
      name: 'John Doe',
      countryCode: '+1',
      mobile: '1234567890',
      referralCode: 'ABC123',
      userReferralCode: 'XYZ789',
      role: 'User',
      mobileVerified: 'Yes',
      status: 'Active',
      deletedAt: '',
      otp: '123456',
      otpSentAt: '2023-06-01 10:00:00',
      otpVerifiedAt: '2023-06-01 10:05:00',
      offer: '10% off',
      userFCMToken: 'fcm-token-123',
      latitude: '37.1234',
      longitude: '-122.5678',
      canAssignPlan: 'Yes',
      timestamps: '2023-06-01 10:00:00',
    },
    {
      name: 'Jane Smith',
      countryCode: '+1',
      mobile: '9876543210',
      referralCode: 'DEF456',
      userReferralCode: 'PQR987',
      role: 'Admin',
      mobileVerified: 'No',
      status: 'Inactive',
      deletedAt: '2023-06-02 15:00:00',
      otp: '',
      otpSentAt: '',
      otpVerifiedAt: '',
      offer: '',
      userFCMToken: 'fcm-token-456',
      latitude: '37.5678',
      longitude: '-122.1234',
      canAssignPlan: 'No',
      timestamps: '2023-06-02 14:30:00',
    },
    // Add more dummy data as needed
  ]);

  const [filteredUsers, setFilteredUsers] = useState(tableData);
  const [searchQuery, setSearchQuery] = useState('');

  const handleRowClick = (user) => {
    if (selectedUser === user) {
      setSelectedUser(null);
    } else {
      setSelectedUser(user);
    }
  };

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleFilterCriteriaChange = (event, field) => {
    const { value } = event.target;
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      [field]: value,
    }));

    const filteredData = tableData.filter((user) => {
      const {
        name,
        countryCode,
        mobile,
        referralCode,
        userReferralCode,
        role,
        mobileVerified,
        status,
        deletedAt,
        otp,
        otpSentAt,
        otpVerifiedAt,
        offer,
        userFCMToken,
        latitude,
        longitude,
        canAssignPlan,
        timestamps,
      } = user;
      const lowerCaseSearchQuery = value.toLowerCase();

      return (
        name.toLowerCase().includes(lowerCaseSearchQuery) ||
        countryCode.toLowerCase().includes(lowerCaseSearchQuery) ||
        mobile.toLowerCase().includes(lowerCaseSearchQuery) ||
        referralCode.toLowerCase().includes(lowerCaseSearchQuery) ||
        userReferralCode.toLowerCase().includes(lowerCaseSearchQuery) ||
        role.toLowerCase().includes(lowerCaseSearchQuery) ||
        mobileVerified.toLowerCase().includes(lowerCaseSearchQuery) ||
        status.toLowerCase().includes(lowerCaseSearchQuery) ||
        deletedAt.toLowerCase().includes(lowerCaseSearchQuery) ||
        otp.toLowerCase().includes(lowerCaseSearchQuery) ||
        otpSentAt.toLowerCase().includes(lowerCaseSearchQuery) ||
        otpVerifiedAt.toLowerCase().includes(lowerCaseSearchQuery) ||
        offer.toLowerCase().includes(lowerCaseSearchQuery) ||
        userFCMToken.toLowerCase().includes(lowerCaseSearchQuery) ||
        latitude.toLowerCase().includes(lowerCaseSearchQuery) ||
        longitude.toLowerCase().includes(lowerCaseSearchQuery) ||
        canAssignPlan.toLowerCase().includes(lowerCaseSearchQuery) ||
        timestamps.toLowerCase().includes(lowerCaseSearchQuery)
      );
    });

    setFilteredUsers(filteredData);
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value);

    const filteredData = tableData.filter((user) => {
      const {
        name,
        countryCode,
        mobile,
        referralCode,
        userReferralCode,
        role,
        mobileVerified,
        status,
        deletedAt,
        otp,
        otpSentAt,
        otpVerifiedAt,
        offer,
        userFCMToken,
        latitude,
        longitude,
        canAssignPlan,
        timestamps,
      } = user;
      const lowerCaseSearchQuery = value.toLowerCase();

      return (
        name.toLowerCase().includes(lowerCaseSearchQuery) ||
        countryCode.toLowerCase().includes(lowerCaseSearchQuery) ||
        mobile.toLowerCase().includes(lowerCaseSearchQuery) ||
        referralCode.toLowerCase().includes(lowerCaseSearchQuery) ||
        userReferralCode.toLowerCase().includes(lowerCaseSearchQuery) ||
        role.toLowerCase().includes(lowerCaseSearchQuery) ||
        mobileVerified.toLowerCase().includes(lowerCaseSearchQuery) ||
        status.toLowerCase().includes(lowerCaseSearchQuery) ||
        deletedAt.toLowerCase().includes(lowerCaseSearchQuery) ||
        otp.toLowerCase().includes(lowerCaseSearchQuery) ||
        otpSentAt.toLowerCase().includes(lowerCaseSearchQuery) ||
        otpVerifiedAt.toLowerCase().includes(lowerCaseSearchQuery) ||
        offer.toLowerCase().includes(lowerCaseSearchQuery) ||
        userFCMToken.toLowerCase().includes(lowerCaseSearchQuery) ||
        latitude.toLowerCase().includes(lowerCaseSearchQuery) ||
        longitude.toLowerCase().includes(lowerCaseSearchQuery) ||
        canAssignPlan.toLowerCase().includes(lowerCaseSearchQuery) ||
        timestamps.toLowerCase().includes(lowerCaseSearchQuery)
      );
    });

    setFilteredUsers(filteredData);
  };

  const handleDownload = () => {
    const csvContent = "data:text/csv;charset=utf-8," + convertToCSV(filteredUsers);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "users.csv");
    document.body.appendChild(link);
    link.click();
  };

  const convertToCSV = (data) => {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(","));
    return [header, ...rows].join("\n");
  };

  const handlePrint = () => {
    const content = tableRef.current.innerHTML;
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const isDarkMode = theme.palette.mode === 'dark';

  const handleResetFilterCriteria = () => {
    setFilterCriteria({
      discount: '',
      couponCode: '',
      numberOfCoupons: '',
      timesUsed: '',
      description: '',
      validFrom: '',
      validTill: '',
      type: '',
      productIds: '',
      referralIds: '',
      location: '',
      maxAge: '',
      minAge: '',
      private: '',
    });

    const filteredData = tableData.filter((coupon) => {
      const { discount, couponCode, numberOfCoupons, timesUsed, description, validFrom, validTill, type, productIds, referralIds, location, maxAge, minAge, private: isPrivate } = coupon;
      const lowerCaseSearchQuery = searchQuery.toLowerCase();

      return (
        discount.toLowerCase().includes(lowerCaseSearchQuery) ||
        couponCode.toLowerCase().includes(lowerCaseSearchQuery) ||
        numberOfCoupons.toLowerCase().includes(lowerCaseSearchQuery) ||
        timesUsed.toLowerCase().includes(lowerCaseSearchQuery) ||
        description.toLowerCase().includes(lowerCaseSearchQuery) ||
        validFrom.toLowerCase().includes(lowerCaseSearchQuery) ||
        validTill.toLowerCase().includes(lowerCaseSearchQuery) ||
        type.toLowerCase().includes(lowerCaseSearchQuery) ||
        productIds.toLowerCase().includes(lowerCaseSearchQuery) ||
        referralIds.toLowerCase().includes(lowerCaseSearchQuery) ||
        location.toLowerCase().includes(lowerCaseSearchQuery) ||
        maxAge.toLowerCase().includes(lowerCaseSearchQuery) ||
        minAge.toLowerCase().includes(lowerCaseSearchQuery) ||
        isPrivate.toLowerCase().includes(lowerCaseSearchQuery)
      );
    });

    setFilteredUsers(filteredData);
  };



  return (
    <>
      <div className="flex flex-col justify-between mb-4">
        <ToastContainer containerId="toast" autoClose={2000} position="top-center" hideProgressBar={true} />
        <ButtonGroup>
          <IconButton>
            <Search />
          </IconButton>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearch}
          />
          <IconButton onClick={handleDownload}>
            <GetApp />
          </IconButton>
          <IconButton onClick={handlePrint}>
            <Print />
          </IconButton>
          <IconButton>
            <ViewColumn />
          </IconButton>
          <IconButton onClick={handleFilterDialogOpen}>
            <FilterList />
          </IconButton>
        </ButtonGroup>

        {/* Table */}
        <TableContainer ref={tableRef} component={Paper} sx={{ backgroundColor: theme.palette.background.paper, marginTop: '50px', }}>
          <Table>
            <TableHead>
              <TableRow style={{
                cursor: 'pointer',
                backgroundColor: '#06334B',
                color: isDarkMode ? tokensDark.secondary[800] : tokensDark.secondary[700],
              }}>
                <TableCell align="center">Account</TableCell>
                <TableCell align="center">Photo</TableCell>
                <TableCell align="center">Gender</TableCell>
                <TableCell align="center">Age</TableCell>
                <TableCell align="center">Height</TableCell>
                <TableCell align="center">Weight</TableCell>
                <TableCell align="center">Mobile Verified</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Deleted At</TableCell>
                <TableCell align="center">OTP</TableCell>
                <TableCell align="center">OTP Sent At</TableCell>
                <TableCell align="center">OTP Verified At</TableCell>
                <TableCell align="center">Offer</TableCell>
                <TableCell align="center">User FCM Token</TableCell>
                <TableCell align="center">Latitude</TableCell>
                <TableCell align="center">Longitude</TableCell>
                <TableCell align="center">Can Assign Plan</TableCell>
                <TableCell align="center">Timestamps</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((user, index) => (
                <TableRow key={index} onClick={() => handleRowClick(user)} style={{
                  cursor: 'pointer',
                  backgroundColor: '#095271',
                  color: isDarkMode ? tokensDark.secondary[800] : tokensDark.secondary[700],
                }}>
                  <TableCell align="center">{user.name}</TableCell>
                  <TableCell align="center">{user.countryCode}</TableCell>
                  <TableCell align="center">{user.mobile}</TableCell>
                  <TableCell align="center">{user.referralCode}</TableCell>
                  <TableCell align="center">{user.userReferralCode}</TableCell>
                  <TableCell align="center">{user.role}</TableCell>
                  <TableCell align="center">{user.mobileVerified}</TableCell>
                  <TableCell align="center">{user.status}</TableCell>
                  <TableCell align="center">{user.deletedAt}</TableCell>
                  <TableCell align="center">{user.otp}</TableCell>
                  <TableCell align="center">{user.otpSentAt}</TableCell>
                  <TableCell align="center">{user.otpVerifiedAt}</TableCell>
                  <TableCell align="center">{user.offer}</TableCell>
                  <TableCell align="center">{user.userFCMToken}</TableCell>
                  <TableCell align="center">{user.latitude}</TableCell>
                  <TableCell align="center">{user.longitude}</TableCell>
                  <TableCell align="center">{user.canAssignPlan}</TableCell>
                  <TableCell align="center">{user.timestamps}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Filter Dialog */}
        <Dialog open={openFilterDialog} onClose={handleFilterDialogClose}>
          <DialogTitle>Filter Table Data</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Name"
              fullWidth
              variant="outlined"
              value={filterCriteria.name}
              onChange={(e) => handleFilterCriteriaChange(e, 'name')}
            />
            <TextField
              margin="dense"
              label="Country Code"
              fullWidth
              variant="outlined"
              value={filterCriteria.countryCode}
              onChange={(e) => handleFilterCriteriaChange(e, 'countryCode')}
            />
            <TextField
              margin="dense"
              label="Mobile"
              fullWidth
              variant="outlined"
              value={filterCriteria.mobile}
              onChange={(e) => handleFilterCriteriaChange(e, 'mobile')}
            />
            <TextField
              margin="dense"
              label="Referral Code"
              fullWidth
              variant="outlined"
              value={filterCriteria.referralCode}
              onChange={(e) => handleFilterCriteriaChange(e, 'referralCode')}
            />
            <TextField
              margin="dense"
              label="User Referral Code"
              fullWidth
              variant="outlined"
              value={filterCriteria.userReferralCode}
              onChange={(e) => handleFilterCriteriaChange(e, 'userReferralCode')}
            />
            <TextField
              margin="dense"
              label="Role"
              fullWidth
              variant="outlined"
              value={filterCriteria.role}
              onChange={(e) => handleFilterCriteriaChange(e, 'role')}
            />
            <TextField
              margin="dense"
              label="Mobile Verified"
              fullWidth
              variant="outlined"
              value={filterCriteria.mobileVerified}
              onChange={(e) => handleFilterCriteriaChange(e, 'mobileVerified')}
            />
            <TextField
              margin="dense"
              label="Status"
              fullWidth
              variant="outlined"
              value={filterCriteria.status}
              onChange={(e) => handleFilterCriteriaChange(e, 'status')}
            />
            <TextField
              margin="dense"
              label="Deleted At"
              fullWidth
              variant="outlined"
              value={filterCriteria.deletedAt}
              onChange={(e) => handleFilterCriteriaChange(e, 'deletedAt')}
            />
            <TextField
              margin="dense"
              label="OTP"
              fullWidth
              variant="outlined"
              value={filterCriteria.otp}
              onChange={(e) => handleFilterCriteriaChange(e, 'otp')}
            />
            <TextField
              margin="dense"
              label="OTP Sent At"
              fullWidth
              variant="outlined"
              value={filterCriteria.otpSentAt}
              onChange={(e) => handleFilterCriteriaChange(e, 'otpSentAt')}
            />
            <TextField
              margin="dense"
              label="OTP Verified At"
              fullWidth
              variant="outlined"
              value={filterCriteria.otpVerifiedAt}
              onChange={(e) => handleFilterCriteriaChange(e, 'otpVerifiedAt')}
            />
            <TextField
              margin="dense"
              label="Offer"
              fullWidth
              variant="outlined"
              value={filterCriteria.offer}
              onChange={(e) => handleFilterCriteriaChange(e, 'offer')}
            />
            <TextField
              margin="dense"
              label="User FCM Token"
              fullWidth
              variant="outlined"
              value={filterCriteria.userFCMToken}
              onChange={(e) => handleFilterCriteriaChange(e, 'userFCMToken')}
            />
            <TextField
              margin="dense"
              label="Latitude"
              fullWidth
              variant="outlined"
              value={filterCriteria.latitude}
              onChange={(e) => handleFilterCriteriaChange(e, 'latitude')}
            />
            <TextField
              margin="dense"
              label="Longitude"
              fullWidth
              variant="outlined"
              value={filterCriteria.longitude}
              onChange={(e) => handleFilterCriteriaChange(e, 'longitude')}
            />
            <TextField
              margin="dense"
              label="Can Assign Plan"
              fullWidth
              variant="outlined"
              value={filterCriteria.canAssignPlan}
              onChange={(e) => handleFilterCriteriaChange(e, 'canAssignPlan')}
            />
            <TextField
              margin="dense"
              label="Timestamps"
              fullWidth
              variant="outlined"
              value={filterCriteria.timestamps}
              onChange={(e) => handleFilterCriteriaChange(e, 'timestamps')}
            />
            <Button variant="contained" onClick={handleFilterDialogClose}>
              Apply Filter
            </Button>

            <Button
              variant="contained"
              style={{ marginLeft: '8px' }}
              onClick={handleResetFilterCriteria}
            >
              Reset Filters
            </Button>

          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default Users;
