import React, { useState, useRef } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  useTheme,
} from '@mui/material';
import { Search, GetApp, Print, ViewColumn, FilterList } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import 'react-toastify/dist/ReactToastify.css';
import { tokensDark, tokensLight } from 'theme';
import { ToastContainer } from 'react-toastify';

const Diagnostics = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const tableRef = useRef(null);
  const theme = useTheme();

  const [filterCriteria, setFilterCriteria] = useState({
    name: '',
    painPoint: '',
    multiWeeksPlan: '',
    active: '',
    exercisesPlan: '',
  });

  const [tableData, setTableData] = useState([
    {
      name: 'John Doe',
      painPoint: 'Back Pain',
      multiWeeksPlan: 'Yes',
      active: 'Yes',
      exercisesPlan: '3 times a week',
    },
    {
      name: 'Jane Smith',
      painPoint: 'Knee Injury',
      multiWeeksPlan: 'No',
      active: 'No',
      exercisesPlan: 'N/A',
    },
    // Add more dummy data as needed
  ]);

  const [filteredItems, setFilteredItems] = useState(tableData);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');

  const handleRowClick = (item) => {
    if (selectedItem === item) {
      setSelectedItem(null);
    } else {
      setSelectedItem(item);
    }
  };

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleFilterCriteriaChange = (event, field) => {
    const { value } = event.target;
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      [field]: value,
    }));
  };

  const applyFilters = () => {
    const filteredData = tableData.filter((item) => {
      const { name, painPoint, multiWeeksPlan, active, exercisesPlan } = item;
      const lowerCaseSearchQuery = searchQuery.toLowerCase();

      return (
        name.toLowerCase().includes(lowerCaseSearchQuery) ||
        painPoint.toLowerCase().includes(lowerCaseSearchQuery) ||
        multiWeeksPlan.toLowerCase().includes(lowerCaseSearchQuery) ||
        active.toLowerCase().includes(lowerCaseSearchQuery) ||
        exercisesPlan.toLowerCase().includes(lowerCaseSearchQuery)
      );
    });

    setFilteredItems(filteredData);
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  const handleDownload = () => {
    const csvContent = "data:text/csv;charset=utf-8," + convertToCSV(filteredItems);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "diagnostics.csv");
    document.body.appendChild(link);
    link.click();
  };

  const convertToCSV = (data) => {
    const header = ["Name", "Pain Point", "Multi-Weeks Plan", "Active", "Exercises Plan"];
    const rows = data.map((item) => [
      item.name,
      item.painPoint,
      item.multiWeeksPlan,
      item.active,
      item.exercisesPlan,
    ]);

    const csvData = [header, ...rows].map((row) => row.map(value => `"${value}"`).join(",")).join("\n");
    return csvData;
  };


  const handlePrint = () => {
    const content = tableRef.current.innerHTML;
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleResetFilterCriteria = () => {
    setFilterCriteria({
      name: '',
      painPoint: '',
      multiWeeksPlan: '',
      active: '',
      exercisesPlan: '',
    });
    setSearchQuery('');
    setFilteredItems(tableData);
  };

  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <div className="flex flex-col justify-between mb-4">
      <ToastContainer containerId="toast" autoClose={2000} position="top-center" hideProgressBar={true} />
      <ButtonGroup>
        <IconButton>
          <Search />
        </IconButton>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearch}
        />
        <IconButton onClick={handleDownload}>
          <GetApp />
        </IconButton>
        <IconButton onClick={handlePrint}>
          <Print />
        </IconButton>
        <IconButton>
          <ViewColumn />
        </IconButton>
        <IconButton onClick={handleFilterDialogOpen}>
          <FilterList />
        </IconButton>
      </ButtonGroup>

      {/* Table */}
      <TableContainer ref={tableRef} component={Paper} sx={{ backgroundColor: theme.palette.background.paper, marginTop: '50px', }}>
        <Table>
          <TableHead>
            <TableRow style={{
              cursor: 'pointer',
              backgroundColor: '#06334B',
              color: isDarkMode ? tokensDark.secondary[800] : tokensDark.secondary[700],
            }}>
              <TableCell>Name</TableCell>
              <TableCell>Pain Point</TableCell>
              <TableCell>Multi-Weeks Plan</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Exercises Plan</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredItems.map((item, index) => (
              <TableRow key={index} onClick={() => handleRowClick(item)} style={{
                cursor: 'pointer',
                backgroundColor: '#095271',
                color: isDarkMode ? tokensDark.secondary[800] : tokensDark.secondary[700],
              }}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.painPoint}</TableCell>
                <TableCell>{item.multiWeeksPlan}</TableCell>
                <TableCell>{item.active}</TableCell>
                <TableCell>{item.exercisesPlan}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Filter Dialog */}
      <Dialog open={openFilterDialog} onClose={handleFilterDialogClose}>
        <DialogTitle>Filter Criteria</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            variant="outlined"
            size="small"
            value={filterCriteria.name}
            onChange={(event) => handleFilterCriteriaChange(event, 'name')}
          />
          <TextField
            label="Pain Point"
            variant="outlined"
            size="small"
            value={filterCriteria.painPoint}
            onChange={(event) => handleFilterCriteriaChange(event, 'painPoint')}
          />
          <TextField
            label="Multi-Weeks Plan"
            variant="outlined"
            size="small"
            value={filterCriteria.multiWeeksPlan}
            onChange={(event) => handleFilterCriteriaChange(event, 'multiWeeksPlan')}
          />
          <TextField
            label="Active"
            variant="outlined"
            size="small"
            value={filterCriteria.active}
            onChange={(event) => handleFilterCriteriaChange(event, 'active')}
          />
          <TextField
            label="Exercises Plan"
            variant="outlined"
            size="small"
            value={filterCriteria.exercisesPlan}
            onChange={(event) => handleFilterCriteriaChange(event, 'exercisesPlan')}
          />
          <div className="mt-4 flex justify-end">
            <Button variant="contained" onClick={handleFilterDialogClose} className="mr-2">Cancel</Button>
            <Button variant="contained" onClick={applyFilters} className="mr-2">Apply</Button>
            <Button variant="contained" onClick={handleResetFilterCriteria}>Reset</Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Diagnostics;
