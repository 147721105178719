import React, { useState } from "react";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
  Search,
  SettingsOutlined,
  ArrowDropDownOutlined,
} from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import { useDispatch } from "react-redux";
import { setMode } from "state";
import profileImage from "assets/profileImage.png";
import {
  AppBar,
  Button,
  Box,
  Typography,
  IconButton,
  InputBase,
  Toolbar,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import logofull from "assets/logofull.svg"
import logofulldark from "assets/logofulldark.svg"
import { auth } from "firebaseConfig";
import './navstyling.css';

const Navbar = ({ user, isSidebarOpen, setIsSidebarOpen, isNonMobile }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogout = () => {
    auth.signOut().then(() => {
      window.location.replace('/')
    }).catch((error) => {
      alert(error.message)
    })
  }
  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none",


      }}
    >

      <Toolbar sx={isSidebarOpen === true
        ? { justifyContent: "space-between", marginLeft: { sm: '35%', md: '24%', lg: '20%', xl: '15%', xxl: '13%' } } : { justifyContent: "space-between" }} >
        {/* LEFT SIDE */}
        <FlexBetween>

          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <MenuIcon />
          </IconButton>
          <FlexBetween
            // backgroundColor={theme.palette.background.alt}
            borderRadius="9px"
            gap="3rem"
            p="0.1rem 1.5rem"
            class="sm:block md:block lg:block xl:block xxl:block hidden"
          >
             {theme.palette.mode === "dark" ? (
            <img src={logofulldark} className={`${!isNonMobile ? 'hidden' : 'visible'}`} />
          ) : (
            <img src={logofull} className={`${!isNonMobile ? 'hidden' : 'visible'}`} />
          )}

          </FlexBetween>
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap={`${!isNonMobile ? '0.1rem' : '1.5rem'}`}>

        

          <IconButton onClick={() => dispatch(setMode())}  class="sm:block md:block lg:block xl:block xxl:block hidden">
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlined sx={{ fontSize: "25px" }} />
            ) : (
              <LightModeOutlined sx={{ fontSize: "25px" }} />
            )}
            
          </IconButton>
          {/* <IconButton>
            <SettingsOutlined sx={{ fontSize: "25px" }} />
          </IconButton> */}


          <FlexBetween>
            <Button
              onClick={handleClick}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "none",
                gap: "1rem",
              }}
            >
              {/* <Box
                component="img"
                alt="profile"
                src={user.photoUrl}
                height="32px"
                width="32px"
                borderRadius="50%"
                sx={{ objectFit: "cover" }}
              /> */}
              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.85rem"
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {user.name}
                </Typography>
                <Typography
                  fontSize="0.75rem"
                  sx={{ color: theme.palette.secondary[200] }}
                >
                  {user.rolekey || ''}
                </Typography>
              </Box>
              <ArrowDropDownOutlined
                sx={{ color: theme.palette.secondary[300], fontSize: "25px" }}
              />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <MenuItem onClick={handleLogout}>Log Out</MenuItem>
            </Menu>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
