import React,{ useEffect, useState } from 'react'
import { 
    Box, 
    Card, 
    CardActions, 
    CardContent, 
    Collapse, 
    Button, 
    Typography, 
    Rating, 
    useTheme, 
    useMediaQuery,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material"
import { useGetAppointmentsQuery } from 'state/api'
import Header from "components/Header"
import FlexBetween from 'components/FlexBetween'
import AppointmentTable from 'components/AppointmentTable'
import { useOutletContext } from 'react-router-dom'

const Appointment = ({
    _id,
    name,
    painPoint,
    multiWeeksPlan,
    exercisesCount,
    thumbnail,
    numberOfWeeks,
    subscriptions

}) =>{
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = useState(false)
    return(
        <Card
            sx={{
                backgroundImage: "none",
                backgroundColor: theme.palette.background.alt,
                borderRadius: "0.55rem"
            }}
        >
            <CardContent>
                <div className='flex justify-center items-center'>
                <img 
                    alt="profile"
                    src={thumbnail}
                    className="object-cover rounded-lg h-[250px] mb-2"
                />
                </div>
                <Typography 
                    sx={{fontSize: 14}}
                    color={theme.palette.secondary[700]}
                    gutterBottom
                    style={{textTransform: 'capitalize'}}
                >
                    Pain Point : <strong>{painPoint}</strong>
                </Typography>
                <Typography
                    variant='h5'
                    component='div'
                >
                    {name}
                </Typography>
                <Typography
                    color={theme.palette.secondary[400]}
                >
                    Exercises in Plan : {exercisesCount}
                </Typography>
                <Typography
                    sx={{ mb: "1.5rem"}}
                    color={theme.palette.secondary[400]}
                >
                    Active Subscriptions : {subscriptions}
                </Typography>
                <Rating value={4} readOnly />
                <Typography></Typography>
                <Typography
                    variant="body"
                >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla nemo nostrum veritatis quae culpa omnis?
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    variant='primary'
                    size='small'
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    See More
                </Button>
            </CardActions>
            <Collapse
                in={isExpanded}
                timeout="auto"
                unmountOnExit
                sx={{
                    color: theme.palette.neutral[300]
                }}
            >
                <CardContent>
                    <Typography>id: {_id}</Typography>
                    <Typography>Number of Weeks: {numberOfWeeks}</Typography>
                    {/* <Typography>Number of Active Subscriptions: {stat}</Typography> */}
                </CardContent>
            </Collapse>
        </Card>
    )
}

const Appointments = () => {
   
    const isNonMobile = useMediaQuery("(min-width: 1000px)")
    const theme = useTheme()
    const [view, setView] = useState(30)
    const [userData] = useOutletContext()
    const {data , isLoading}  = useGetAppointmentsQuery(userData.userData[0].userReferralCode);
   
  return (
    <Box m="1.5rem 2.5rem">
        <Header
            title="APPOINTMENTS"
            subtitle="See all your appointments here!" 
        />
       
        {data || !isLoading ? (
              <AppointmentTable
               
              appointments={data}
             />
          
        ) : (
        <>Loading...</>
    )}
    </Box>
  )
}

export default Appointments;