import React, {useEffect, useState} from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Navbar from "components/Navbar"
import Sidebar from "components/Sidebar"
// import Test from "components/Test"
// import { Calculate } from '@mui/icons-material';
import { useGetAccountQuery, useGetUserQuery } from 'state/api';
import { auth } from 'firebaseConfig';



const Layout = () => {
    const isNonMobile = useMediaQuery("(min-width: 600px)");
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const userId = useSelector((state) => state.global.userId);
    const user = localStorage.getItem("user")
    const userName = localStorage.getItem("user_name")
    const { data, error} = useGetUserQuery(user);
    const userData = useGetAccountQuery(auth.currentUser?.phoneNumber.replace("+", "%2b"))
    const router = useNavigate()
    // console.log('data', data)
  // if (error) {
  //    router(`/profile?partner=${userName}`)
  // } else if(userData.data && userData.data[0].role === "Admin"){
  //   router('/')
  // }

  useEffect(()=>{
    if (userData.data) {
       if(userData.data[0].role === "Admin"){
        router('/', {replace:true})
       } else {
        auth.signOut().then(()=>{
          router('/', {replace:true})
          alert("Access Denied! Please signin with admin access!")
        }).catch(e=>{
          alert("Something went wrong!")
        })
       }
    }
  },[userData.data])
   
  return ( 
    <div className=''>
         {/* <Notification /> */}
        <div>
            
           {userData.data && <Sidebar
                user={userData.data[0] || {}}
                isNonMobile={isNonMobile}
                drawerWidth="250px"
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
            />}
            
            <div className='flex flex-col flex-grow'>
                {
                    userData.data && <Navbar 
                    user={userData.data[0] || {}}
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                    isNonMobile={isNonMobile}
                />
                }
                <Box 
                // sx={{justifyContent: "space-between", marginLeft: {sm:'40%', md: '30%', lg:'25%', xl:'15%', xxl: '13%' }}}  
                sx={isSidebarOpen === true 
                    ?  {justifyContent: "center", marginLeft: {sm:'40%', md: '30%', lg:'20%', xl:'18%', xxl: '15%' }, paddingX: {xs: '6px', md:'10px', lg:'0px'} }:{justifyContent: "center", paddingX: {xs: '6px', md:'32px', lg:'0px'}, paddingY: {xs: '6px', md:'10px', lg:'16px'} }}
                >

               { userData.data && <Outlet  context={[{userData: userData.data}]} />}
                </Box>
                {/* <Test /> */}
            </div>
        </div>    
    </div>
  )
}

export default Layout