import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetMedicalRecordQuery } from "state/api";


export default function MedicalRecord(){
    const location = useLocation()
    const params = new URLSearchParams(location.search);
    const record = params.get("record")
    const {data, loading} = useGetMedicalRecordQuery(record)
    const history = useNavigate()
    console.log("record", record)
    return (
       <div className="flex flex-col justify-center items-center">
      
        {
            data && <div className="w-full md:w-3/4 align-middle items-center flex flex-col m-4">
            <img className="" src={data.document}/>
            <h4 className="mt-4">{data.name}</h4>
          </div>
        }
         <div className="flex mt-4">
       <Button sx={{bgcolor:"#3d3976", margin:"8px", color:"white"}} onClick={()=>{history(-1)}}>Go Back</Button>
       <Button sx={{bgcolor:"#3d3976", margin:"8px", color:"white"}} onClick={()=>{history(`/patient/add-record?patient=${data.user}`)}}>Add Medical Record</Button>
       </div>
       </div>
    )
}