import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  useTheme,
} from "@mui/material";
import {
  Search,
  GetApp,
  Print,
  ViewColumn,
  FilterList,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";
import { tokensDark, tokensLight } from "theme";
import { ToastContainer } from "react-toastify";
import { useGetReferralsQuery } from "state/api";
import { ArrowUpward } from "@mui/icons-material";
import { ArrowDownward } from "@mui/icons-material";
import moment from "moment";

const Referrals = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const tableRef = useRef(null);
  const theme = useTheme();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [sortOrder, setSortOrder] = useState("asc");

  const [filterCriteria, setFilterCriteria] = useState({
    name: "",
    user: "",
    code: "",
    timesUsed: "",
    organization: "",
  });

  const { data: tableData = [], isLoading } = useGetReferralsQuery();
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    setFilteredItems(tableData);
  }, [tableData]);

  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const handleRowClick = (item) => {
    if (selectedItem === item) {
      setSelectedItem(null);
    } else {
      setSelectedItem(item);
    }
  };

  const handleSortOrderChange = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
  };

  const sortTableData = () => {
    const sortedData = [...filteredItems].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });

    setFilteredItems(sortedData);
  };

  useEffect(() => {
    sortTableData();
  }, [sortOrder]);

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleFilterCriteriaChange = (event, field) => {
    const { value } = event.target;
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      [field]: value,
    }));
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value);

    const filteredData = tableData.filter((item) => {
      if (item) {
        const lowerCaseSearchQuery = value.toLowerCase();

        const name = item.name ? item.name.toLowerCase() : "";
        const user = item.user ? item.user.toLowerCase() : "";
        const code = item.code ? item.code.toLowerCase() : "";
        const timesUsed = item.timesUsed
          ? item.timesUsed.toString().toLowerCase()
          : "";
        const organization = item.organization
          ? item.organization.toLowerCase()
          : "";

        return (
          name.includes(lowerCaseSearchQuery) ||
          user.includes(lowerCaseSearchQuery) ||
          code.includes(lowerCaseSearchQuery) ||
          timesUsed.includes(lowerCaseSearchQuery) ||
          organization.includes(lowerCaseSearchQuery)
        );
      }
      return false;
    });

    setFilteredItems(filteredData);
    setCurrentPage(1);
  };

  const handleDownload = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," + convertToCSV(filteredItems);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "referrals.csv");
    document.body.appendChild(link);
    link.click();
  };

  const convertToCSV = (data) => {
    const header = ["Name", "User", "Code", "Times Used", "Organization"];
    const rows = data.map((item) => [
      item.name,
      item.user,
      item.code,
      item.timesUsed,
      item.organization,
    ]);

    const csvData = [header, ...rows]
      .map((row) => row.map((value) => `"${value}"`).join(","))
      .join("\n");
    return csvData;
  };

  const handlePrint = () => {
    const content = tableRef.current.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleResetFilterCriteria = () => {
    setFilterCriteria({
      name: "",
      user: "",
      code: "",
      timesUsed: "",
      organization: "",
    });
    setFilteredItems(tableData);
    setCurrentPage(1);
  };

  const isDarkMode = theme.palette.mode === "dark";

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const backgroundColor = theme.palette.primary.light;

  return (
    <div className="flex flex-col justify-between mb-4 p-2">
      <ToastContainer
        containerId="toast"
        autoClose={2000}
        position="top-center"
        hideProgressBar={true}
      />
      <ButtonGroup>
        <IconButton>
          <Search />
        </IconButton>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearch}
        />
        <IconButton onClick={handleDownload}>
          <GetApp />
        </IconButton>
        <IconButton onClick={handlePrint}>
          <Print />
        </IconButton>
        <IconButton>
          <ViewColumn />
        </IconButton>
        <IconButton onClick={handleFilterDialogOpen}>
          <FilterList />
        </IconButton>
      </ButtonGroup>

      <TableContainer
        ref={tableRef}
        component={Paper}
        sx={{
          backgroundColor: theme.palette.background.paper,
          marginTop: "50px",
          height: "500px",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: isDarkMode
                  ? "#06334B"
                  : tokensDark.secondary[500],
                color: isDarkMode
                  ? tokensDark.secondary[800]
                  : tokensDark.secondary[700],
                fontWeight: "bold",
              }}
            >
              <TableCell style={{ backgroundColor: theme.palette.background.alt }}>
                Name
              </TableCell>
              <TableCell style={{ backgroundColor: theme.palette.background.alt }}>
                User
              </TableCell>
              <TableCell style={{ backgroundColor: theme.palette.background.alt }}>
                Code
              </TableCell>
              <TableCell style={{ backgroundColor: theme.palette.background.alt }}>
                Times Used
              </TableCell>
              <TableCell
                style={{ backgroundColor: theme.palette.background.alt }}
                onClick={handleSortOrderChange}
              >
                Created At{" "}
                {sortOrder === "asc" ? <ArrowDownward /> : <ArrowUpward />}{" "}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentItems.map((item, index) => (
              <TableRow
                key={index}
                onClick={() => handleRowClick(item)}
                style={{
                  cursor: "pointer",
                  backgroundColor: backgroundColor,
                  color: isDarkMode
                    ? tokensDark.secondary[800]
                    : tokensDark.secondary[700],
                }}
              >
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.user}</TableCell>
                <TableCell>{item.code}</TableCell>
                <TableCell>{item.timesUsed}</TableCell>
                <TableCell>
                  {moment(item.createdAt).format("DD MMMM YYYY HH:mm")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div className=" justify-center mt-4 overflow-x-auto">
        <ButtonGroup>
          <Button
            style={{ color: isDarkMode ? "white" : "#755a0e", border: "solid" }}
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </Button>
          {Array.from({ length: totalPages }, (_, index) => (
            <Button
              style={{ color: isDarkMode ? "white" : "#755a0e", border: "solid" }}
              key={index + 1}
              disabled={currentPage === index + 1}
              variant={currentPage === index + 1 ? "contained" : "outlined"}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Button>
          ))}
          <Button
            style={{ color: isDarkMode ? "white" : "#755a0e", border: "solid" }}
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </Button>
        </ButtonGroup>
      </div>

      <Dialog
        open={openFilterDialog}
        onClose={handleFilterDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Filter</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            margin="dense"
            fullWidth
            variant="outlined"
            value={filterCriteria.name}
            onChange={(event) => handleFilterCriteriaChange(event, "name")}
          />
          <TextField
            label="User"
            margin="dense"
            fullWidth
            variant="outlined"
            value={filterCriteria.user}
            onChange={(event) => handleFilterCriteriaChange(event, "user")}
          />
          <TextField
            label="Code"
            margin="dense"
            fullWidth
            variant="outlined"
            value={filterCriteria.code}
            onChange={(event) => handleFilterCriteriaChange(event, "code")}
          />
          <TextField
            label="Times Used"
            margin="dense"
            fullWidth
            variant="outlined"
            value={filterCriteria.timesUsed}
            onChange={(event) => handleFilterCriteriaChange(event, "timesUsed")}
          />
          <TextField
            label="Organization"
            margin="dense"
            fullWidth
            variant="outlined"
            value={filterCriteria.organization}
            onChange={(event) =>
              handleFilterCriteriaChange(event, "organization")
            }
          />
          <Button variant="contained" onClick={handleFilterDialogClose}>
            Apply Filter
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: "8px" }}
            onClick={handleResetFilterCriteria}
          >
            Reset Filters
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Referrals;
