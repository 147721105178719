import { Box } from '@mui/material';
import AgoraVideoComponent from 'components/agora-video-component/TestVideo';
import { VideoRoom } from 'components/agora-video-component/VideoRoom';
import Loading from 'components/Loading';

import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetVideoCallTokenQuery } from 'state/api';



function VideoCall() {
 
  const location = useLocation()
  const params = new URLSearchParams(location.search);
  const channelId = params.get("appointment")
  const { data, isLoading, } = useGetVideoCallTokenQuery(channelId, true);
  // Get the value of a query parameter
  const token = data?.data.token;
  
  const uid = data?.data.uid
  console.log(data)
  return (
    <Box >
        
        { data &&  <AgoraVideoComponent TestVideo app="31c2fa7e0667488e844e4ff37c8fd63c" channel={channelId}  token={token} uid={uid}/> }
        { isLoading && <Loading/>}
    </Box>
  );
}

export default VideoCall;
