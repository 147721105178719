import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  useTheme,
} from "@mui/material";
import {
  Search,
  GetApp,
  Print,
  ViewColumn,
  FilterList,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";
import { tokensDark, tokensLight } from "theme";
import { ToastContainer } from "react-toastify";
import { useGetScenarioMapsQuery } from "state/api"; // Import the useGetScenarioMapsQuery hook from your API file
import { ArrowUpward } from "@mui/icons-material";
import { ArrowDownward } from "@mui/icons-material";
const ScenarioMaps = () => {
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const tableRef = useRef(null);
  const theme = useTheme();

  const [filterCriteria, setFilterCriteria] = useState({
    scenario: "",
    painPoint: "",
    plan: "",
  });

  const { data: tableData = [], isLoading } = useGetScenarioMapsQuery();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [filteredScenarios, setFilteredScenarios] = useState(tableData);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    setFilteredScenarios(tableData);
    setCurrentPage(1);
  }, [tableData]);

  const handleRowClick = (scenario) => {
    if (selectedScenario === scenario) {
      setSelectedScenario(null);
    } else {
      setSelectedScenario(scenario);
    }
  };

  const handleSortOrderChange = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
  };
  const sortTableData = () => {
    const sortedData = [...tableData].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
    console.log(sortedData);
    setFilteredScenarios(sortedData);
  };

  useEffect(() => {
    sortTableData();
  }, [sortOrder]);

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleFilterCriteriaChange = (event, field) => {
    const { value } = event.target;
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      [field]: value,
    }));

    const filteredData = tableData.filter((feedback) => {
      const { scenario, painPoint, plan } = feedback;
      const lowerCaseSearchQuery = event.target.value;

      return (
        (scenario &&
          scenario.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (painPoint &&
          painPoint.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (plan && plan.toString().toLowerCase().includes(lowerCaseSearchQuery))
      );
    });

    setFilteredScenarios(filteredData);
    setCurrentPage(1);
  };

  const handleResetFilterCriteria = () => {
    setFilterCriteria({
      scenario: "",
      painPoint: "",
      plan: "",
    });
    setFilteredScenarios(tableData);
    setCurrentPage(1);
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleExport = () => {
    const table = tableRef.current;
    const csv = [];
    const rows = table.getElementsByTagName("tr");

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const cols = row.querySelectorAll("td, th");
      const csvRow = [];

      for (let j = 0; j < cols.length; j++) {
        const col = cols[j].innerText.replace(",", "");
        csvRow.push(col);
      }

      csv.push(csvRow.join(","));
    }

    const csvContent = csv.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "scenarios.csv");
  };

  const handlePrint = () => {
    const content = tableRef.current.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
          </head>
          <body>
            ${content}
          </body>
        </html>
      `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value);

    const filteredData = tableData.filter((feedback) => {
      const { scenario, painPoint, plan } = feedback;
      const lowerCaseSearchQuery = event.target.value;

      return (
        scenario.toString().toLowerCase().includes(lowerCaseSearchQuery) ||
        painPoint.toString().toLowerCase().includes(lowerCaseSearchQuery) ||
        plan.toString().toLowerCase().includes(lowerCaseSearchQuery)
      );
    });
    console.log(filteredData);
    setFilteredScenarios(filteredData);
    setCurrentPage(1);
  };

  const isDarkMode = theme.palette.mode === "dark";
  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = filteredScenarios.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredScenarios.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div className="flex flex-col justify-between mb-4">
      <ToastContainer
        containerId="toast"
        autoClose={2000}
        position="top-center"
        hideProgressBar={true}
      />
      <ButtonGroup>
        <IconButton>
          <Search />
        </IconButton>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => {
            handleSearch(e);
          }}
        />
        <IconButton onClick={handleExport}>
          <GetApp />
        </IconButton>
        <IconButton onClick={handlePrint}>
          <Print />
        </IconButton>
        <IconButton>
          <ViewColumn />
        </IconButton>
        <IconButton onClick={handleFilterDialogOpen}>
          <FilterList />
        </IconButton>
      </ButtonGroup>

      <TableContainer
        ref={tableRef}
        component={Paper}
        sx={{
          backgroundColor: theme.palette.background.paper,
          marginTop: "50px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow
              style={{
                cursor: "pointer",
                backgroundColor: "#06334B",
                color: isDarkMode
                  ? tokensDark.secondary[800]
                  : tokensDark.secondary[700],
              }}
            >
              <TableCell>Scenario</TableCell>
              <TableCell>Pain Point</TableCell>
              <TableCell onClick={handleSortOrderChange}>
                Plan{" "}
                {sortOrder === "asc" ? <ArrowDownward /> : <ArrowUpward />}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentItems.map((scenario, index) => (
              <TableRow
                key={index}
                onClick={() => handleRowClick(scenario)}
                className={selectedScenario === scenario ? "selected-row" : ""}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#095271",
                  color: isDarkMode
                    ? tokensDark.secondary[800]
                    : tokensDark.secondary[700],
                }}
              >
                <TableCell>{scenario.scenario}</TableCell>
                <TableCell>{scenario.painPoint}</TableCell>
                <TableCell>{scenario.plan.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <ButtonGroup style={{ justifyContent: "center" }}>
        <Button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </Button>
        {Array.from({ length: totalPages }, (_, i) => (
          <Button
            key={i + 1}
            variant={currentPage === i + 1 ? "contained" : "outlined"}
            onClick={() => handlePageChange(i + 1)}
          >
            {i + 1}
          </Button>
        ))}
        <Button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </Button>
      </ButtonGroup>
      <Dialog open={openFilterDialog} onClose={handleFilterDialogClose}>
        <DialogTitle>Filter Scenarios</DialogTitle>
        <DialogContent>
          <TextField
            label="Scenario"
            value={filterCriteria.scenario}
            fullWidth
            onChange={(event) => handleFilterCriteriaChange(event, "scenario")}
          />
          <TextField
            label="Pain Point"
            value={filterCriteria.painPoint}
            fullWidth
            onChange={(event) => handleFilterCriteriaChange(event, "painPoint")}
          />
          <TextField
            label="Plan"
            value={filterCriteria.plan}
            fullWidth
            onChange={(event) => handleFilterCriteriaChange(event, "plan")}
          />
          <Button variant="contained" onClick={handleFilterDialogClose}>
            Apply Filter
          </Button>

          <Button
            variant="contained"
            style={{ marginLeft: "8px" }}
            onClick={handleResetFilterCriteria}
          >
            Reset Filters
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ScenarioMaps;
