import React, { useEffect, useRef, useState } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { VideoPlayer } from './AgoraVideoComponent';  
import VideoCallController from 'components/controls/Controls';
import { Box } from '@mui/material';
import { VideoPlayerPinned } from './VideoPlayPinned';
import { Person, VideocamOff } from '@mui/icons-material';
 

const APP_ID = "31c2fa7e0667488e844e4ff37c8fd63c";

const client = AgoraRTC.createClient({
  mode: 'rtc',
  codec: 'vp8',
});

export const VideoRoom = ({channel, token, uid}) => {
  const [users, setUsers] = useState([]);
  const [localUser, setLocalUser] = useState(null);
  const [localTracks, setLocalTracks] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [mute, setMute] = useState(false)
  const [videoHidden, setVideoHidden] = useState(false)
  const [pinnedUser, setPinnedUser] = useState(null)
  let localRef = useRef()
  const handlePinned = (user) => {
    setPinnedUser(user)
  }
  const handleUserJoined = async (user, mediaType) => {
    await client.subscribe(user, mediaType);
    let updatedUsers = users;
    if (mediaType === 'video') {     
        
        if (updatedUsers.filter(u=> u.uid == user.uid).length > 0) {
            
            const nextCounters = updatedUsers.filter((u)=>u.uid !== user.uid)
            nextCounters.push({uid:user.uid, audioTrack:user.audioTrack, videoTrack:user.videoTrack})
           setUsers(nextCounters);
        } else {
            updatedUsers.push({uid:user.uid, audioTrack:user.audioTrack, videoTrack:user.videoTrack})
            setUsers(updatedUsers);
            setPinnedUser({uid:user.uid, audioTrack:user.audioTrack, videoTrack:user.videoTrack})
        }
    }
    if (mediaType === 'audio') {
        // setUsers((previousUsers) => [...previousUsers, user]);
      user.audioTrack.play()
     
    }
     
  };

  const onVideoHide =()=>{
    setVideoHidden(!videoHidden)
    
  }
  const onVideoShow =()=>{
    setVideoHidden(!videoHidden)
  }
  const onCallLeave = () => {
    for (let localTrack of localTracks) {
        localTrack.stop();
        localTrack.close();
      }
     
  }
  const onUnmute = () => {
    setMute(!mute)
  }
  const onMute = () => {
    setMute(!mute)
  }

  const handleUserLeft = (user) => {
    const nextCounters = user.filter((u)=>u.uid !== user.uid)
    setUsers(nextCounters
    );
  };

  useEffect(() => {
    
    client.on("user-published", handleUserJoined);
    client.on('user-left', handleUserLeft);

    client
      .join(APP_ID, channel, token, uid)
      .then((uid) =>
        Promise.all([
          AgoraRTC.createMicrophoneAndCameraTracks(),
          uid,
        ])
      )
      .then(([tracks, uid]) => {
        const [audioTrack, videoTrack] = tracks;
    
        setLocalTracks(tracks);
        setLocalUser({
            uid,
            videoTrack,
            audioTrack,
          })
        setUsers((previousUsers) => [
          ...previousUsers,
          {
            uid,
            videoTrack,
            audioTrack,
          },
        ]);
        client.publish(tracks);
      });

    return () => {
      for (let localTrack of localTracks) {
        localTrack.stop();
        localTrack.close();
        
      }
      client.off('user-published', handleUserJoined);
      client.off('user-left', handleUserLeft);
      client.unpublish(localTracks).then(() => client.leave());
    };
  }, []);

 useEffect(()=>{
  if (localUser) {
    localUser.videoTrack.play(localRef.current)
  }
 },[localUser])
  return (
    <div >
    
        {
           pinnedUser && 
          <Box  borderRadius="8px">
        <VideoPlayerPinned user={pinnedUser} isVideoHidden={!videoHidden}/>
       <VideoCallController localTracks={localTracks} onMute={onMute} onUnmute={onUnmute} onLeaveCall={onCallLeave} onVideoHide={onVideoHide} onVideoShow={onVideoShow}  isMuted={mute} isVideoHidden={!videoHidden}/>
      </Box>
           
        }   
          <Box 
           mt="20px" 
           display="grid" 
           gridTemplateColumns="repeat(4, minmax(0, 1fr))" 
           justifyContent="space-between"
           rowGap="20px"
           columnGap="1.33%"
          >
        
        {users.map((user, index) => (
           <VideoPlayer handlePinned={handlePinned} index={index} key={index} user={user} />
        ))}
    
      </Box>
      {
        localUser &&  <div ref={localRef} style={{height:"200px", width:"200px"}}/>
      }
    </div>
  );
};