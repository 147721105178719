import { initializeApp } from 'firebase/app'
import {getAuth, signInWithPhoneNumber} from 'firebase/auth'
import { getStorage } from "firebase/storage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyABXGrNZ7XC4iHc7g5OFz7lq1wyCZTdyBo",
    authDomain: "casamed-6ec79.firebaseapp.com",
    databaseURL: "https://casamed-6ec79-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "casamed-6ec79",
    storageBucket: "casamed-6ec79.appspot.com",
    messagingSenderId: "729779463558",
    appId: "1:729779463558:web:a5c540c3e5b6010e27f807",
    measurementId: "G-Q3LF8YQCLT"
}

// Initialize Firebase and Firebase Authentication
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const storage = getStorage(app)

const signin = (phoneNumber, verifier) => signInWithPhoneNumber(auth, phoneNumber, verifier);
 
  
export { signin, auth, storage }