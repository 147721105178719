import React, {useState} from 'react';
import AgoraUIKit from 'agora-react-uikit';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AgoraVideoComponent = ({app, channel, token, uid}) => {
  const [videoCall, setVideoCall] = useState(true);
  const navigate = useNavigate()
  const rtcProps = {
    appId:app,
    channel: channel, // your agora channel
    token: token,// use null or skip if using app in testing mode
    uid:uid
  };
  const callbacks = {
    EndCall: () => {
        setVideoCall(false)
        navigate('/appointments', {replace:true})
        window.location.reload()
    },
  };
  return videoCall ? (
    <Box  style={{display: 'flex',backgroundColor:"#146db9", width:'100%',height: '100vh'}} >
      <AgoraUIKit rtcProps={rtcProps} callbacks={callbacks} />
    </Box>
  ) : (
   
    <div>
        
    </div>
  );
};

export default AgoraVideoComponent;