import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useOutletContext } from "react-router-dom";
import { useCreatePrescriptionMutation, useGetCustomersQuery } from "state/api";

const PrescriptionFormComponent = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    if (diagnosis.trim().length === 0 || complain.trim().length === 0 || conclusion.trim().length === 0) {
      alert("Please enter all fields!")
    } else {
      const prescription = {
        partner:userData.userData[0]._id,
        patient: userId,
        diagnosis: diagnosis,
        complaint: complain,
        conclusion: conclusion
      }
      createPrescription(prescription).unwrap().then((d)=>{
        alert("Prescription created successfully!")
        window.location.reload()
      }).catch((e)=>{
        alert("Something went wrong while creating prescription!")
      })
    }
  };
  const theme = useTheme();
  const [userData] = useOutletContext()
  
  const { data, isLoading } = useGetCustomersQuery(userData.userData[0].userReferralCode);
  const [userId, setUserId] = useState(data && data[0]._id)
  const [createPrescription] = useCreatePrescriptionMutation()
  const [diagnosis, setDiagnosis] = useState("")
  const [complain, setComplain] = useState("")
  const [conclusion, setConclusion] = useState("")

  useEffect(()=>{
    console.log(userId)
  },[userId])
  return (
  <div
      style={{
        backgroundColor: theme.palette.mode === "dark" ? "#090e45" : "#f7d06d",
        minHeight: "100vh",
        padding: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin:"8px",
        borderRadius:"8px"
      }}
    >
      <h1
        style={{
          color: "white",
          fontSize: "32px",
          fontWeight: "bold",
          marginBottom: theme.spacing(6),
        }}
      >
        Prescription Form
      </h1>
     {isLoading === false ? <form
        onSubmit={handleSubmit}
        style={{
          width: "100%",
          color:"black",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        {/* <label htmlFor="partnerName" style={{ marginBottom: theme.spacing(1) }}>
          Partner Name:
        </label>
        <input
          type="text"
          id="partnerName"
          name="partnerName"
          placeholder="Enter partner name"
          style={{
            width: "100%",
            padding: "8px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            marginBottom: theme.spacing(2),
          }}
        /> */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: theme.spacing(2),
            width: "100%",
          }}
        >
          {/* <div style={{ flex: 1, marginRight: theme.spacing(1) }}>
            <label htmlFor="logo">Partner Logo:</label>
            <input
              type="file"
              id="logo"
              name="logo"
              accept="image/*"
              style={{
                width: "100%",
                padding: "8px",
              }}
            />
          </div> */}
          <div style={{ flex: 2 }}>
            <label
              htmlFor="patientName"
              style={{ marginBottom: theme.spacing(1), color:"white" }}
            >
              Select Patient
            </label>
            <select
              type="text"
              id="patientName"
              name="patientName"
              placeholder="Enter patient name"
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginBottom: theme.spacing(2),
              }}
              onChange={(e)=>{setUserId(e.target.value)}}
            >
              {data && data.map((u)=>{
                return <option value={u._id}>{u.name}</option>
              })}
            </select>

            {/* <label
              htmlFor="personalDetails"
              style={{ marginBottom: theme.spacing(1) }}
            >
              Personal Details:
            </label>
            <textarea
              id="personalDetails"
              name="personalDetails"
              placeholder="Enter personal details"
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginBottom: theme.spacing(2),
              }}
            /> */}
          </div>
        </div>

        <label htmlFor="complaint" style={{ marginBottom: theme.spacing(1), color:"white"  }}>
          Complaint
        </label>
        <textarea
          id="complaint"
          name="complaint"
          placeholder="Enter complaint"
          style={{
            width: "100%",
            padding: "8px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            marginBottom: theme.spacing(2),
          
          }}
          onChange={(e)=>{setComplain(e.target.value)}}
        />

        <label htmlFor="diagnosis" style={{ marginBottom: theme.spacing(1), color:"white"  }}>
          Diagnosis
        </label>
        <textarea
          id="complaint"
          name="complaint"
          placeholder="Enter diagnosis"
          style={{
            width: "100%",
            padding: "8px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            marginBottom: theme.spacing(2),
          }}
          onChange={(e)=>{setDiagnosis(e.target.value)}}
        />

        <label htmlFor="conclusion" style={{ marginBottom: theme.spacing(1), color:"white"  }}>
          Conclusion
        </label>
        <textarea
          id="conclusion"
          name="conclusion"
          placeholder="Enter conclusion"
          style={{
            width: "100%",
            padding: "8px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            marginBottom: theme.spacing(2),
          }}
          onChange={(e)=>{setConclusion(e.target.value)}}
        />

        <button
          type="submit"
          style={{
            backgroundColor: "#59c229",
            color: "#fff",
            padding: "12px 24px",
            borderRadius: "4px",
            border: "none",
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "bold",
            transition: "background-color 0.2s ease",
          }}
        >
          Submit
        </button>
      </form> : 
      <div className="loader">
      
      </div>
      }
    </div> 
  );
};

export default PrescriptionFormComponent;
