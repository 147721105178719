import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  useTheme,
} from "@mui/material";
import {
  Search,
  GetApp,
  Print,
  ViewColumn,
  FilterList,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";
import { tokensDark, tokensLight } from "theme";
import { ToastContainer } from "react-toastify";
import { useGetCouponsQuery } from "state/api";

const Coupons = () => {
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const tableRef = useRef(null);
  const theme = useTheme();

  const [filterCriteria, setFilterCriteria] = useState({
    discount: "",
    couponCode: "",
    numberOfCoupons: "",
    timesUsed: "",
    description: "",
    validFrom: "",
    validTill: "",
    type: "",
    productIds: "",
    referralIds: "",
    location: "",
    maxAge: "",
    minAge: "",
    private: "",
  });

  const { data: tableData = [], isLoading } = useGetCouponsQuery();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);

  const [filteredCoupons, setFilteredCoupons] = useState(tableData);
  useEffect(() => {
    setFilteredCoupons(tableData);
    setCurrentPage(1);
  }, [tableData]);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const handleRowClick = (coupon) => {
    if (selectedCoupon === coupon) {
      setSelectedCoupon(null);
    } else {
      setSelectedCoupon(coupon);
    }
  };

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleFilterCriteriaChange = (event, field) => {
    const { value } = event.target;
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      [field]: value,
    }));

    const filteredData = tableData.filter((coupon) => {
      const {
        couponCode,
        numberOfCoupons,
        timesUsed,
        description,
        validFrom,
        validTill,
        type,
        productIds,
        referralIds,
        location,
        maxAge,
        minAge,
        private: isPrivate,
      } = coupon;
      const { name } = coupon.discount;
      const lowerCaseSearchQuery = event.target.value;

      return (
        (name &&
          name.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (couponCode &&
          couponCode.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (numberOfCoupons &&
          numberOfCoupons
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (timesUsed &&
          timesUsed.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (description &&
          description
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (validFrom &&
          validFrom.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (validTill &&
          validTill.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (type &&
          type.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (productIds && productIds.toString().includes(lowerCaseSearchQuery)) ||
        (referralIds &&
          referralIds.toString().includes(lowerCaseSearchQuery)) ||
        (location &&
          location.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (maxAge &&
          maxAge.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (minAge &&
          minAge.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (isPrivate &&
          isPrivate.toString().toLowerCase().includes(lowerCaseSearchQuery))
      );
    });
    console.log(filteredData);
    setFilteredCoupons(filteredData);
    setCurrentPage(1); // Reset to the first page
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value);

    const filteredData = tableData.filter((coupon) => {
      const {
        couponCode,
        numberOfCoupons,
        timesUsed,
        description,
        validFrom,
        validTill,
        type,
        productIds,
        referralIds,
        location,
        maxAge,
        minAge,
        private: isPrivate,
      } = coupon;
      const { name } = coupon.discount;
      const lowerCaseSearchQuery = event.target.value;

      return (
        (name &&
          name.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (couponCode &&
          couponCode.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (numberOfCoupons &&
          numberOfCoupons
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (timesUsed &&
          timesUsed.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (description &&
          description
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (validFrom &&
          validFrom.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (validTill &&
          validTill.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (type &&
          type.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (productIds && productIds.toString().includes(lowerCaseSearchQuery)) ||
        (referralIds &&
          referralIds.toString().includes(lowerCaseSearchQuery)) ||
        (location &&
          location.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (maxAge &&
          maxAge.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (minAge &&
          minAge.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (isPrivate &&
          isPrivate.toString().toLowerCase().includes(lowerCaseSearchQuery))
      );
    });

    setFilteredCoupons(filteredData);
    console.log(filteredData);
    setCurrentPage(1); // Reset to the first page
  };

  const handleDownload = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," + convertToCSV(filteredCoupons);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "coupons.csv");
    document.body.appendChild(link);
    link.click();
  };

  const convertToCSV = (data) => {
    const header = [
      "Discount",
      "Coupon Code",
      "Number of Coupons",
      "Times Used",
      "Description",
      "Valid From",
      "Valid Till",
      "Type",
      "Product IDs",
      "Referral IDs",
      "Location",
      "Max Age",
      "Min Age",
      "Private",
    ];
    const rows = data.map((coupon) => [
      coupon.discount.name,
      coupon.couponCode,
      coupon.numberOfCoupons,
      coupon.timesUsed,
      coupon.description,
      coupon.validFrom,
      coupon.validTill.toString(),
      coupon.type,
      coupon.productIds,
      coupon.referralIds,
      coupon.location,
      coupon.maxAge,
      coupon.minAge,
      coupon.private,
    ]);

    const csvData = [header, ...rows]
      .map((row) => row.map((value) => `"${value}"`).join(","))
      .join("\n");
    return csvData;
  };

  const handlePrint = () => {
    const content = tableRef.current.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleViewColumn = () => {
    // Implement the logic to show/hide columns
  };

  const isDarkMode = theme.palette.mode === "dark";
  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredCoupons.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredCoupons.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleResetFilterCriteria = () => {
    setFilterCriteria({
      discount: "",
      couponCode: "",
      numberOfCoupons: "",
      timesUsed: "",
      description: "",
      validFrom: "",
      validTill: "",
      type: "",
      productIds: "",
      referralIds: "",
      location: "",
      maxAge: "",
      minAge: "",
      private: "",
    });

    const filteredData = tableData.filter((coupon) => {
      const {
        discount,
        couponCode,
        numberOfCoupons,
        timesUsed,
        description,
        validFrom,
        validTill,
        type,
        productIds,
        referralIds,
        location,
        maxAge,
        minAge,
        private: isPrivate,
      } = coupon;
      const lowerCaseSearchQuery = searchQuery.toLowerCase();

      return (
        discount.toString().toLowerCase().includes(lowerCaseSearchQuery) ||
        couponCode.toString().toLowerCase().includes(lowerCaseSearchQuery) ||
        numberOfCoupons
          .toString()
          .toLowerCase()
          .includes(lowerCaseSearchQuery) ||
        timesUsed.toString().toLowerCase().includes(lowerCaseSearchQuery) ||
        description.toString().toLowerCase().includes(lowerCaseSearchQuery) ||
        validFrom.toString().toLowerCase().includes(lowerCaseSearchQuery) ||
        validTill.toString().toLowerCase().includes(lowerCaseSearchQuery) ||
        type.toString().toLowerCase().includes(lowerCaseSearchQuery) ||
        productIds.toString().toLowerCase().includes(lowerCaseSearchQuery) ||
        referralIds.toString().toLowerCase().includes(lowerCaseSearchQuery) ||
        location.toString().toLowerCase().includes(lowerCaseSearchQuery) ||
        maxAge.toString().toLowerCase().includes(lowerCaseSearchQuery) ||
        minAge.toString().toLowerCase().includes(lowerCaseSearchQuery) ||
        isPrivate.toString().toLowerCase().includes(lowerCaseSearchQuery)
      );
    });

    setFilteredCoupons(filteredData);
    setCurrentPage(1); // Reset to the first page
  };

  return (
    <>
      <div className="flex flex-col justify-between mb-4 p-4">
        <ToastContainer
          containerId="toast"
          autoClose={2000}
          position="top-center"
          hideProgressBar={true}
        />
        <ButtonGroup>
          <IconButton>
            <Search />
          </IconButton>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={(e) => {
              handleSearch(e);
            }}
          />
          <IconButton onClick={handleDownload}>
            <GetApp />
          </IconButton>
          <IconButton onClick={handlePrint}>
            <Print />
          </IconButton>
          <IconButton>
            <ViewColumn />
          </IconButton>
          <IconButton onClick={handleFilterDialogOpen}>
            <FilterList />
          </IconButton>
        </ButtonGroup>

        {/* Table */}
        <TableContainer
          ref={tableRef}
          component={Paper}
          sx={{
            backgroundColor: theme.palette.background.paper,
            marginTop: "50px",
          }}
        >
          <Table>
            <TableHead>
              <TableRow
                style={{
                  cursor: "pointer",
                  backgroundColor: theme.palette.background.alt,
                  color: isDarkMode
                    ? tokensDark.secondary[800]
                    : tokensDark.secondary[700],
                }}
              >
                <TableCell>Discount</TableCell>
                <TableCell>Coupon Code</TableCell>
                <TableCell>Number of Coupons</TableCell>
                <TableCell>Times Used</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Valid From</TableCell>
                <TableCell>Valid Till</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Product IDs</TableCell>
                <TableCell>Referral IDs</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Max Age</TableCell>
                <TableCell>Min Age</TableCell>
                <TableCell>Private</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCoupons.map((coupon, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleRowClick(coupon)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: theme.palette.primary.light,
                    color: isDarkMode
                      ? tokensDark.secondary[800]
                      : tokensDark.secondary[700],
                  }}
                >
                  <TableCell>{coupon.discount.name}</TableCell>
                  <TableCell>{coupon.couponCode}</TableCell>
                  <TableCell>{coupon.numberOfCoupons}</TableCell>
                  <TableCell>{coupon.timesUsed}</TableCell>
                  <TableCell>{coupon.description}</TableCell>
                  <TableCell>{coupon.validFrom}</TableCell>
                  <TableCell>{coupon.validTill}</TableCell>
                  <TableCell>{coupon.type}</TableCell>
                  <TableCell>{coupon.productIds}</TableCell>
                  <TableCell>{coupon.referralIds}</TableCell>
                  <TableCell>{coupon.location}</TableCell>
                  <TableCell>{coupon.maxAge}</TableCell>
                  <TableCell>{coupon.minAge}</TableCell>
                  <TableCell>{coupon.private}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <div className="flex justify-center mt-4">
          <ButtonGroup>
            <Button
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </Button>
            {Array.from({ length: totalPages }, (_, i) => (
              <Button
                key={i + 1}
                variant={currentPage === i + 1 ? "contained" : "outlined"}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </Button>
            ))}
            <Button
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </Button>
          </ButtonGroup>
        </div>

        {/* Filter Dialog */}
        <Dialog open={openFilterDialog} onClose={handleFilterDialogClose}>
          <DialogTitle>Filter Table Data</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Discount"
              fullWidth
              variant="outlined"
              value={filterCriteria.discount}
              onChange={(e) => handleFilterCriteriaChange(e, "discount")}
            />

            <TextField
              margin="dense"
              label="Coupon Code"
              fullWidth
              variant="outlined"
              value={filterCriteria.couponCode}
              onChange={(e) => handleFilterCriteriaChange(e, "couponCode")}
            />

            <TextField
              margin="dense"
              label="Number of Coupons"
              fullWidth
              variant="outlined"
              value={filterCriteria.numberOfCoupons}
              onChange={(e) => handleFilterCriteriaChange(e, "numberOfCoupons")}
            />

            <TextField
              margin="dense"
              label="Times Used"
              fullWidth
              variant="outlined"
              value={filterCriteria.timesUsed}
              onChange={(e) => handleFilterCriteriaChange(e, "timesUsed")}
            />

            <TextField
              margin="dense"
              label="Description"
              fullWidth
              variant="outlined"
              value={filterCriteria.description}
              onChange={(e) => handleFilterCriteriaChange(e, "description")}
            />

            <TextField
              margin="dense"
              label="Valid From"
              fullWidth
              variant="outlined"
              value={filterCriteria.validFrom}
              onChange={(e) => handleFilterCriteriaChange(e, "validFrom")}
            />

            <TextField
              margin="dense"
              label="Valid Till"
              fullWidth
              variant="outlined"
              value={filterCriteria.validTill}
              onChange={(e) => handleFilterCriteriaChange(e, "validTill")}
            />

            <TextField
              margin="dense"
              label="Type"
              fullWidth
              variant="outlined"
              value={filterCriteria.type}
              onChange={(e) => handleFilterCriteriaChange(e, "type")}
            />

            <TextField
              margin="dense"
              label="Product IDs"
              fullWidth
              variant="outlined"
              value={filterCriteria.productIds}
              onChange={(e) => handleFilterCriteriaChange(e, "productIds")}
            />

            <TextField
              margin="dense"
              label="Referral IDs"
              fullWidth
              variant="outlined"
              value={filterCriteria.referralIds}
              onChange={(e) => handleFilterCriteriaChange(e, "referralIds")}
            />

            <TextField
              margin="dense"
              label="Location"
              fullWidth
              variant="outlined"
              value={filterCriteria.location}
              onChange={(e) => handleFilterCriteriaChange(e, "location")}
            />

            <TextField
              margin="dense"
              label="Max Age"
              fullWidth
              variant="outlined"
              value={filterCriteria.maxAge}
              onChange={(e) => handleFilterCriteriaChange(e, "maxAge")}
            />

            <TextField
              margin="dense"
              label="Min Age"
              fullWidth
              variant="outlined"
              value={filterCriteria.minAge}
              onChange={(e) => handleFilterCriteriaChange(e, "minAge")}
            />

            <TextField
              margin="dense"
              label="Private"
              fullWidth
              variant="outlined"
              value={filterCriteria.private}
              onChange={(e) => handleFilterCriteriaChange(e, "private")}
            />

            <Button variant="contained" onClick={handleFilterDialogClose}>
              Apply Filter
            </Button>

            <Button
              variant="contained"
              style={{ marginLeft: "8px" }}
              onClick={handleResetFilterCriteria}
            >
              Reset Filters
            </Button>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default Coupons;
