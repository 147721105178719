import { useState } from 'react';
import PlayVideo from './PlayVideo';
import FlexBetween from './FlexBetween';
import { InputBase, InputLabel, MenuItem, Select, useTheme } from '@mui/material';
import { Search } from '@mui/icons-material';

function Exercises({ exercises, handleAddExercise, showModal, setShowModal }) {

    const [showVideo, setShowVideo] = useState(false)
    const [selectedEx, setSelectedEx] = useState(0)
    const [reps, setReps] = useState(3);
    const [sets, setSets] = useState(1);
    const [success, setSuccess] = useState(false);
    const [search, setSearch] = useState('')
    const [painPoint, setPainPoint] = useState("lowerBack")
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    const addExercise = (exercise, exerciseIndex) => {
        if (sets <= 0 || reps <= 0) {
            alert("Enter Reps and Sets correctly")
        } else {
            const exerciseNew = {
            exercise: exercise._id,
            reps: reps,
            sets: sets,
            times: 1
        }
        setSelectedEx(exerciseIndex)
        handleAddExercise(exerciseNew)
        setSuccess(true)
        setTimeout(()=>{
            setSuccess(false)
        },2000)
        }
    }
    return (
        <div >
            {
                showVideo && <PlayVideo setShowModal={setShowVideo} showModal={showVideo} videoUrl={exercises[selectedEx].videoUrl} />
            }
            {showModal && (
                <div className="fixed  inset-0 w-full overflow-y-auto z-20" style={{  backgroundColor:theme.palette.background.alt}}>

                    <div className="flex  w-full min-h-screen">
                        <button
                            className="absolute top-4 right-4 bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-lg"
                            onClick={handleCloseModal}
                        >
                            Close
                        </button>

                        <div className='flex flex-col h-full'>
                            <FlexBetween className='ml-8'>

                                <div className='flex flex-col md:flex-row gap-4 items-center'>

                                    <FlexBetween
                                        backgroundColor={theme.palette.primary.light}
                                        borderRadius="9px"
                                        gap="3rem"

                                        mt={2}
                                        p="0.1rem 1.5rem"
                                    >
                                        <InputBase onChange={(e) => { setSearch(e.target.value) }} placeholder="Search by name..." />
                                        <Search />
                                    </FlexBetween>
                                    <div className='flex items-center gap-2 justify-center'>
                                   <span className='mt-4'>Pain Point</span>
                                    {/* <InputLabel sx={{ marginLeft: "8px" }}>Pain Point</InputLabel> */}
                                    <Select
                                sx={{ marginLeft: "8px" }}
                                value={painPoint}
                                variant='standard'
                                label="Pain Point"
                                onChange={(e) => setPainPoint(e.target.value)}
                                className='mt-0 md:mt-4'
                            >
                                <MenuItem value={"lowerBack"}>Lowerback</MenuItem>
                                <MenuItem value={"upperBack"}>Upperback</MenuItem>
                                <MenuItem value={"shoulder"}>Shoulder</MenuItem>
                                <MenuItem value={"knee"}>Knee</MenuItem>
                                <MenuItem value={"hip"}>Hip</MenuItem>
                                <MenuItem value={"neck"}>Neck</MenuItem>
                                <MenuItem value={"ankle"}>Ankle</MenuItem>
                                <MenuItem value={"elbow"}>Elbow</MenuItem>
                                <MenuItem value={"wristAndHand"}>Wrist and Hand</MenuItem>
                            </Select>
                                    </div>
                                </div>
                            </FlexBetween>
                            <div className=" grid grid-cols-1 md:grid-cols-3 gap-4 rounded-lg p-8 w-full mx-auto mt-2" >

                                {exercises.filter((e)=>e.painPoints.includes(painPoint) && e.name.trim().toLowerCase().includes(search.trim().toLowerCase())).map((exercise, exerciseIndex) => {
                                    return <div key={exerciseIndex} className=" text-[#ffe3a3] mt-4 p-4 rounded-md" style={{backgroundColor: theme.palette.background.default, color: isDarkMode ? "#ffe3a3" : theme.palette.secondary.dark}}>
                                        <div className="mb-4">
                                            <img onClick={() => {
                                                setSelectedEx(exerciseIndex)
                                                setShowVideo(true)
                                            }} src={exercise.thumbnailUrl} className="h-56  cursor-pointer" />
                                        </div>
                                        <div className="mb-4">

                                            <label className="block font-bold  mb-2" htmlFor={`exerciseName${exerciseIndex}`}>
                                                {exercise.name}
                                            </label>
                                            <label className="block font-bold  mb-2" htmlFor={`exerciseName${exerciseIndex}`}>
                                                {exercise.painPoints.join(",")}
                                            </label>

                                        </div>
                                        <div className="grid  grid-cols-2 gap-4">
                                            <div className="mb-4">
                                                <label className="block font-bold mb-2" htmlFor={`exerciseReps${exerciseIndex}`}>
                                                    Reps
                                                </label>
                                                <input
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id={`exerciseReps${exerciseIndex}`}
                                                    type="number"
                                                    placeholder="Reps"
                                                    min={1}
                                                    value={reps}
                                                    onChange={(e) => { setReps(Number.parseInt(e.target.value)) }}
                                                />
                                            </div>
                                            <div className="mb-4">
                                                <label className="block font-bold mb-2" htmlFor={`exerciseSets${exerciseIndex}`}>
                                                    Sets
                                                </label>
                                                <input
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id={`exerciseSets${exerciseIndex}`}
                                                    type="number"
                                                    placeholder="Sets"
                                                    min={1}
                                                    value={sets}
                                                    onChange={(e) => { setSets(Number.parseInt(e.target.value)) }}
                                                />
                                            </div>

                                        </div>

                                        <button onClick={() => { addExercise(exercise, exerciseIndex) }} className="bg-gray-300 bg-opacity-40 mt-2 rounded w-full p-2">Add Exercise</button>
                                        {
                                            success && selectedEx === exerciseIndex && <div key={exerciseIndex} className='w-full p-4 mt-2 rounded-lg bg-green-200 text-green-600 text-center'>
                                                Exercise Added Successfully!
                                            </div>
                                        }
                                    </div>
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Exercises;
