import { DarkMode, Search } from "@mui/icons-material";
import PlayVideo from "components/PlayVideo";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import FlexBetween from "components/FlexBetween";
import { InputBase, InputLabel, MenuItem, Select } from "@mui/material";

function ExerciseView({
  exercises,
  handleAddExercise,
  showModal,
  setShowModal,
}) {
  const [showVideo, setShowVideo] = useState(false);
  const [selectedEx, setSelectedEx] = useState(0);
  const [reps, setReps] = useState(0);
  const [sets, setSets] = useState(0);
  const [success, setSuccess] = useState(false);
  const [painPoint, setPainPoint] = useState("lowerBack")
  const [search, setSearch] = useState('')
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  return (
    <div className=" bg-opacity-50 rounded-md">
      <div >
        {showVideo && (
          <PlayVideo
            setShowModal={setShowVideo}
            showModal={showVideo}
            videoUrl={exercises.filter((e)=>e.painPoints.includes(painPoint) && e.name.trim().toLowerCase().includes(search.trim().toLowerCase()))[selectedEx].videoUrl}
          />
        )}
        <FlexBetween className='mb-8'>

          <div className='flex flex-col md:flex-row gap-4 items-center'>

            <FlexBetween
              backgroundColor={theme.palette.background.alt}
              borderRadius="9px"
              gap="2px"

              mt={2}
              p="4px"
            >
               <Search color="action" />
              <InputBase onChange={(e) => { setSearch(e.target.value) }} placeholder="Search by name..." />
             
            </FlexBetween>
            <InputLabel sx={{ marginLeft: "8px" }}>Pain Point</InputLabel>
            <Select
              sx={{ marginLeft: "8px"}}
              value={painPoint}
              label="Pain Point"
              onChange={(e) => setPainPoint(e.target.value)}
              className='mt-0 md:mt-4'
            >
              <MenuItem value={"lowerBack"}>Lowerback</MenuItem>
              <MenuItem value={"upperBack"}>Upperback</MenuItem>
              <MenuItem value={"shoulder"}>Shoulder</MenuItem>
              <MenuItem value={"knee"}>Knee</MenuItem>
              <MenuItem value={"hip"}>Hip</MenuItem>
              <MenuItem value={"neck"}>Neck</MenuItem>
              <MenuItem value={"ankle"}>Ankle</MenuItem>
              <MenuItem value={"elbow"}>Elbow</MenuItem>
              <MenuItem value={"wristAndHand"}>Wrist and Hand</MenuItem>
            </Select>
          </div>
        </FlexBetween>
        <div
          className="grid grid-cols-1 md:grid-cols-4 gap-4 p-4 rounded-md"
          style={{ backgroundColor: isDarkMode ? "#08143d" : "#f3f3f3" }}
        >
          {exercises.filter((e)=>e.painPoints.includes(painPoint) && e.name.trim().toLowerCase().includes(search.trim().toLowerCase())).map((exercise, exerciseIndex) => (
            <div
              key={exerciseIndex}
              className="text-[#ffe3a3] rounded-md p-4"
              style={{ backgroundColor: isDarkMode ? "#21295c" : "#453407" }}
            >
              <label
                className="block font-bold mb-2"
                htmlFor={`exerciseName${exerciseIndex}`}
              >
                {exercise.aiConfigStatus ? "AI Enabled" : ""}
              </label>
              <div
                className="mb-4"
                style={{ backgroundColor: isDarkMode ? "#21295c" : "#453407" }}
              >
                <img
                  onClick={() => {
                    setSelectedEx(exerciseIndex);
                    setShowVideo(true);
                  }}
                  src={exercise.thumbnailUrl}
                  className="h-56 cursor-pointer"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block font-bold mb-2"
                  htmlFor={`exerciseName${exerciseIndex}`}
                >
                  {exercise.name}
                </label>
              </div>
              <div className="mb-4">
                <label
                  className="block font-bold mb-2"
                  htmlFor={`exerciseName${exerciseIndex}`}
                >
                  {exercise.painPoints.join(", ")}
                </label>
              </div>
              <div className="grid grid-cols-2 gap-4 mb-4">
                {/* Input fields for reps and sets */}
              </div>
              {/* <button className="bg-gray-300 bg-opacity-40 mt-2 rounded w-full p-2">
                Update Exercise
              </button> */}
              {success && selectedEx === exerciseIndex && (
                <div className="w-full p-4 mt-2 rounded-lg bg-green-200 text-green-600 text-center">
                  Exercise Added Successfully!
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ExerciseView;
