import React, { useMemo, useState } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  DownloadOutlined,
  Email,
  PointOfSale,
  PersonAdd,
  Traffic,
  Group,
  CalendarMonth,
  Subscriptions,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Select,
  MenuItem,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import BreakdownChart from "components/BreakdownChart";
import OverviewChart from "components/OverviewChart";
import { ResponsiveLine } from "@nivo/line";
// import { useGetDashboardQuery } from "state/api";
import StatBox from "components/StatBox";
import { dataOverallStat } from "utils/data";

import { useGetCustomersQuery, useGetDashboardStatusQuery } from "state/api";
import moment from "moment";
import { useOutletContext } from "react-router-dom";
import PatientTable from "components/PatientTable";

const Dashboard = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const dataX = dataOverallStat[0];
  const dataM = dataX.monthlyData[dataX.monthlyData.length - 1].totalSales;

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");
  const [userData] = useOutletContext();
  const [filterDays, setFilterDays] = useState("3months");
  // console.log("userData", userData)
  const dashboardStats = useGetDashboardStatusQuery(filterDays);

  const patients = useGetCustomersQuery(userData.userData[0].userReferralCode);

  const [totalSalesLine, totalSubscriptions] = useMemo(() => {
    if (!dashboardStats.data) return [];

    const monthlyData = dashboardStats.data.accountGraphData;
    const subscriptionData = dashboardStats.data.subscriptionGraphData;

    const totalSalesLine = {
      id: "Total Patients",
      color: theme.palette.secondary.main,
      data: [],
    };
    const totalSubscriptions = {
      id: "Subscriptions",
      color: theme.palette.secondary.main,
      data: [],
    };

    Object.values(subscriptionData).reduce(
      (acc, { x, y }) => {
        totalSubscriptions.data = [...totalSubscriptions.data, { x: x, y: y }];

        return { sales: y };
      },
      { sales: 0 }
    );
    Object.values(monthlyData).reduce(
      (acc, { x, y }) => {
        totalSalesLine.data = [...totalSalesLine.data, { x: x, y: y }];

        return { sales: y };
      },
      { sales: 0 }
    );

    return [[totalSalesLine], [totalSubscriptions]];
  }, [dashboardStats.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      field: "systemsub_id",
      headerName: "Index",
      flex: 0.5,
    },
    {
      field: "user",
      headerName: "User Name",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "CreatedAt",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Subscription Status",
      flex: 1,
    },
    {
      field: "razorpayOrderObject.amount",
      headerName: "Amount",
      flex: 1,
    },
    {
      field: "plan_id",
      headerName: "Plan Name",
      flex: 1,
    },
  ];
  const rows = [];

  return (
    <Box m='1.5rem 2.5rem'>
      <FlexBetween>
        <Header title='DASHBOARD' subtitle='Welcome to your dashboard' />

        <Box>
          <Select
            value={filterDays}
            onChange={(e) => {
              setFilterDays(e.target.value);
            }}
            color='secondary'>
            <MenuItem value={"1month"}>1 Month</MenuItem>
            <MenuItem value={"3months"}>3 Months</MenuItem>
            <MenuItem value={"6months"}>6 Months</MenuItem>
            {/* <MenuItem value={"lifetime"}>Lifetime</MenuItem> */}
          </Select>
        </Box>
      </FlexBetween>

      <Box
        mt='20px'
        display='grid'
        gridTemplateColumns='repeat(12, 1fr)'
        gridAutoRows='160px'
        gap='20px'
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}>
        {/* ROW 1 */}
        {dashboardStats.data && (
          <>
            <StatBox
              title='Total Patients'
              value={dashboardStats.data.accounts}
              increase={
                dashboardStats.data.accountGrowthPercentage > 0
                  ? "+" + dashboardStats.data.accountGrowthPercentage + "%"
                  : dashboardStats.data.accountGrowthPercentage + "%"
              }
              description='Since last month'
              icon={
                <Group
                  sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                />
              }
            />
            {/* <StatBox
              title="Active Patients"
              value={dashboardStats.data.totalActiveUsers}
              increase={dashboardStats.data.activeUsersGrow > 0 ? "+" + dashboardStats.data.activeUsersGrow + "%" :  dashboardStats.data.activeUsersGrow + "%"}

              description="Since last month"
              icon={
                <PersonAdd
                  sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                />
              }
            /> */}
          </>
        )}
        {dashboardStats.data && (
          <>
            <StatBox
              title='Subscriptions'
              value={dashboardStats.data.subscriptions}
              increase={
                dashboardStats.data.subscriptionGrowthPercentage > 0
                  ? "+" + dashboardStats.data.subscriptionGrowthPercentage + "%"
                  : dashboardStats.data.subscriptionGrowthPercentage + "%"
              }
              description='Since last month'
              icon={
                <Subscriptions
                  sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                />
              }
            />
            <StatBox
              title='Sessions'
              value={dashboardStats.data.feedbacks}
              increase={
                dashboardStats.data.feedbackGrowthPercentage > 0
                  ? "+" + dashboardStats.data.feedbackGrowthPercentage + "%"
                  : dashboardStats.data.feedbackGrowthPercentage + "%"
              }
              description='Since last month'
              icon={
                <CalendarMonth
                  sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                />
              }
            />
          </>
        )}
        <Box
          gridColumn='span 6'
          gridRow='span 2'
          backgroundColor={theme.palette.background.alt}
          p='1rem'
          borderRadius='0.55rem'>
          {dashboardStats.data && (
            <ResponsiveLine
              data={totalSalesLine}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: theme.palette.secondary[200],
                    },
                  },
                  legend: {
                    text: {
                      fill: theme.palette.secondary[200],
                    },
                  },
                  ticks: {
                    line: {
                      stroke: theme.palette.secondary[200],
                      strokeWidth: 1,
                    },
                    text: {
                      fill: theme.palette.secondary[200],
                    },
                  },
                },
                legends: {
                  text: {
                    fill: theme.palette.secondary[200],
                  },
                },
                tooltip: {
                  container: {
                    color: theme.palette.primary.main,
                  },
                },
              }}
              margin={{ top: 20, right: 50, bottom: 50, left: 70 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: false,
                reverse: false,
              }}
              yFormat=' >-.2f'
              curve='catmullRom'
              enableArea={true}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                format: (v) => {
                  if (filterDays === "1month") {
                    return moment(v).format("DD");
                  } else {
                    return moment(v).format("DD MMMM YY");
                  }
                },
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Date",
                legendOffset: 36,
                legendPosition: "middle",
              }}
              axisLeft={{
                orient: "left",
                tickValues: 5,
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: `Total No. of Patients`,
                legendOffset: -60,
                legendPosition: "middle",
              }}
              enableGridX={false}
              enableGridY={false}
              pointSize={10}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
              legends={[
                {
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 30,
                  translateY: -40,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          )}
        </Box>

        {dashboardStats.data && (
          <Box
            gridColumn='span 6'
            gridRow='span 2'
            backgroundColor={theme.palette.background.alt}
            p='1rem'
            borderRadius='0.55rem'>
            {dashboardStats.data && (
              <ResponsiveLine
                data={totalSubscriptions}
                theme={{
                  axis: {
                    domain: {
                      line: {
                        stroke: theme.palette.secondary[200],
                      },
                    },
                    legend: {
                      text: {
                        fill: theme.palette.secondary[200],
                      },
                    },
                    ticks: {
                      line: {
                        stroke: theme.palette.secondary[200],
                        strokeWidth: 1,
                      },
                      text: {
                        fill: theme.palette.secondary[200],
                      },
                    },
                  },
                  legends: {
                    text: {
                      fill: theme.palette.secondary[200],
                    },
                  },
                  tooltip: {
                    container: {
                      color: theme.palette.primary.main,
                    },
                  },
                }}
                margin={{ top: 20, right: 50, bottom: 50, left: 70 }}
                xScale={{ type: "point" }}
                yScale={{
                  type: "linear",
                  min: "auto",
                  max: "auto",
                  stacked: false,
                  reverse: false,
                }}
                yFormat=' >-.2f'
                curve='catmullRom'
                enableArea={true}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  format: (v) => {
                    if (filterDays === "1month") {
                      return moment(v).format("DD");
                    } else {
                      return moment(v).format("DD MMMM YY");
                    }
                  },
                  orient: "bottom",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Date",
                  legendOffset: 36,
                  legendPosition: "middle",
                }}
                axisLeft={{
                  orient: "left",
                  tickValues: 5,
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: `Total No. of Subscriptions`,
                  legendOffset: -60,
                  legendPosition: "middle",
                }}
                enableGridX={false}
                enableGridY={false}
                pointSize={10}
                pointColor={{ theme: "background" }}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[
                  {
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 30,
                    translateY: -40,
                    itemsSpacing: 0,
                    itemDirection: "left-to-right",
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: "circle",
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemBackground: "rgba(0, 0, 0, .03)",
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              />
            )}
          </Box>
        )}
        {/* ROW 2 */}
        <Box
          gridColumn='span 12'
          gridRow='span 3'
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              borderRadius: "5rem",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}>
          {patients.data && (
            <PatientTable shouldExpandRow={true} patients={patients.data} />
          )}
        </Box>
        {/* <Box
          gridColumn="span 4"
          gridRow="span 3"
          mt="1rem"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            Sales By Category
          </Typography>
          <BreakdownChart isDashboard={true} />
          <Typography
            p="0 0.6rem"
            fontSize="0.8rem"
            sx={{ color: theme.palette.secondary[200] }}
          >
            Breakdown of real states and information via category for revenue
            made for this year and total sales.
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Dashboard;
