import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "https://www.internal.casamed.org/api/v1/",
    // baseUrl: "http://localhost:9000/v1/",
  }),
  reducerPath: "adminApi",
  tagTypes: ["User", "Products", "Customers", "Transactions"],
  endpoints: (build) => {
    return {
      createPrescription: build.mutation({
        query: (prescription) => ({
          url: "client/services/prescriptions",
          method: "POST",
          body: prescription,
        }),
        providesTags: ["Prescription"],
      }),
      updatePrescription: build.mutation({
        query: (prescription) => ({
          url: `client/services/prescriptions/${prescription._id}`,
          method: "PUT",
          body: prescription,
        }),
        providesTags: ["UserCasesUpdate"],
      }),
      createAppointment: build.mutation({
        query: (appoointment) => ({
          url: "client/services/book-appointment",
          method: "POST",
          body: appoointment,
        }),
        providesTags: ["Appointment"],
      }),
      createAccount: build.mutation({
        query: (accountData) => ({
          url: "client/services/accounts",
          method: "POST",
          body: accountData,
        }),
        providesTags: ["Accounts"],
      }),
      createProfile: build.mutation({
        query: (partnerData) => ({
          url: "client/services/partners",
          method: "POST",
          body: partnerData,
        }),
        providesTags: ["Partners"],
      }),
      createSubscription: build.mutation({
        query: (subscription) => ({
          url: "client/services/subscriptions",
          method: "POST",
          body: subscription,
        }),
        providesTags: ["Subscription"],
      }),
      updateSubscription: build.mutation({
        query: (subscription) => ({
          url: `client/services/subscriptions/${subscription._id}`,
          method: "PUT",
          body: subscription,
        }),
        providesTags: ["UpdateSubscription"],
      }),
      updateOrder: build.mutation({
        query: (order) => ({
          url: `general/order/${order.id}`,
          method: "PUT",
          body: order,
        }),
        providesTags: ["UpdateOrder"],
      }),
      createPlan: build.mutation({
        query: (planData) => ({
          url: "client/services/plans",
          method: "POST",
          body: planData,
        }),
        providesTags: ["CreatePlan"],
      }),
      createSendLink: build.mutation({
        query: (body) => ({
          url: "client/services/send-sms",
          method: "POST",
          body: body,
        }),
        providesTags: ["CreateSms"],
      }),
      updateFeedback: build.mutation({
        query: (feedbackData) => ({
          url: `client/services/physio-session/${feedbackData.id}`,
          method: "PUT",
          body: feedbackData,
        }),
        providesTags: ["Partners"],
      }),
      updatePartnerProfile: build.mutation({
        query: (profile) => ({
          url: `general/partner/${profile._id}`,
          method: "PUT",
          body: profile,
        }),
        providesTags: ["PartnersUpdate"],
      }),
      addPhyioSessionRecord: build.mutation({
        query: (feedbackData) => ({
          url: `client/services/physio-session`,
          method: "POST",
          body: feedbackData,
        }),
        providesTags: ["AddPhysioSession"],
      }),
      updateCustomPlan: build.mutation({
        query: (planData) => ({
          url: `client/services/plans/${planData.id}`,
          method: "PUT",
          body: planData,
        }),
        providesTags: ["PlanUpdate"],
      }),
      updateUserProfile: build.mutation({
        query: (userData) => ({
          url: `client/services/users/${userData.id}`,
          method: "PUT",
          body: userData,
        }),
        providesTags: ["UserProfileUpdate"],
      }),
      updatePhysioSession: build.mutation({
        query: (physioSession) => ({
          url: `client/services/physio-session/${physioSession.id}`,
          method: "PUT",
          body: physioSession,
        }),
        providesTags: ["PhysioSessionUpdate"],
      }),
      updateAppointment: build.mutation({
        query: (appointment) => ({
          url: `client/services/appointments/${appointment.id}`,
          method: "PUT",
          body: appointment,
        }),
        providesTags: ["AppointmentUpdate"],
      }),
      createUserProfile: build.mutation({
        query: (userData) => ({
          url: `client/services/users`,
          method: "POST",
          body: userData,
        }),
        providesTags: ["PlanUpdate"],
      }),
      sendJoinRequest: build.mutation({
        query: (message) => ({
          url: `client/services/request-patient-join`,
          method: "POST",
          body: message,
        }),
        providesTags: ["PlanUpdate"],
      }),
      addMedicalRecordMutation: build.mutation({
        query: (record) => ({
          url: `client/services/medical-records`,
          method: "POST",
          body: record,
        }),
        providesTags: ["MedicalRecord"],
      }),
      getUserPhysioSessions: build.query({
        query: (user) => `client/services/physio-session?user=${user}`,
        providesTags: ["Physio Session"],
      }),
      getUser: build.query({
        query: (id) => `general/users`,
        providesTags: ["User"],
      }),
      getProducts: build.query({
        query: (days) => `client/services/products?days=${days}`,
        providesTags: ["Products"],
      }),
      getCustomers: build.query({
        query: (referralCode) => `general/patients?role=Client`,
        providesTags: ["Customers"],
      }),
      getPhysio: build.query({
        query: () => `general/patients?role=Physio`,
        providesTags: ["Physios"],
      }),
      getDoctors: build.query({
        query: () => `general/patients?role=Doctor`,
        providesTags: ["Doctors"],
      }),
      getTransactions: build.query({
        query: ({ page, pageSize, sort, search, referralCode }) => ({
          url: `client/services/orders`,
          method: "GET",
          // params: { page, pageSize, sort, search, referralCode: referralCode._id },
        }),
        providesTags: ["Transactions"],
      }),
      getAppointments: build.query({
        query: (referralCode) => `general/appointments`,
        providesTags: ["Appointments"],
      }),
      getVideoCallToken: build.query({
        query: (appointmentId, isPublisher) => ({
          url: "client/services/appointments-generate-token",
          method: "POST",
          body: { appointmentId, isPublisher },
        }),
        providesTags: ["VideoCallToken"],
      }),
      getUserFeedbacks: build.query({
        query: () => `general/feedbacks`,
        providesTags: ["Feedbacks"],
      }),
      getAccount: build.query({
        query: (user) => `client/services/accounts?mobile=${user}`,
        providesTags: ["Accounts"],
      }),
      getUserProfile: build.query({
        query: (accountId) => `client/services/users?accountId=${accountId}`,
        providesTags: ["UserProfile"],
      }),
      getUserSubscriptions: build.query({
        query: (query) => `general/subscriptions`,
        providesTags: ["Subscription"],
      }),
      getPlans: build.query({
        query: (custom) => `client/services/plans?custom=${custom}`,
        providesTags: ["Plans"],
      }),
      getExercises: build.query({
        query: () => `client/services/exercises`,
        providesTags: ["Exercises"],
      }),
      getPlanDetails: build.query({
        query: (id) => `client/services/plans/${id}`,
        providesTags: ["Plan Details"],
      }),
      getDashboardStatus: build.query({
        query: (filter) => `general/dashboard-stats/${filter}`,
        providesTags: ["DashboardStats"],
      }),
      getPartnerUserSubscriptions: build.query({
        query: (referralCode) => `general/subscriptions`,
        providesTags: ["PartnerUserSubscriptions"],
      }),
      getMedicalRecords: build.query({
        query: (user) => `general/medical-records`,
        providesTags: ["MedicalRecords"],
      }),
      getMedicalRecordsUser: build.query({
        query: (user) => `client/services/medical-records?user=${user}`,
        providesTags: ["MedicalRecordsUser"],
      }),
      getMedicalRecord: build.query({
        query: (id) => `client/services/medical-records/${id}`,
        providesTags: ["MedicalRecord"],
      }),
      getAppShareLink: build.query({
        query: () => `client/services/share-app-link`,
        providesTags: ["ShareLink"],
      }),
      getActiveSubscription: build.query({
        query: (user) =>
          `client/services/tier-subscriptions?user=${user}&status=active`,
        providesTags: ["Tiers"],
      }),
      getInventory: build.query({
        query: (id) => `general/inventories`,
        providesTags: ["Inventory"],
      }),
      getPricing: build.query({
        query: (id) => `general/pricings`,
        providesTags: ["Pricing"],
      }),
      getScenarioMaps: build.query({
        query: () => `general/scenario-maps`,
        providesTags: ["Scenario-Maps"],
      }),
      getQuestions: build.query({
        query: () => `general/questions`,
        providesTags: ["Question"],
      }),
      getFoodItems: build.query({
        query: () => `general/food-items`,
        providesTags: ["FoodItems"],
      }),
      getCoupons: build.query({
        query: () => `general/coupons`,
        providesTags: ["coupons"],
      }),
      getDiscounts: build.query({
        query: () => `general/discounts`,
        providesTags: ["discounts"],
      }),
      getOrders: build.query({
        query: () => `general/orders`,
        providesTags: ["orders"],
      }),
      getPartners: build.query({
        query: () => `general/partners`,
        providesTags: ["Partners"],
      }),
      getAccounts: build.query({
        query: () => `general/accounts`,
        providesTags: ["Accounts"],
      }),
      getUserSpecificSubscriptions: build.query({
        query: (query) => `client/services/subscriptions?${query}`,
        providesTags: ["Subscription"],
      }),
      getUserSpecificFeedbacks: build.query({
        query: (user) => `client/services/feedbacks?user=${user}`,
        providesTags: ["Feedbacks"],
      }),
      getReferrals: build.query({
        query: () => `general/referrals`,
        providesTags: ["Referrals"],
      }),
      getAllPartners: build.query({
        query: () => `general/all-partners`,
        providesTags: ["All Partners"],
      }),
      getPartner: build.query({
        query: (account) => `general/partner?account=${account}`,
        providesTags: ["Partner"],
      }),
      getPrescriptions: build.query({
        query: (partner) => `client/services/prescriptions?partner=${partner}`,
        providesTags: ["Prescriptions"],
      }),
    };
  },
});

export const {
  useUpdateOrderMutation,
  useCreatePrescriptionMutation,
  useUpdatePrescriptionMutation,
  useGetPrescriptionsQuery,
  useUpdatePartnerProfileMutation,
  useGetPartnerQuery,
  useGetAllPartnersQuery,
  useGetDoctorsQuery,
  useGetPhysioQuery,
  useUpdateSubscriptionMutation,
  useGetUserSpecificFeedbacksQuery,
  useGetUserSpecificSubscriptionsQuery,
  useGetMedicalRecordsUserQuery,
  useCreateAppointmentMutation,
  useGetAccountsQuery,
  useGetPartnersQuery,
  useGetOrdersQuery,
  useGetDiscountsQuery,
  useGetCouponsQuery,
  useGetUserQuery,
  useGetProductsQuery,
  useGetCustomersQuery,
  useGetTransactionsQuery,
  useGetAppointmentsQuery,
  useGetVideoCallTokenQuery,
  useGetUserFeedbacksQuery,
  useCreateAccountMutation,
  useGetAccountQuery,
  useGetUserProfileQuery,
  useCreateProfileMutation,
  useUpdateFeedbackMutation,
  useCreateSubscriptionMutation,
  useGetUserSubscriptionsQuery,
  useGetPlansQuery,
  useGetExercisesQuery,
  useCreatePlanMutation,
  useGetPlanDetailsQuery,
  useUpdateCustomPlanMutation,
  useGetDashboardStatusQuery,
  useGetPartnerUserSubscriptionsQuery,
  useCreateUserProfileMutation,
  useUpdateUserProfileMutation,
  useGetMedicalRecordsQuery,
  useGetMedicalRecordQuery,
  useAddMedicalRecordMutationMutation,
  useSendJoinRequestMutation,
  useGetAppShareLinkQuery,
  useCreateSendLinkMutation,
  useGetUserPhysioSessionsQuery,
  useAddPhyioSessionRecordMutation,
  useUpdatePhysioSessionMutation,
  useUpdateAppointmentMutation,
  useGetInventoryQuery,
  useGetPricingQuery,
  useGetScenarioMapsQuery,
  useGetQuestionsQuery,
  useGetFoodItemsQuery,
  useGetReferralsQuery,
} = api;
