import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { auth, storage } from "firebaseConfig";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreateProfileMutation, useGetUserQuery } from "state/api";

const EstablishmentCategoryOptions = [
    "Multi-Speciality Hospital",
    "Single Speciality Hospital",
    "Clinic with OT",
    " OPD Clinic"
];

const Profile = () => {
    const query = useLocation().search;
    const name = new URLSearchParams(query).get("partner");
    const [licenseDetails, setLicenseDetails] = useState();
    const [photo, setPhoto] = useState();
    const [experienceDetails, setExperienceDetails] = useState();
    const [degree, setDegree] = useState("");
    const [specialization, setSpecialization] = useState();
    const [establishmentCategory, setEstablishmentCategory] = useState();
    const [error, setError] = useState(false)
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [createPartner, response] = useCreateProfileMutation();
    const user = localStorage.getItem("user")
    const { data } = useGetUserQuery(user);
    const navigate = useNavigate()
    console.log(user)
    if (data) {
        navigate('/')
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!licenseDetails || !photo || !experienceDetails || !degree || !specialization || !establishmentCategory) {
            setError(true)
            setMessage('Please enter all the fields!')
        } else {
            setError(false)
            setLoading(true)
            setMessage()
            const storageRef = ref(storage, `/Doctors/${name}`)
            const uploadTask = uploadBytesResumable(storageRef, photo);
           
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        const partner = { name: name, mobile: auth.currentUser?.phoneNumber,  photoUrl: url, licenseNumber:licenseDetails, yearsOfExperience: experienceDetails, specialization: [specialization], degree:[degree], description:establishmentCategory, status:"active", account: user  }
                        createPartner(partner).unwrap().then((data) => {
                            setLoading(false)
                            
                            window.location.replace('/')
    
                        }).catch((err) => {
                            console.log("err", err)
                            setError(true)
                            setLoading(false)
                            setMessage(err.message)
                        })
                    });
                }
            );

        }

    };

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            flexDirection: 'column',


        }}>
            <h4 className="text-2xl text-white mb-5">Create Profile</h4>
            <form onSubmit={handleSubmit} className="mb-4 w-2/4 mx-auto text-black bg-white rounded-md p-5">
                <div className="mb-4 ">
                    <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
                        Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-input w-full border-gray-700 border rounded-md shadow-sm p-3"
                        disabled
                        value={name}
                    />
                </div>

                <div className="mb-4">
                    <label
                        htmlFor="licenseDetails"
                        className="block text-gray-700 font-bold mb-2"
                    >
                        License Details
                    </label>
                    <textarea
                        id="licenseDetails"
                        name="licenseDetails"
                        rows="1"
                        className="form-input w-full border-gray-700 border p-2 rounded-md shadow-sm"

                        onChange={(e) => setLicenseDetails(e.target.value)}
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="photo" className="block text-gray-700 font-bold mb-2">
                        Photo
                    </label>
                    <input
                        type="file"
                        id="photo"
                        name="photo"
                        className="form-input w-full rounded-md shadow-sm"
                        accept="image/*"
                        onChange={(e) => setPhoto(e.target.files[0])}
                    />
                </div>

                <div className="mb-4">
                    <label
                        htmlFor="experienceDetails"
                        className="block text-gray-700 font-bold mb-2"
                    >
                        Experience Details
                    </label>
                    <input
                        id="experienceDetails"
                        name="experienceDetails"
                        min={0}
                        className="form-input w-full border-gray-700 border p-2  rounded-md shadow-sm"
                        type="number"
                        onChange={(e) => setExperienceDetails(e.target.value)}
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="degree" className="block text-gray-700 font-bold mb-2">
                        Degree/Qualifications
                    </label>
                    <input
                        type="text"
                        id="degree"
                        name="degree"
                        className="form-input w-full border-gray-700 border  rounded-md shadow-sm p-3"

                        onChange={(e) => setDegree(e.target.value)}
                    />
                </div>

                <div className="mb-4">
                    <label
                        htmlFor="specialization"
                        className="block text-gray-700 font-bold mb-2"
                    >
                        Specialization
                    </label>
                    <input
                        type="text"
                        id="specialization"
                        name="specialization"
                        className="form-input w-full border-gray-700 border  rounded-md shadow-sm p-3"

                        onChange={(e) => setSpecialization(e.target.value)}
                    />
                </div>

                <div className="mb-4">
                    <label
                        htmlFor="establishmentCategory"
                        className="block text-gray-700 font-bold mb-2"
                    >
                        Establishment Category
                    </label>
                    <select
                        id="establishmentCategory"
                        name="establishmentCategory"
                        className="form-select w-full border-gray-700 border  rounded-md shadow-sm"

                        onChange={(e) => setEstablishmentCategory(e.target.value)}
                    >
                        <option value="">-- Select an option --</option>
                        {EstablishmentCategoryOptions.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
                {
                    error && <div className="mb-4 bg-red-200 p-2 text-center rounded-md mt-4">
                        <label
                            htmlFor="specialization"
                            className="block text-red-700 font-bold "
                        >
                            {message}
                        </label>

                    </div>
                }
                <div className="mt-6 w-full align-middle center text-center flex justify-center">
                    {!loading && <button
                        type="submit"
                        className="w-2/4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Submit
                    </button>}
                    {
                        loading && <CircularProgress />
                    }
                </div>
            </form>
        </Box>
    );
};

export default Profile