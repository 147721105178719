import { CircularProgress, CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "theme";
import { useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Dashboard from "scenes/dashboard";
import Layout from "scenes/layout";
import Products from "scenes/products";
import Customers from "scenes/customers";
import Geography from "scenes/geography";
import Overview from "scenes/overview";
import Daily from "scenes/daily";
import Monthly from "scenes/monthly";
import Breakdown from "scenes/breakdown";
import Appointments from "scenes/appointments";
import VideoCall from "scenes/videoCall";
import CustomerDetails from "scenes/feedbacks";
import Loginnew from "scenes/signin";
import LoginPage from "scenes/signin";

import { auth, requestForToken } from "firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { Box } from "@mui/system";
import Patient from "scenes/patient";
import Profile from "scenes/profile";
import AssignPlan from "scenes/patient/assign-plan";
import Tiers from "scenes/tiers";
import CreateCustomPlan from "scenes/custom/create";
import ViewCustomPlan from "scenes/custom/details";
import Subscriptions from "scenes/subscriptions";
import AddPatient from "scenes/patient/add";
import PatientProfile from "scenes/patient/profile";
import MedicalRecord from "scenes/patient/medical-record";
import AddRecord from "scenes/patient/add-record";
import UpdateStat from "scenes/patient/update-stats";
import MergePlan from "scenes/custom/merge";
import Planssec from "scenes/plans";
import ViewExercise from "scenes/exercises";
import Orders from "scenes/orders";
import Coupons from "scenes/coupons";
import Users from "scenes/users";
import Inventory from "scenes/inventory";
import Pricing from "scenes/pricing";
import Diagnostics from "scenes/diagnostics";
import Doctors from "scenes/doctors";
import Referrals from "scenes/referrals";
import Accounts from "scenes/accounts";
import Feedbacks from "scenes/feedback";
import FoodItems from "scenes/fooditems";
import ScenarioMaps from "scenes/scenariomaps";
import Questions from "scenes/questions";
import Discounts from "scenes/discounts";
import Partners from "scenes/partners";
import { MedicalPage } from "components/MedicalPage";
import DoctorsScreen from "scenes/doctors";
import PhysiosScreen from "scenes/physios";
import PartnerProfile from "scenes/partnerprofile";
import Prescription from "scenes/prescription";
import PrescriptionFormComponent from "scenes/prescription/PrescriptionForm";
import PrescriptionDetail from "scenes/prescription/details";
import Report from "scenes/patient/report";

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return (
      <div className='app_loader'>
        <div className='loader'></div>
      </div>
    );
  }
  if (user) {
    // window.location.replace('/')
  }

  return user ? (
    <div className='app'>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route element={<Layout />}>
              <Route path='/' element={<Navigate to='/dashboard' replace />} />
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/appointments' element={<Appointments />} />
              <Route path='/doctors' element={<DoctorsScreen />} />
              <Route path='/physios' element={<PhysiosScreen />} />
              <Route path='/products' element={<Products />} />
              <Route path='/patients' element={<Customers />} />
              {/* <Route path='/transactions' element={<Transactions />} /> */}
              <Route path='/geography' element={<Geography />} />
              <Route path='/overview' element={<Overview />} />
              <Route path='/daily' element={<Daily />} />
              <Route path='/monthly' element={<Monthly />} />
              <Route path='/breakdown' element={<Breakdown />} />
              <Route
                path='/appointment/medicalrecord'
                element={<MedicalPage />}
              />
              <Route path='/video-call' element={<VideoCall />} />
              {/* <Route path="/feedbacks" element={<CustomerDetails />} /> */}
              <Route path='/subscriptions' element={<Subscriptions />} />
              <Route path='/patient/assign-plan' element={<AssignPlan />} />
              <Route path='/patient' element={<Patient />} />
              <Route path='/patient/add' element={<AddPatient />} />
              <Route
                path='/patient/medical-record'
                element={<MedicalRecord />}
              />
              <Route path='/patient/profile' element={<PatientProfile />} />
              <Route path='/patient/add-record' element={<AddRecord />} />
              <Route path='/patient/update-stats' element={<UpdateStat />} />
              <Route path='/patient/d' element={<UpdateStat />} />
              <Route path='/plan' element={<Planssec />} />
              <Route path='/exercise' element={<ViewExercise />} />
              <Route path='/order' element={<Orders />} />
              <Route path='/coupon' element={<Coupons />} />
              <Route path='/user' element={<Users />} />
              <Route path='/inventory' element={<Inventory />} />
              <Route path='/pricing' element={<Pricing />} />
              <Route path='/diagnostic' element={<Diagnostics />} />
              <Route path='/referral' element={<Referrals />} />
              <Route path='/account' element={<Accounts />} />
              <Route path='/feedback' element={<Feedbacks />} />
              <Route path='/food item' element={<FoodItems />} />
              <Route path='/scenario map' element={<ScenarioMaps />} />
              <Route path='/question' element={<Questions />} />
              <Route path='/discount' element={<Discounts />} />
              <Route path='/partner' element={<Partners />} />
              <Route path='/partner/profile' element={<PartnerProfile />} />
              <Route path='/prescription' element={<Prescription />} />
              <Route
                path='/prescription/form'
                element={<PrescriptionFormComponent />}
              />
              <Route
                path='/prescription/detail'
                element={<PrescriptionDetail />}
              />
              <Route path='/report' element={<Report />} />
              {/* <Route path="/doctors/profile" element={<PartnerProfile/>} />  */}
            </Route>
            <Route path='/custom/create' element={<CreateCustomPlan />} />
            <Route path='/custom/details' element={<ViewCustomPlan />} />
            <Route path='/custom/merge' element={<MergePlan />} />
            <Route path='/tier-purchase' element={<Tiers />} />
            <Route path='/profile' element={<Profile />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  ) : (
    <div className='app'>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path='/' element={<Navigate to='/signin' replace />} />
            <Route path='/signin' element={<LoginPage />} />
            {/* <Route path="/signup" element={<SignupPage />} /> */}
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
