import React, { useState, useRef,useEffect } from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  Typography,
  TableFooter,
} from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  useGetAllPartnersQuery,
  useGetMedicalRecordsQuery,
  useGetVideoCallTokenQuery,
  useSendJoinRequestMutation,
  useUpdateAppointmentMutation,
} from "state/api";
import { useTheme } from "@mui/material/styles";
import { tokensDark, tokensLight } from "theme";
import { toast } from "react-toastify";
import {
  Search,
  GetApp,
  Print,
  ViewColumn,
  FilterList,
  Clear,
  UpdateRounded,
} from "@mui/icons-material";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ArrowUpward } from "@mui/icons-material";
import { ArrowDownward } from "@mui/icons-material";

function encodeIdToNum(id) {
  // Convert the string representation of MongoDB ID to hexadecimal
  const hex = id.replace(/-/g, "");
  // Convert the hexadecimal to a decimal number
  const decimal = parseInt(hex, 16);
  // Take the modulo of the decimal number with a large prime number to reduce the output size
  const modulo = decimal % 15485867;
  // Convert the modulo back to a string and pad with leading zeros to make it a 5 digit number
  const num = modulo.toString().padStart(5, "0");
  return num.toString();
}

const AppointmentTable = ({ appointments }) => {
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [sendRequest, response] = useSendJoinRequestMutation();
  const [userData] = useOutletContext();
  const navigate = useNavigate();
  const [updateAppointment] = useUpdateAppointmentMutation();
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortSchedule, setSortSchedule] = useState("asc");
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const tableRef = useRef(null);

  const [filterCriteria, setFilterCriteria] = useState({
    name: "",
    date: "",
    painPoint: "",
    phoneNumber: "",
    status: "",
  });
  const [filteredAppointments, setFilteredAppointments] =
    useState(appointments); // New state for filtered results
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleSortOrderChange = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
  };
  const handleSortScheduleChange = () => {
    const newSortOrder = sortSchedule === "asc" ? "desc" : "asc";
    setSortSchedule(newSortOrder);
  };
  const sortTableData = () => {
    const sortedData = [...appointments].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
     
    setFilteredAppointments(sortedData);
  };
  const sortTableOnSchedule = () => {
    const sortedData = [...appointments].sort((a, b) => {
      const dateA = new Date(a.schedule);
      const dateB = new Date(b.schedule);
      return sortSchedule === "asc" ? dateA - dateB : dateB - dateA;
    });
    
    setFilteredAppointments(sortedData);
  };

  useEffect(() => {
    sortTableData();
  }, [sortOrder]);
  useEffect(() => {
    sortTableOnSchedule();
  }, [sortSchedule]);

  const handleFilterCriteriaChange = (event, field) => {
    const { value } = event.target;
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      [field]: value,
    }));

    const filteredData = appointments.filter((appointment) => {
      const { user, schedule, painPoint, status } = appointment;

      const {
        name: nameFilter,
        date: scheduleFilter,
        painPoint: painPointFilter,
        phoneNumber: phoneNumberFilter,
        status: statusFilter,
      } = filterCriteria;

      const isNameMatch =
        !nameFilter ||
        (user &&
          user.name &&
          user.name.toLowerCase().includes(nameFilter.toLowerCase()));
      const isScheduleMatch =
        !scheduleFilter ||
        (schedule &&
          moment(schedule).utc(false).format("DD/MM/YYYY HH:mm").includes(scheduleFilter));
      const isPainPointMatch =
        !painPointFilter ||
        (painPoint &&
          painPoint.toLowerCase().includes(painPointFilter.toLowerCase()));
      const isPhoneNumberMatch =
        !phoneNumberFilter ||
        (user && user.mobile && user.mobile.includes(phoneNumberFilter));
      const isStatusMatch =
        !statusFilter ||
        (status &&
          getStatusText(status)
            .toLowerCase()
            .includes(statusFilter.toLowerCase()));

      return (
        isNameMatch &&
        isScheduleMatch &&
        isPainPointMatch &&
        isPhoneNumberMatch &&
        isStatusMatch
      );
    });

    setFilteredAppointments(filteredData);
    setCurrentPage(1);
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    const searchedData = appointments.filter((appointment) => {
      const { user, schedule, painPoint, status } = appointment;

      const isNameMatch =
        user &&
        user.name &&
        user.name.toLowerCase().includes(query.toLowerCase());
      const isScheduleMatch =
        schedule && moment(schedule).utc(false).format("DD/MM/YYYY HH:mm").includes(query);
      const isPainPointMatch =
        painPoint && painPoint.toLowerCase().includes(query.toLowerCase());
      const isPhoneNumberMatch =
        user && user.mobile && user.mobile.includes(query);
      const isStatusMatch =
        status &&
        getStatusText(status).toLowerCase().includes(query.toLowerCase());

      return (
        isNameMatch ||
        isScheduleMatch ||
        isPainPointMatch ||
        isPhoneNumberMatch ||
        isStatusMatch
      );
    });

    setFilteredAppointments(searchedData);
    setCurrentPage(1);
  };

  const handleDownload = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," + convertToCSV(filteredAppointments);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "appointments.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  };

  const convertToCSV = (data) => {
    const header = [
      "Patient Name",
      "Date",
      "Pain Point",
      "Phone Number",
      "Status",
    ];
    const rows = data.map((appointment) => [
      appointment.user.name,
      appointment.schedule,
      appointment.painPoint,
      `${appointment.user.mobile}`, // Treat phone number as a string
      appointment.status,
    ]);

    const csvData = [header, ...rows]
      .map((row) => row.map((value) => `"${value}"`).join(","))
      .join("\n");
    return csvData;
  };

  const handlePrint = () => {
    const content = tableRef.current.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setFilteredAppointments(appointments); // Reset filtered results to original data
  };

  const getStatusText = (status) => {
    switch (status) {
      case "active":
        return "Active";
      case "paid":
        return "Paid";
      case "created":
        return "Created";
      case "expired":
        return "Expired";
      case "cancelled":
        return "Cancelled";
      case "completed":
        return "Completed";
      default:
        return "";
    }
  };

  const handleResetFilterCriteria = () => {
    setFilterCriteria({
      name: "",
      schedule: "",
      painPoint: "",
      user: "",
      status: "",
    });
    setFilteredAppointments(appointments); // Reset filtered results to original data
    setCurrentPage(1);
  };

  const handleRowClick = (appointment) => {
    if (selectedAppointment === appointment) {
      setSelectedAppointment(null);
    } else {
      setSelectedAppointment(appointment);
    }
  };

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const handleMedicalClick = (appointment) => {};
  const handleCancelAppointment = (appointment) => {
    if (appointment.status === "cancelled") {
      toast.error("Already cancelled!");
    } else {
      updateAppointment({
        id: appointment._id,
        status: "cancelled",
        cancelledBy: userData.userData[0]._id,
        cancelledByType: userData.userData[0].role,
        cancelled: true,
      })
        .unwrap()
        .then((d) => {
          setSelectedAppointment(d);
          toast.success("Appointment Cancelled Successfully!");
        })
        .catch((e) => {
          toast.error(e.message);
        });
    }
  };
  const SelectPartner = ({appointment}) => {
    const [selectedPartner, setSelectedPartnerDetail] = useState();
    const [editAccess, setEditAccess] = useState(true);
    const [fullAccess, setFullAccess] = useState(true);
    const [createTransferOwnership] = useUpdateAppointmentMutation()
    const {data} = useGetAllPartnersQuery()
    const [searchTerm, setSearchTerm] = useState('');
    const handleCreateUserCase = () => {
      const userCase = { id: appointment._id, partner: selectedPartner.account ? selectedPartner.account._id : selectedPartner.user._id}
        createTransferOwnership(userCase).unwrap().then((d)=>{
          alert("Partner Assigned Successfully!")
          setShowPartners(false)
        }).catch(e=>{
          alert("Something went wrong while transferring the case")
        })
    }
    const handlePartnerSearch = (e) => {
      const ptr = data
      .filter((option) =>
       option.account ? option.account.name.trim().toLowerCase().includes(e.target.value.trim().toLowerCase()) : option.name.trim().toLowerCase().includes(e.target.value.trim().toLowerCase())
      )
    
      setSelectedPartnerDetail(ptr[0])
      setSearchTerm(e.target.value);
    };
    return (
      <div className="fixed  inset-0 z-50 flex items-center mt-16 md:mt-0 justify-center bg-black bg-opacity-50">
        <div className="bg-white overflow-y-auto overflow-auto  rounded-t-lg md:rounded-lg p-6">
          <h2 className="text-lg text-gray-700 font-semibold mb-4">Select the Partner to Transfer</h2>
          <input
        type="text"
        placeholder="Search"
        className="w-full border rounded-lg px-3 py-2 text-gray-700 mt-2 mb-2"
        
        onChange={handlePartnerSearch}
      />
      <select  defaultValue="choose" onChange={(e) => { 
            console.log(JSON.parse(e.target.value))
            setSelectedPartnerDetail(JSON.parse(e.target.value)) }} className='w-full border rounded-lg px-3 py-2 text-gray-700'>
        {data && data
          .filter((option) =>
           option.account ? option.account.name.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()) : option.name.trim().toLowerCase().includes(searchTerm.trim().toLowerCase())
          )
          .map((option) => (
            <option  key={option._id} value={JSON.stringify(option)}>
              {option.account ? option.account.name : option.user ? option.user.name : option.name}
            </option>
          ))}
      </select>
          {/* <select>
                       <option disabled value="choose">Choose</option>
           {
            data && data.map((p)=>{
              return <option key={p._id} value={JSON.stringify(p)}>{p.account ? p.account.name : p.name}</option>
            })
           }
          </select> */}
         {
         selectedPartner && <div className='mt-2'>
        { (selectedPartner.account || selectedPartner.user) && <div>   <h2 className="text-sm text-gray-500 ">Role</h2>
           <h2 className="text-sm text-gray-700 font-semibold mb-4">{selectedPartner.account ? selectedPartner.account.role : selectedPartner.user.role}</h2>
           </div>
           }
           <h2 className="text-sm text-gray-500 ">Degrees</h2>
           <h2 className="text-sm text-gray-700 font-semibold mb-4">{selectedPartner.degree && selectedPartner.degree.join(", ")}</h2>
           <h2 className="text-sm text-gray-500  ">Specialisations</h2>
           <h2 className="text-sm text-gray-700 font-semibold mb-4">{selectedPartner.specialization && selectedPartner.specialization.join(", ")}</h2>
           <h2 className="text-sm text-gray-500 ">Expertise</h2>
           <h2 className="text-sm text-gray-700 font-semibold mb-4">{selectedPartner.areaOfExpertise && selectedPartner.areaOfExpertise.join(", ")}</h2>

         </div>
         }

          <div className="flex gap-2 justify-end mt-4">
            <button
              onClick={() => { setShowPartners(false) }}
              className="bg-red-500 hover:bg-red-600 text-white rounded-lg px-4 py-2"
            >
              Cancel
            </button>
            <button
              onClick={() => {handleCreateUserCase() }}
              className="bg-blue-500 hover:bg-blue-600 text-white rounded-lg px-4 py-2"
            >
              Assign
            </button>

          </div>
        </div>
      </div>
    );
  };
  const DateTimePickerModal = ({ showSchedule, setShowSchedule, patient }) => {
    const [selectedDateTime, setSelectedDateTime] = useState("");
    const [appointmentType, setAppointmentType] = useState("Physio Evaluation");
    const [department, setDepartment] = useState("orthopedic");

    const handleDateTimeChange = (e) => {
      setSelectedDateTime(e.target.value);
    };

    const handleSave = (patient) => {
      if (selectedDateTime.trim().length === 0) {
        alert("Please enter the date and time for the appointment");
      } else {
        const appointment = {
          id: patient._id,
          schedule: moment(selectedDateTime)
            .utc(true)
            .format("YYYY-MM-DD HH:mm"),
          appointmentType: appointmentType,
          department: department,
        };
        updateAppointment(appointment)
          .unwrap()
          .then((d) => {
            alert("Appointment updated!");
            setShowSchedule(false);
          })
          .catch((e) => {
            console.log(e);
            alert(e.data.message);
          });
      }
    };

    return (
      <div className="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg p-6">
          <h2 className="text-lg text-gray-700 font-semibold mb-4">
            Select a date and time
          </h2>
          <input
            type="datetime-local"
            value={selectedDateTime}
            onChange={handleDateTimeChange}
            className="border rounded-lg px-3 py-2 mb-4 text-gray-700"
          />
          <h2 className="text-md text-gray-700 font-semibold mt-4">
            Choose Appointment For
          </h2>
          <select
            onChange={(e) => {
              setAppointmentType(e.target.value);
            }}
            className="w-full text-gray-700"
          >
            <option value={"Physio Evaluation"}>Physio Evaluation</option>
            <option value={"Physio Session"}>Physio Session</option>
            <option value={"Doctor Consultation"}>Doctor Consultation</option>
            {/* <option>Diet</option>
            <option>Dietician</option> */}
            <option value={"Home Visit"}>Home Visit</option>
          </select>
          <h2 className="text-md text-gray-700 font-semibold mt-4">
            Choose Department
          </h2>
          <select
            onChange={(e) => {
              setDepartment(e.target.value);
            }}
            className="w-full text-gray-700"
          >
            <option value={"orthopedic"}>Orthopedic</option>
            <option value={"sports_injury"}>Sports</option>
            <option value={"gynaecology"}>Gynae</option>
            {/* <option>Diet</option>
            <option>Dietician</option> */}
            <option value={"pcod"}>PCOD</option>
          </select>

          <div className="flex gap-2 justify-end mt-4">
            <button
              onClick={() => {
                setShowSchedule(false);
              }}
              className="bg-red-500 hover:bg-red-600 text-white rounded-lg px-4 py-2"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleSave(patient);
              }}
              className="bg-blue-500 hover:bg-blue-600 text-white rounded-lg px-4 py-2"
            >
              Schedule
            </button>
          </div>
        </div>
      </div>
    );
  };
  const tableRowStyle = {
    cursor: "pointer",
    margin: "8px",
    backgroundColor: isDarkMode
      ? tokensDark.primary[600]
      : tokensDark.secondary[300],
    color: isDarkMode ? tokensDark.secondary[800] : tokensDark.secondary[700],
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredAppointments.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredAppointments.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderRow = (appointment, idx) => {
    const { schedule, painPoint, status, createdAt } = appointment;
    const { name, mobile } = appointment.user ? appointment.user : "Not Found";

    const backgroundColor = theme.palette.primary.light

    return (
      <TableRow
      
      sx={{
        
        backgroundColor: backgroundColor,
        color: isDarkMode ? tokensDark.secondary[800] : tokensDark.secondary[700],
        ":hover":{
          backgroundColor: isDarkMode ? tokensDark.primary[300] : tokensDark.grey[200]
        }
        
      }}
        key={appointment.id}
        onClick={() => handleRowClick(appointment)}
      >
        <TableCell>{name}</TableCell>
        <TableCell>
          {appointment.partner ? appointment.partner.name : "Not Assigned"}
        </TableCell>
        <TableCell>{moment(schedule).utc(false).format("DD/MM/YYYY HH:mm")}</TableCell>
        <TableCell>{painPoint}</TableCell>
        <TableCell>{mobile}</TableCell>
        <TableCell>{getStatusText(status)}</TableCell>
        <TableCell>{moment(createdAt).format("DD/MM/YYYY HH:mm")}</TableCell>
      </TableRow>
    );
  };
  const HandleChangeStatus = (appointment, status) => {
    updateAppointment({
      id: appointment._id,
      status: status,
    })
      .unwrap()
      .then((d) => {
        alert("Status Changed Successfully!");
      })
      .catch((e) => {
        alert(e.data.message);
      });
  };
  const HandleStartCall = (appointment) => {
    const callId = appointment._id;
    const message = {
      notification: {
        title: "Incoming Call",
        body: `Incoming Call From ${userData.userData[0].name}`,
      },
      data: {
        title: "Incoming Call",
        content: `Incoming Call From ${userData.userData[0].name}`,
        start_video_call: "true",
        channel_id: callId,
      },
      topic: `${appointment._id}-${appointment.user._id}`,
    };
    // console.log(message)
   
    sendRequest(message)
      .unwrap()
      .then((d) => {
        if (d) {
          navigate(`/video-call?appointment=${callId}`);
        }
      })
      .catch((err) => {
        alert(err.data.message);
      });
  };
  const [showSchedule, setShowSchedule] = useState(false);
  const [showPartners, setShowPartners] = useState(false);
  const renderExpandedRow = (appointment, idx) => {
    const backgroundColor = theme.palette.background.alt;
    const handleMedicalClick = (appointment) => {
      navigate(`/appointment/medicalrecord?patient=${appointment.user._id}`, {
        state: { appointment },
      });
    };

    return (
      <TableRow
      sx={{
        cursor: 'pointer',
        backgroundColor: backgroundColor,
        color: isDarkMode ? tokensDark.secondary[800] : tokensDark.primary[700],
       
      }}
        key={`${appointment.id}-expanded`}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, cursor: "pointer" }}
          colSpan={7}
        >
          {showSchedule && (
            <DateTimePickerModal
              setShowSchedule={setShowSchedule}
              showSchedule={showSchedule}
              patient={appointment}
            />
          )}
            {showPartners && (
            <SelectPartner
             appointment={appointment}
            />
          )}
          <div className="expanded-row">
            <Button
              style={{
                margin: "8px",
                borderColor: "gray",
                color:  isDarkMode ? "white" : "black",
              }}
              variant="outlined"
              onClick={() =>
                appointment.status === "active"
                  ? HandleStartCall(appointment)
                  : alert("Appointment not active yet!")
              }
            >
              Start call
            </Button>
            <Button
              style={{
                margin: "8px",
                borderColor: "gray",
                color:  isDarkMode ? "white" : "black",
              }}
              variant="outlined"
              onClick={() => {
                handleCancelAppointment(appointment);
              }}
            >
              Cancel appointment
            </Button>
            <Button
              style={{
                margin: "8px",
                borderColor: "gray",
                color:  isDarkMode ? "white" : "black",
              }}
              variant="outlined"
              onClick={() => {
                setShowSchedule(true);
              }}
            >
              Update Appointment
            </Button>
            <Button
              style={{
                margin: "8px",
                borderColor: "gray",
                color:  isDarkMode ? "white" : "black",
              }}
              variant="outlined"
              onClick={() => handleMedicalClick(appointment)}
            >
              View medical record
            </Button>
            <Button
              style={{
                margin: "8px",
                borderColor: "gray",
                color:  isDarkMode ? "white" : "black",
              }}
              variant="outlined"
              onClick={() => setShowPartners(true)}
            >
              Assign Partner
            </Button>
            <Select
              value={"change"}
              
              variant="standard"
              className="m-2"
              onChange={(e) => {
                HandleChangeStatus(appointment, e.target.value);
              }}
            >
              <MenuItem disabled value={"change"}>
                Change Status
              </MenuItem>
              <MenuItem value={"active"}>Active</MenuItem>
              <MenuItem value={"completed"}>Completed</MenuItem>
              <MenuItem value={"expired"}>Expired</MenuItem>
            </Select>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <div className="flex justify-between mb-4">
        <ToastContainer
          containerId="toast"
          autoClose={2000}
          position="top-center"
          hideProgressBar={true}
        />

        <ButtonGroup>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearch}
          />
          <IconButton onClick={handleDownload}>
            <GetApp />
          </IconButton>
          <IconButton onClick={handlePrint}>
            <Print />
          </IconButton>
          <IconButton>
            <ViewColumn />
          </IconButton>
          <IconButton onClick={handleFilterDialogOpen}>
            <FilterList />
          </IconButton>
        </ButtonGroup>
      </div>

      <TableContainer
         
        ref={tableRef}
        style={{ marginTop: "16px", height:"500px", scrollbarWidth:0}}
        component={Paper}
      >
        <Table stickyHeader >
          <TableHead >
            <TableRow
               
               sx={{
                backgroundColor: theme.palette.background.alt,
                color: isDarkMode ? tokensDark.secondary[800] : tokensDark.secondary[700],
                fontWeight: 'bold',
              }}
            >
              <TableCell style={{ backgroundColor: theme.palette.background.alt}}>Patient</TableCell>
              <TableCell style={{ backgroundColor: theme.palette.background.alt}}>Partner</TableCell>
              <TableCell style={{ backgroundColor: theme.palette.background.alt}} onClick={handleSortScheduleChange}>Schedule On {" "}
                {sortSchedule === "asc" ? <ArrowDownward /> : <ArrowUpward />}</TableCell>
              <TableCell style={{ backgroundColor: theme.palette.background.alt}}>Pain point</TableCell>
              <TableCell style={{ backgroundColor: theme.palette.background.alt}}>Phone number</TableCell>
              <TableCell style={{ backgroundColor: theme.palette.background.alt}}>
                Status{" "}
                
              </TableCell>
              <TableCell style={{ backgroundColor: theme.palette.background.alt}} onClick={handleSortOrderChange}>
                Created At{" "}
                {sortOrder === "asc" ? <ArrowDownward /> : <ArrowUpward />}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentItems.map((appointment, idx) => (
              <>
                {renderRow(appointment, idx)}
                {selectedAppointment === appointment &&
                  renderExpandedRow(appointment, idx)}
              </>
            ))}
          </TableBody>
          <TableFooter>
            
          </TableFooter>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <div className="justify-center mt-4   overflow-x-auto ">
        <ButtonGroup>
          <Button
          
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
             style={{color:tokensDark.grey[500], borderColor:tokensDark.grey[500], border:"solid"}}
          >
            Previous
          </Button>
          {Array.from({ length: totalPages }, (_, i) => (
            <Button
              key={i + 1}
              variant={currentPage === i + 1 ? "contained" : "outlined"}
              onClick={() => handlePageChange(i + 1)}
              style={{color:tokensDark.grey[400], borderColor:tokensDark.grey[500],border:"solid"}}
            >
              {i + 1}
            </Button>
          ))}
          <Button
         
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            style={{color:tokensDark.grey[500], borderColor:tokensDark.grey[500],border:"solid"}}
          >
            Next
          </Button>
        </ButtonGroup>
      </div>

      <Dialog open={openFilterDialog} onClose={handleFilterDialogClose}>
        <DialogTitle>Filter Table Data</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Patient name"
            type="text"
            fullWidth
            variant="outlined"
            value={filterCriteria.name}
            onChange={(e) => handleFilterCriteriaChange(e, "name")}
          />
          <TextField
            margin="dense"
            label="Date"
            type="text"
            fullWidth
            variant="outlined"
            value={filterCriteria.date}
            onChange={(e) => handleFilterCriteriaChange(e, "date")}
          />
          <TextField
            margin="dense"
            label="Pain point"
            type="text"
            fullWidth
            variant="outlined"
            value={filterCriteria.painPoint}
            onChange={(e) => handleFilterCriteriaChange(e, "painPoint")}
          />
          <TextField
            margin="dense"
            label="Phone number"
            type="text"
            fullWidth
            variant="outlined"
            value={filterCriteria.phoneNumber}
            onChange={(e) => handleFilterCriteriaChange(e, "phoneNumber")}
          />
          <TextField
            margin="dense"
            label="Status"
            type="text"
            fullWidth
            variant="outlined"
            value={filterCriteria.status}
            onChange={(e) => handleFilterCriteriaChange(e, "status")}
          />

          <Button variant="contained" onClick={handleFilterDialogClose}>
            Apply Filter
          </Button>

          <Button
            variant="contained"
            style={{ marginLeft: "8px" }}
            onClick={handleResetFilterCriteria}
          >
            Reset Filters
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AppointmentTable;
