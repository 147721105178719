import { Add, ArrowBack, Remove } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { IconButton, MenuItem, Select, Tab, Tabs,useTheme } from '@mui/material';
import { Box } from '@mui/system';
import MultiWeekDetail from 'components/MultiWeekDetail';
import TestMultiWeek from 'components/TestMultiWeek';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCreatePlanMutation, useCreateSubscriptionMutation, useGetExercisesQuery, useGetPlanDetailsQuery, useUpdateCustomPlanMutation } from 'state/api';

const ViewCustomPlan = () => {
    const [name, setName] = useState('');
    const [weeks, setWeeks] = useState(1);
    const [department, setDepartment] = useState('');
    const [painPoints, setPainPoints] = useState([]);
    const [sports, setSports] = useState([]);
    const [multiWeekPlan, setMultiWeekPlan] = useState([])
    const {data, loading} = useGetExercisesQuery();
    const [selectedWeek, setSelectedWeek] = useState(0)
    const [numberOfSessions, setNumberOfSessions] = useState(1)
    const [createCustomPlan, response] = useCreatePlanMutation()
    const [updateCustomPlan, response2] = useUpdateCustomPlanMutation()
    const [error, setError] = useState(false)
    const [customPlan, setCustomPlan] = useState(true)
    const [success, setSuccess] = useState(false)
    const [message, setMessage] = useState()
    const query = useLocation().search;
    const user = new URLSearchParams(query).get("user");

    const partner = new URLSearchParams(query).get("partner")
    const planId = new URLSearchParams(query).get("plan")
    const getPlanDetails = useGetPlanDetailsQuery(planId)
    useEffect(()=>{
        
        if (getPlanDetails.data) {
           
            let multiWP = [];
            for (let index = 0; index < getPlanDetails.data.multiWeekPlan.length; index++) {
                const element = getPlanDetails.data.multiWeekPlan[index];
                let exer = []
                for (let j = 0; j < element.exercises.length; j++) {
                    const element1 =  element.exercises[j];
                    exer.push({
                        exercise: element1.exercise._id,
                        sets: element1.sets,
                        reps:element1.reps,
                        times:element1.times
                    })
                }
                multiWP.push({
                    weekNumber: element.weekNumber,
                    isDaily: element.isDaily,
                    exercises: exer
                })
            }
          
            setWeeks(getPlanDetails.data.multiWeekPlan.length)
            setMultiWeekPlan(multiWP)
            setName(getPlanDetails.data.name)
            console.log("plan Pain",getPlanDetails.data.painPoint)
            setPainPoints(getPlanDetails.data.painPoint)
            setDepartment(getPlanDetails.data.department[0])
            setSports(getPlanDetails.data.sports)
            setNumberOfSessions(getPlanDetails.data.numberOfSessions)
            setCustomPlan(getPlanDetails.data.custom)
            
        }
    }, [getPlanDetails.data])
    const handleExercises = (week, exercises)=>{
      
            for (let index = 0; index < exercises.length; index++) {
                const element = exercises[index];
                if (element.exercise.length === 0) {
                    setError(true)
                    setMessage("Select exercise name")
                    return
                }
                if (element.sets === 0) {
                    setError(true)
                    setMessage("Sets should be more than 0")
                    return
                }
                if (element.reps === 0) {
                    setError(true)
                    setMessage("Reps should be more than 0")
                    return
                }
                
            }
            const tempWeek = multiWeekPlan.map((w)=>{
                if (w.weekNumber === week.weekNumber) {
                   w.exercises = exercises
                }
                return w
            });
            setMultiWeekPlan(tempWeek)
            setSuccess(true)
            setError(false)
            setMessage("Exercise updated")
            setTimeout(()=>{
                setSuccess(false)
            },2000)
            console.log(tempWeek)
            
    }
    //   useState(()=>{
    //     const mWP = [];
    //     for (let index = 0; index < weeks; index++) {
    //         mWP.push({
    //             week: index+1,
    //             isDaily:false,
    //             exercises: []
    //         })
    //     }
    //     setMultiWeekPlan(mWP)
    //     console.log(mWP)
    //   }, [weeks])
    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const [createSubscription, response1] = useCreateSubscriptionMutation()
    const [days, setDays] = useState(45)


    // const days = numberOfWeeks === 4 ? 30 : numberOfWeeks === 12 ? 90 : 365
    const _todayStartOfDay = moment().startOf('day').utc(false);
    const _startsAt = _todayStartOfDay.toDate();
    const _endsAt = moment(_todayStartOfDay)
        .add(days, 'd')
        .endOf('day')
        .utc(false)
        .toDate();
    var morningSession = moment().utc(false);

    morningSession.set('hour', 8);
    morningSession.set('minute', 0)

    var eveningSession = moment().utc(false);
    eveningSession.set('hour', 18);
    eveningSession.set('minute', 0)
    const sessions = [{ session: morningSession }, { session: eveningSession }];

    const handleUpdatePlan = () => {
        if (name.trim().length === 0) {
            setError(true)
            setMessage("Name is Required")
        } else if (department.trim().length === 0) {
            setError(true)
            setMessage("Select a department")
        } else if (painPoints.length === 0) {
            setError(true)
            setMessage("Select a pain point")
        } else if (department === "sports_injury" && sports.length === 0) {
            setError(true)
            setMessage("Select a sport")
        } else {
            for (let index = 0; index < multiWeekPlan.length; index++) {
                const element = multiWeekPlan[index];
                if (element.exercises.length === 0 ) {
                    setError(true)
                    setMessage("Add all exercises to each week")
                    return
                }
            }
            const plan = {
                id: planId, name: name, painPoint: painPoints, department: [department],
                multiWeekPlan: multiWeekPlan, custom:customPlan, numberOfSessions:numberOfSessions
            }
           
            updateCustomPlan(plan, planId).unwrap().then((data) => {
                setSuccess(true)
                setMessage("Plan Updated Successfully!")
                setTimeout(() => {
                    setSuccess(false)
                    setMessage()
                    // navigate(`/custom/details`)
                }, 2000)
            }).catch((error) => {
                setError(true)
                setMessage(error.data.message)
                setTimeout(() => {
                    setError(false)
                    setMessage()
                    // navigate(`/custom/details`)
                }, 2000)
            })
        }
    }
    const handleAssignPlan = (id) => {
        const subscription = {
            plan: id, user: user, days: days,
            startsAt: _startsAt, endsAt: _endsAt, sessions: sessions, status: "active"
        }
        createSubscription(subscription).unwrap().then((data) => {


            setSuccess(true)
            setMessage("Assigned Plan Successfully!")

            setTimeout(() => {
                setSuccess(false)
                setMessage()
            }, 2000)
        }).catch((error) => {
            setError(true)
            setMessage(error.message)
        })
    }
    const handleCreatePlan = () => {
        if (name.trim().length === 0) {
            setError(true)
            setMessage("Name is Required")
        } else if (department.trim().length === 0) {
            setError(true)
            setMessage("Select a department")
        } else if (painPoints.length === 0) {
            setError(true)
            setMessage("Select a pain point")
        } else if (department === "sports_injury" && sports.length === 0) {
            setError(true)
            setMessage("Select a sport")
        } else {
            for (let index = 0; index < multiWeekPlan.length; index++) {
                const element = multiWeekPlan[index];
                if (element.exercises.length === 0 ) {
                    setError(true)
                    setMessage("Add all exercises to each week")
                    return
                }
            }
            const plan = {
                name: name, painPoint: painPoints, department: [department],
                multiWeekPlan: multiWeekPlan, custom:customPlan, createdBy: localStorage.getItem("user"), numberOfSessions:numberOfSessions
            }
           
            createCustomPlan(plan).unwrap().then((data) => {
                setSuccess(true)
                setMessage("Custom Plan Created Successfully!")
                setTimeout(() => {
                    setSuccess(false)
                    setMessage()
                    // navigate(`/custom/details`)
                }, 2000)
            }).catch((error) => {
                setError(true)
                setMessage(error.data.message)
                setTimeout(() => {
                    setError(false)
                    setMessage()
                    // navigate(`/custom/details`)
                }, 2000)
            })
        }
    }
    const handleModifyAndAssign = () =>{
        if (name.trim().length === 0) {
            setError(true)
            setMessage("Name is Required")
        } else if (department.trim().length === 0) {
            setError(true)
            setMessage("Select a department")
        } else if (painPoints.length === 0) {
            setError(true)
            setMessage("Select a pain point")
        } else if (department === "sports_injury" && sports.length === 0) {
            setError(true)
            setMessage("Select a sport")
        } else {
            for (let index = 0; index < multiWeekPlan.length; index++) {
                const element = multiWeekPlan[index];
                if (element.exercises.length === 0 ) {
                    setError(true)
                    setMessage("Add all exercises to each week")
                    return
                }
            }
            const plan = {
                name: name, painPoint: painPoints, department: [department],
                multiWeekPlan: multiWeekPlan, custom:customPlan, createdBy: localStorage.getItem("user"), numberOfSessions:numberOfSessions
            }
           
            createCustomPlan(plan).unwrap().then((data) => {
                setSuccess(true)
                setMessage("Custom Plan Created Successfully!")
                handleAssignPlan(data._id)
                setTimeout(() => {
                    setSuccess(false)
                    setMessage()
                    // navigate(`/custom/details`)
                }, 2000)
            }).catch((error) => {
                setError(true)
                setMessage(error.data.message)
                setTimeout(() => {
                    setError(false)
                    setMessage()
                    // navigate(`/custom/details`)
                }, 2000)
            })
        }
    }
    // const handleWeeksChange = (e) => {
    //     setWeeks(e.target.value);
    //     const mWP = multiWeekPlan;
    //    if (e.target.value > multiWeekPlan.length) {
    //     const diff = e.target.value - multiWeekPlan.length
    //     for (let index = 0; index < diff; index++) {
    //         mWP.push({
    //             weekNumber: index+1+multiWeekPlan.length,
    //             isDaily:false,
    //             exercises: []
    //          })
    //     }
    //    } else {
    //     const diff =  multiWeekPlan.length - e.target.value
    //     for (let index = 0; index < diff; index++) {
    //         mWP.pop()
    //    }
    //    }
    //     setMultiWeekPlan(mWP)
    // };

    const handleAddWeek = ()=>{
        setWeeks(weeks+1);
        const mWP = multiWeekPlan;
        mWP.push({
            weekNumber: multiWeekPlan.length+1,
            isDaily:false,
            exercises: []
         })
         setMultiWeekPlan(mWP)
    }

    const handleRemoveWeek = () => {
        setWeeks(weeks-1);
        const mWP = multiWeekPlan;
        mWP.pop()
        setMultiWeekPlan(mWP)
    }

    const handleDepartmentChange = (e) => {
        if (e.target.value !== "sports_injury") {
            setSports([])
        }
        setDepartment(e.target.value);
    };

    const handlePainPointsChange = (e) => {
        const selectedPainPoint = e.target.value;
        console.log(e.target.value)
        if (painPoints.includes(selectedPainPoint)) {
            setPainPoints(painPoints.filter((point) => point !== selectedPainPoint));
        } else {
            setPainPoints([...painPoints, selectedPainPoint]);
        }
    };
    const handleSportsChange = (e) => {
        const selectedSport = e.target.value;
        console.log(e.target.value)
        if (sports.includes(selectedSport)) {
            setSports(sports.filter((point) => point !== selectedSport));
        } else {
            setSports([...sports, selectedSport]);
        }
    };
    const navigate = useNavigate();
    const isSelectedPainPoint = (point) => {

        return painPoints.includes(point);
    };
    const isSelectedSport = (sport) => {

        return sports.includes(sport);
    };
    const sportsArray = [
        "badminton", "swimming", "football", "tennis", "basketball", "volleyball", "hand_ball", "cricket", "kabaddi", "weight_lifting", "wrestling"
    ]

    const theme = useTheme()
    const backgroundColor = theme.palette.primary.light
    const isDarkMode = theme.palette.mode === 'dark';
   
    return (
        <div className='flex flex-col md:flex-row'>
            <div style={{backgroundColor: theme.palette.background.alt }} className="bg-[#21295c] text-[#ffe3a3] md:w-1/4 w-full p-4">
                <IconButton onClick={() => { navigate(-1) }}>
                    <ArrowBack />
                </IconButton>
                <div className="my-4">
                    <label style={{color:isDarkMode?'#ffe3a3':'black'}}className="block font-bold mb-2" htmlFor="name">
                        Plan Name
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="name"
                        type="text"
                        placeholder="Enter Name (must be unique)"
                        value={name}
                        onChange={handleNameChange}
                    />
                </div>
                <div className="my-4">
                    <label  style={{color:isDarkMode?'#ffe3a3':'black'}} className="block font-bold mb-2" htmlFor="weeks">
                        Number of Weeks
                    </label>
                   <div className='flex justify-center gap-2'>
                    <IconButton onClick={()=>{handleRemoveWeek()}}>
                        <Remove/>
                    </IconButton>
                   <input
                        min={1}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline disabled:bg-white"
                        id="weeks"
                        type="number"
                        placeholder="Enter Number of Weeks"
                        value={weeks}
                        disabled
                       
                    />
                     <IconButton onClick={()=>{handleAddWeek()}}>
                        <Add/>
                    </IconButton>
                   </div>
                </div>
                <div className="my-4">
                    <label  style={{color:isDarkMode?'#ffe3a3':'black'}} className="block font-bold mb-2" htmlFor="weeks">
                        Number of Session
                    </label>
                    <input
                        min={1}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="sessions"
                        type="number"
                        placeholder="Enter Number of Sessions in a day"
                        value={numberOfSessions}
                        onChange={(e)=>{setNumberOfSessions(e.target.value)}}
                    />
                </div>
                
                <div className="my-4">
                    <label  style={{color:isDarkMode?'#ffe3a3':'black'}} className="block font-bold mb-2" htmlFor="department">
                        Department
                    </label>
                    <select
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="department"
                        value={department}
                        onChange={handleDepartmentChange}
                    >
                        <option value="" selected disabled>Select Department</option>
                        <option value="orthopedic">Orthopedic</option>
                        <option value="sports_injury">Sports</option>
                        <option value="gynaecology">Gynaecology</option>
                        <option value="pcod">PCOD</option>
                    </select>
                </div>
                <div className="my-4">
                    <label  style={{color:isDarkMode?'#ffe3a3':'black'}} className="block font-bold mb-2">Pain Points</label>
                    <div className="flex flex-wrap">
                        <div className="m-1">
                            <input
                                className="hidden"
                                id="lowerBack"
                                type="checkbox"
                                value="lowerBack"
                                onChange={handlePainPointsChange}
                            />
                            <label
                                className={`inline-flex items-center bg-[#586cfa] bg-opacity-${isSelectedPainPoint('lowerBack') ? 100 : 40} rounded px-3 py-1 cursor-pointer`}
                                htmlFor="lowerBack"
                            >
                                Lower Back
                            </label>
                        </div>
                        <div className="m-1">
                            <input
                                className="hidden"
                                id="upperBack"
                                type="checkbox"
                                value="upperBack"
                                onChange={handlePainPointsChange}
                            />
                            <label
                                className={`inline-flex items-center bg-[#586cfa] bg-opacity-${isSelectedPainPoint('upperBack') ? 100 : 40} rounded px-3 py-1 cursor-pointer`}
                                htmlFor="upperBack"
                            >
                                Upper Back
                            </label>
                        </div>
                        <div className="m-1">
                            <input
                                className="hidden"
                                id="neck"
                                type="checkbox"
                                value="neck"
                                onChange={handlePainPointsChange}
                            />
                            <label
                                className={`inline-flex items-center bg-[#586cfa] bg-opacity-${isSelectedPainPoint('neck') ? 100 : 40} rounded px-3 py-1 cursor-pointer`}
                                htmlFor="neck"
                            >
                                Neck
                            </label>
                        </div>
                        <div className="m-1">
                            <input
                                className="hidden"
                                id="shoulder"
                                type="checkbox"
                                value="shoulder"
                                onChange={handlePainPointsChange}
                            />
                            <label
                                className={`inline-flex items-center bg-[#586cfa] bg-opacity-${isSelectedPainPoint('shoulder') ? 100 : 40} rounded px-3 py-1 cursor-pointer`}
                                htmlFor="shoulder"
                            >
                                Shoulder
                            </label>
                        </div>
                        <div className="m-1">
                            <input
                                className="hidden"
                                id="knee"
                                type="checkbox"
                                value="knee"
                                onChange={handlePainPointsChange}
                            />
                            <label
                                className={`inline-flex items-center bg-[#586cfa] bg-opacity-${isSelectedPainPoint('knee') ? 100 : 40} rounded px-3 py-1 cursor-pointer`}
                                htmlFor="knee"
                            >
                                Knee
                            </label>
                        </div>
                        <div className="m-1">
                            <input
                                className="hidden"
                                id="hip"
                                type="checkbox"
                                value="hip"
                                onChange={handlePainPointsChange}
                            />
                            <label
                                className={`inline-flex items-center bg-[#586cfa] bg-opacity-${isSelectedPainPoint('hip') ? 100 : 40} rounded px-3 py-1 cursor-pointer`}
                                htmlFor="hip"
                            >
                                Hip
                            </label>
                        </div>
                        <div className="m-1">
                            <input
                                className="hidden"
                                id="ankle"
                                type="checkbox"
                                value="ankle"
                                onChange={handlePainPointsChange}
                            />
                            <label
                                className={`inline-flex items-center bg-[#586cfa] bg-opacity-${isSelectedPainPoint('ankle') ? 100 : 40} rounded px-3 py-1 cursor-pointer`}
                                htmlFor="ankle"
                            >
                                Ankle
                            </label>
                        </div>
                        <div className="m-1">
                            <input
                                className="hidden"
                                id="wristAndHand"
                                type="checkbox"
                                value="wristAndHand"
                                onChange={handlePainPointsChange}
                            />
                            <label
                                className={`inline-flex items-center bg-[#586cfa] bg-opacity-${isSelectedPainPoint('wristAndHand') ? 100 : 40} rounded px-3 py-1 cursor-pointer`}
                                htmlFor="wristAndHand"
                            >
                                Wrist and Hand
                            </label>
                        </div>
                        <div className="m-1">
                            <input
                                className="hidden"
                                id="elbow"
                                type="checkbox"
                                value="elbow"
                                onChange={handlePainPointsChange}
                            />
                            <label
                                className={`inline-flex items-center bg-[#586cfa] bg-opacity-${isSelectedPainPoint('elbow') ? 100 : 40} rounded px-3 py-1 cursor-pointer`}
                                htmlFor="elbow"
                            >
                                Elbow
                            </label>
                        </div>
                    </div>
                </div>
                {
                    department === "sports_injury" && <div className="my-4">
                        <label className="block font-bold mb-2">Sports</label>
                        <div className="flex flex-wrap">
                            {
                                sportsArray.map((s, i) => {
                                    return <div className="m-1" key={i}>
                                        <input
                                            className="hidden"
                                            id={s}
                                            type="checkbox"
                                            value={s}
                                            onChange={handleSportsChange}
                                        />
                                        <label
                                            className={`inline-flex items-center bg-[#586cfa] bg-opacity-${isSelectedSport(s) ? 100 : 40} rounded px-3 py-1 cursor-pointer`}
                                            htmlFor={s}
                                        >
                                            {s.split('_').join(' ')}
                                        </label>
                                    </div>
                                })
                            }
                        </div>

                    </div>
                }
                <div className="my-4">
                    <label  style={{color:isDarkMode?'#ffe3a3':'black'}} className="block font-bold mb-2" htmlFor="weeks">
                        Number of days for Plan to be Active
                    </label>
                    <Select
                                value={days}
                                label="Days"
                                fullWidth
                                onChange={(e) => setDays(e.target.value)}
                            >
                                <MenuItem value={45}>45</MenuItem>
                                <MenuItem value={90}>90</MenuItem>
                                <MenuItem value={180}>180</MenuItem>
                            </Select>
                </div>
                {/* <div className='mt-4'>
                    <span  style={{color:isDarkMode?'#ffe3a3':'black'}} className='text-sm'>Is Custom Plan</span>
                    <div className=' mt-2 flex gap-4 justify-between'>
                        <button onClick={()=>{setCustomPlan(true)}} className={`${customPlan ? 'bg-blue-500' : 'bg-blue-700'} px-4 py-1 rounded-md`}>Yes</button>
                        <button onClick={()=>{setCustomPlan(false)}} className={`${!customPlan ? 'bg-blue-500' : 'bg-blue-700'} px-4 py-1 rounded-md`}>No</button>
                    </div>
                 </div> */}
           { customPlan && <button onClick={handleUpdatePlan} className='rounded px-4 py-2 bg-[#5240eb] w-full mt-4'>Update Plan</button>  }
                <button onClick={handleCreatePlan} className='rounded px-4 py-2 bg-[#5240eb] w-full mt-4'>Modify Plan</button>
               { user && user != "null" &&  <button onClick={()=>{handleAssignPlan(planId)}} className='rounded px-4 py-2 bg-[#5240eb] w-full mt-4'>Assign Plan</button> }
               {user && user != "null" &&   <button onClick={()=>{handleModifyAndAssign()}} className='rounded px-4 py-2 bg-[#5240eb] w-full mt-4'>Modify & Assign</button>}
              
            </div>
            <div className="w-full md:w-3/4 py-4 text-white overflow-y-auto">

            <TabContext value={selectedWeek}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} borderRadius={2} padding={2}>
        {error && (
          <div className='w-full p-4 mt-2 rounded-lg bg-red-200 text-red-600 text-center'>
            {message}
          </div>
        )}
        {success && (
          <div className='w-full p-4 mt-2 rounded-lg bg-green-200 text-green-600 text-center'>
            {message}
          </div>
        )}
        <TabList variant="scrollable" onChange={(e, v) => { setSelectedWeek(Number.parseInt(v)) }} aria-label="basic tabs example">
          {Array.from({ length: weeks }, (_, index) => (
            <Tab value={index} key={index} label={`Week ${index + 1}`}></Tab>
          ))}
        </TabList>
      </Box>

      {multiWeekPlan.map((m, i) => (
        <TabPanel value={i} key={i}>
          {data && <MultiWeekDetail key={i} week={m} handleExercises={handleExercises} allExercises={data} />}
        </TabPanel>
      ))}
    </TabContext>
            </div>
        </div>
   
    );
};

export default ViewCustomPlan;