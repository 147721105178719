import React, { useState, useRef, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  useTheme,
} from '@mui/material';
import { Search, GetApp, Print, ViewColumn, FilterList } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import 'react-toastify/dist/ReactToastify.css';
import { tokensDark, tokensLight } from 'theme';
import { ToastContainer } from 'react-toastify';
import { useGetAccountsQuery } from 'state/api';

const Accounts = () => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const tableRef = useRef(null);
  const theme = useTheme();

  const [filterCriteria, setFilterCriteria] = useState({
    role: '',
    name: '',
    otp: '',
    email: '',
    mobile: '',
    otpSentAt: '',
    otpVerifiedAt: '',
    userFCMToken: '',
    referralCode: '',
    userReferralCode: '',
  });

  const { data: tableData = [], isLoading } = useGetAccountsQuery();
  

  const [filteredAccounts, setFilteredAccounts] = useState(tableData);
  useEffect(()=>{
    if (tableData) {
       setFilteredAccounts(tableData)
    }
  },[tableData])
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');

  const handleRowClick = (account) => {
    if (selectedAccount === account) {
      setSelectedAccount(null);
    } else {
      setSelectedAccount(account);
    }
  };

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleFilterCriteriaChange = (event, field) => {
    const { value } = event.target;
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      [field]: value,
    }));

    const filteredData = tableData.filter((account) => {
      const { role, name, otp, email, mobile, otpSentAt, otpVerifiedAt, userFCMToken, referralCode, userReferralCode } = account;
      const lowerCaseSearchQuery = value.toLowerCase();

      return (
        role.toLowerCase().includes(lowerCaseSearchQuery) ||
        name.toLowerCase().includes(lowerCaseSearchQuery) ||
        otp.toLowerCase().includes(lowerCaseSearchQuery) ||
        email.toLowerCase().includes(lowerCaseSearchQuery) ||
        mobile.toLowerCase().includes(lowerCaseSearchQuery) ||
        otpSentAt.toLowerCase().includes(lowerCaseSearchQuery) ||
        otpVerifiedAt.toLowerCase().includes(lowerCaseSearchQuery) ||
        userFCMToken.toLowerCase().includes(lowerCaseSearchQuery) ||
        referralCode.toLowerCase().includes(lowerCaseSearchQuery) ||
        userReferralCode.toLowerCase().includes(lowerCaseSearchQuery)
      );
    });

    setFilteredAccounts(filteredData);
  };

  const handleSearch = () => {
    const filteredData = tableData.filter((account) => {
      const { role, name, otp, email, mobile, otpSentAt, otpVerifiedAt, userFCMToken, referralCode, userReferralCode } = account;
      const lowerCaseSearchQuery = searchQuery.toLowerCase();

      return (
        role.toLowerCase().includes(lowerCaseSearchQuery) ||
        name.toLowerCase().includes(lowerCaseSearchQuery) ||
        otp.toLowerCase().includes(lowerCaseSearchQuery) ||
        email.toLowerCase().includes(lowerCaseSearchQuery) ||
        mobile.toLowerCase().includes(lowerCaseSearchQuery) ||
        otpSentAt.toLowerCase().includes(lowerCaseSearchQuery) ||
        otpVerifiedAt.toLowerCase().includes(lowerCaseSearchQuery) ||
        userFCMToken.toLowerCase().includes(lowerCaseSearchQuery) ||
        referralCode.toLowerCase().includes(lowerCaseSearchQuery) ||
        userReferralCode.toLowerCase().includes(lowerCaseSearchQuery)
      );
    });

    setFilteredAccounts(filteredData);
  };

  const handleDownload = () => {
    const csvData = convertToCSV(filteredAccounts);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'accounts.csv');
  };

  const handlePrint = () => {
    window.print();
  };

  const handleResetFilterCriteria = () => {
    setFilterCriteria({
      role: '',
      name: '',
      otp: '',
      email: '',
      mobile: '',
      otpSentAt: '',
      otpVerifiedAt: '',
      userFCMToken: '',
      referralCode: '',
      userReferralCode: '',
    });
  };

  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]);
    const rows = data.map((row) => Object.values(row));
    const csvContent =
      headers.join(',') +
      '\n' +
      rows.map((row) => row.join(',')).join('\n');

    return csvContent;
  };

  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <div className="flex flex-col justify-between mb-4">
    <ToastContainer containerId="toast" autoClose={2000} position="top-center" hideProgressBar={true} />
      <ButtonGroup>
        <IconButton>
          <Search />
        </IconButton>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearch}
        />
        <IconButton onClick={handleDownload}>
          <GetApp />
        </IconButton>
        <IconButton onClick={handlePrint}>
          <Print />
        </IconButton>
        <IconButton>
          <ViewColumn />
        </IconButton>
        <IconButton onClick={handleFilterDialogOpen}>
          <FilterList />
        </IconButton>
      </ButtonGroup>
    
    <TableContainer ref={tableRef} component={Paper} sx={{ backgroundColor: theme.palette.background.paper, marginTop:'50px', }}>
        <Table>
          <TableHead>
          <TableRow tyle={{
          cursor: 'pointer',
          backgroundColor: '#06334B',
          color: isDarkMode ? tokensDark.secondary[800] : tokensDark.secondary[700],
        }}>
              <TableCell>Role</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>OTP</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>OTP Sent At</TableCell>
              <TableCell>OTP Verified At</TableCell>
              <TableCell>User FCM Token</TableCell>
              <TableCell>Referral Code</TableCell>
              <TableCell>User Referral Code</TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
            {filteredAccounts.map((account, index) => (
              <TableRow
                key={index}
                onClick={() => handleRowClick(account)}
                selected={selectedAccount === account}
              >
                <TableCell>{account.role}</TableCell>
                <TableCell>{account.name}</TableCell>
                <TableCell>{account.otp}</TableCell>
                <TableCell>{account.email}</TableCell>
                <TableCell>{account.mobile}</TableCell>
                <TableCell>{account.otpSentAt}</TableCell>
                <TableCell>{account.otpVerifiedAt}</TableCell>
                <TableCell>{account.userFCMToken}</TableCell>
                <TableCell>{account.referralCode}</TableCell>
                <TableCell>{account.userReferralCode}</TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openFilterDialog} onClose={handleFilterDialogClose}>
        <DialogTitle>Filter Table Data</DialogTitle>
        <DialogContent>
        <TextField
  margin="dense"
  label="Role"
  fullWidth
  variant="outlined"
  value={filterCriteria.role}
  onChange={(e) => handleFilterCriteriaChange(e, 'role')}
/>

<TextField
  margin="dense"
  label="Name"
  fullWidth
  variant="outlined"
  value={filterCriteria.name}
  onChange={(e) => handleFilterCriteriaChange(e, 'name')}
/>

<TextField
  margin="dense"
  label="OTP"
  fullWidth
  variant="outlined"
  value={filterCriteria.otp}
  onChange={(e) => handleFilterCriteriaChange(e, 'otp')}
/>

<TextField
  margin="dense"
  label="Email"
  fullWidth
  variant="outlined"
  value={filterCriteria.email}
  onChange={(e) => handleFilterCriteriaChange(e, 'email')}
/>

<TextField
  margin="dense"
  label="Mobile"
  fullWidth
  variant="outlined"
  value={filterCriteria.mobile}
  onChange={(e) => handleFilterCriteriaChange(e, 'mobile')}
/>

<TextField
  margin="dense"
  label="OTP Sent At"
  fullWidth
  variant="outlined"
  value={filterCriteria.otpSentAt}
  onChange={(e) => handleFilterCriteriaChange(e, 'otpSentAt')}
/>

<TextField
  margin="dense"
  label="OTP Verified At"
  fullWidth
  variant="outlined"
  value={filterCriteria.otpVerifiedAt}
  onChange={(e) => handleFilterCriteriaChange(e, 'otpVerifiedAt')}
/>

<TextField
  margin="dense"
  label="User FCM Token"
  fullWidth
  variant="outlined"
  value={filterCriteria.userFCMToken}
  onChange={(e) => handleFilterCriteriaChange(e, 'userFCMToken')}
/>

<TextField
  margin="dense"
  label="Referral Code"
  fullWidth
  variant="outlined"
  value={filterCriteria.referralCode}
  onChange={(e) => handleFilterCriteriaChange(e, 'referralCode')}
/>

<TextField
  margin="dense"
  label="User Referral Code"
  fullWidth
  variant="outlined"
  value={filterCriteria.userReferralCode}
  onChange={(e) => handleFilterCriteriaChange(e, 'userReferralCode')}
/>
<Button variant="contained" onClick={handleFilterDialogClose}>
            Apply Filter
          </Button>

          <Button
            variant="contained"
            style={{ marginLeft: '8px' }}
            onClick={handleResetFilterCriteria}
          >
            Reset Filters
          </Button>

        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Accounts;
