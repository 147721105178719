import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  useTheme,
} from "@mui/material";
import {
  Search,
  GetApp,
  Print,
  ViewColumn,
  FilterList,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";
import { tokensDark, tokensLight } from "theme";
import { ToastContainer } from "react-toastify";
import { useGetDiscountsQuery } from "state/api";

const Discounts = () => {
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const tableRef = useRef(null);
  const theme = useTheme();

  const backgroundColor = theme.palette.primary.light
  const [filterCriteria, setFilterCriteria] = useState({
    name: "",
    description: "",
    value: "",
    type: "",
    days: "",
  });

  const { data: tableData = [], isLoading } = useGetDiscountsQuery();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);

  const [filteredDiscounts, setFilteredDiscounts] = useState(tableData);
  useEffect(() => {
    if (tableData) {
      setFilteredDiscounts(tableData);
      setCurrentPage(1);
      console.log(tableData);
    }
  }, [tableData]);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const handleRowClick = (discount) => {
    if (selectedDiscount === discount) {
      setSelectedDiscount(null);
    } else {
      setSelectedDiscount(discount);
    }
  };

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleFilterCriteriaChange = (event, field) => {
    const { value } = event.target;
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      [field]: value,
    }));

    const filteredData = tableData.filter((discount) => {
      const { name, description, value, type, days } = discount;
      const lowerCaseSearchQuery = event.target.value.toLowerCase();

      return (
        (name &&
          name.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (description &&
          description
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (value &&
          value.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (type &&
          type.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (days && days.toString().toLowerCase().includes(lowerCaseSearchQuery))
      );
    });

    setFilteredDiscounts(filteredData);
    setCurrentPage(1); // Reset to the first page
  };

  const handleResetFilterCriteria = () => {
    setFilterCriteria({
      name: "",
      description: "",
      value: "",
      type: "",
      days: "",
    });
    setFilteredDiscounts(tableData);
    setCurrentPage(1); // Reset to the first page
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const applyFilter = () => {
    const filteredData = tableData.filter((discount) => {
      const { name, description, value, type, days } = discount;
      const lowerCaseName = name.toLowerCase();
      const lowerCaseDescription = description.toLowerCase();
      const lowerCaseValue = value.toLowerCase();
      const lowerCaseType = type.toLowerCase();
      const lowerCaseDays = days.toLowerCase();
      const lowerCaseSearchQuery = searchQuery.toLowerCase();

      return (
        lowerCaseName.includes(lowerCaseSearchQuery) &&
        lowerCaseDescription.includes(lowerCaseSearchQuery) &&
        lowerCaseValue.includes(lowerCaseSearchQuery) &&
        lowerCaseType.includes(lowerCaseSearchQuery) &&
        lowerCaseDays.includes(lowerCaseSearchQuery)
      );
    });

    setFilteredDiscounts(filteredData);
    setCurrentPage(1); // Reset to the first page
  };

  const handleExport = () => {
    const table = tableRef.current;
    const csv = [];
    const rows = table.getElementsByTagName("tr");

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const cols = row.querySelectorAll("td, th");
      const csvRow = [];

      for (let j = 0; j < cols.length; j++) {
        const col = cols[j].innerText.replace(",", "");
        csvRow.push(col);
      }

      csv.push(csvRow.join(","));
    }

    const csvContent = csv.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "discounts.csv");
  };

  const handlePrint = () => {
    const content = tableRef.current.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value);

    const filteredData = tableData.filter((discount) => {
      const { name, description, value, type, days } = discount;
      const lowerCaseSearchQuery = event.target.value.toLowerCase();

      return (
        (name &&
          name.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (description &&
          description
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (value &&
          value.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (type &&
          type.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (days && days.toString().toLowerCase().includes(lowerCaseSearchQuery))
      );
    });

    setFilteredDiscounts(filteredData);
  };

  const isDarkMode = theme.palette.mode === "dark";
  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredDiscounts.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredDiscounts.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="flex flex-col justify-between mb-4">
      <ToastContainer
        containerId="toast"
        autoClose={2000}
        position="top-center"
        hideProgressBar={true}
      />
      <ButtonGroup>
        <IconButton>
          <Search />
        </IconButton>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => {
            handleSearch(e);
          }}
        />
        <IconButton onClick={handleExport}>
          <GetApp />
        </IconButton>
        <IconButton onClick={handlePrint}>
          <Print />
        </IconButton>
        <IconButton>
          <ViewColumn />
        </IconButton>
        <IconButton onClick={handleFilterDialogOpen}>
          <FilterList />
        </IconButton>
      </ButtonGroup>

      <TableContainer
        ref={tableRef}
        component={Paper}
        sx={{
          backgroundColor: theme.palette.background.paper,
          marginTop: "50px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow
              style={{
                cursor: "pointer",
                backgroundColor: theme.palette.background.alt,
                color: isDarkMode
                  ? tokensDark.secondary[800]
                  : tokensDark.secondary[700],
              }}
            >
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Days</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDiscounts.map((discount) => (
              <TableRow
                key={discount.name}
                onClick={() => handleRowClick(discount)}
                className={selectedDiscount === discount ? "selected-row" : ""}
                style={{
                  cursor: "pointer",
                  backgroundColor: backgroundColor,
                  color: isDarkMode
                    ? tokensDark.secondary[800]
                    : tokensDark.secondary[700],
                }}
              >
                <TableCell>{discount.name}</TableCell>
                <TableCell>{discount.description}</TableCell>
                <TableCell>{discount.value}</TableCell>
                <TableCell>{discount.type}</TableCell>
                <TableCell>{discount.days}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <ButtonGroup>
          <Button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </Button>
          {Array.from({ length: totalPages }, (_, i) => (
            <Button
              key={i + 1}
              variant={currentPage === i + 1 ? "contained" : "outlined"}
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </Button>
          ))}
          <Button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </Button>
        </ButtonGroup>
      </div>

      <Dialog open={openFilterDialog} onClose={handleFilterDialogClose}>
        <DialogTitle>Filter Criteria</DialogTitle>
        <DialogContent>
          <div className="grid grid-cols-2 gap-4">
            <TextField
              label="Name"
              variant="outlined"
              size="small"
              value={filterCriteria.name}
              onChange={(e) => handleFilterCriteriaChange(e, "name")}
            />
            <TextField
              label="Description"
              variant="outlined"
              size="small"
              value={filterCriteria.description}
              onChange={(e) => handleFilterCriteriaChange(e, "description")}
            />
            <TextField
              label="Value"
              variant="outlined"
              size="small"
              value={filterCriteria.value}
              onChange={(e) => handleFilterCriteriaChange(e, "value")}
            />
            <TextField
              label="Type"
              variant="outlined"
              size="small"
              value={filterCriteria.type}
              onChange={(e) => handleFilterCriteriaChange(e, "type")}
            />
            <TextField
              label="Days"
              variant="outlined"
              size="small"
              value={filterCriteria.days}
              onChange={(e) => handleFilterCriteriaChange(e, "days")}
            />
          </div>
        </DialogContent>
        <div className="flex justify-end mt-2">
          <Button onClick={handleResetFilterCriteria}>Reset</Button>
          <Button onClick={applyFilter}>Apply</Button>
        </div>
      </Dialog>
    </div>
  );
};

export default Discounts;
