import { ArrowBack, Download } from "@mui/icons-material";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import logofull from "assets/logofull.svg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
export default function Report() {
  const location = useLocation();

  const _exportPdf = () => {
    var element = document.getElementById("pdf-div");
    var htmlWidth = element.offsetWidth;
    var htmlHeight = element.offsetHeight;
    var pdfWidth = htmlWidth + 15 * 2;
    var pdfHeight = pdfWidth * 1.5 + 15 * 2;
    var doc = new jsPDF("p", "pt", [pdfWidth, pdfHeight]);

    var pageCount = Math.ceil(htmlHeight / pdfHeight) - 1;

    html2canvas(document.getElementById("pdf-div"), { allowTaint: true }).then(
      (canvas) => {
        // document.body.appendChild(canvas);  // if you want see your screenshot in body.
        canvas.getContext("2d");
        const imgData = canvas.toDataURL("image/png", 1.0);

        doc.addImage(imgData, "PNG", 15, 15, htmlWidth, htmlHeight);
        for (var i = 1; i <= pageCount; i++) {
          doc.addPage();
          doc.addImage(
            imgData,
            "PNG",
            15,
            -(pdfHeight * i) + 15,
            htmlWidth,
            htmlHeight
          );
        }
        // doc.save("report.pdf")
        // doc.addImage(imgData, 'PNG', 0, 0, 600, 4400);
        doc.save("report.pdf");
        // window.location.reload()
      }
    );
  };
  const theme = useTheme();
  const navigate = useNavigate();
  if (!location.state === null) {
    window.location.replace("/");
  } else if (
    !location.state.user ||
    (location.state &&
      location.state.sessions &&
      location.state.sessions.length === 0)
  ) {
    window.location.replace("/");
  }
  const userData = location.state.user;
  const subscription = location.state.subscription;
  const beforeData = location.state.sessions[0];
  const afterData = location.state.sessions[location.state.sessions.length - 1];

  const [
    rom,
    painPerception,
    difficulty,
    assessedPain,
    muscleT,
    muscleST,
    rimData,
    mltData,
    limbData,
    sgGraphData,
    neuroGraphData,
    chestGraph,
    functionalGraph,
    touchGraph,
    painGraph,
    tempGraph,
    pressureGraph,
  ] = useMemo(() => {
    //    let romData = []
    //    for (let index = 0; index < location.state.sessions.length; index++) {
    //     const element = location.state.sessions[index];
    //     const romArray =  element.rom.split('-')
    //     const newRomData = [{month: moment(element.createdAt).format("DD-MMMM-YY"), upper:Number.parseFloat(romArray[0]), lower:Number.parseFloat(romArray[0])}]
    //      romData.push(newRomData)
    //    }
    const lowerRom = {
      id: "Physio Session ROM",
      color: theme.palette.secondary.main,
      data: [],
    };
    const painPerception1 = {
      id: "Pain Perception",
      color: theme.palette.secondary.main,
      data: [],
    };
    const difficulty1 = {
      id: "Difficulty",
      color: theme.palette.secondary.main,
      data: [],
    };

    const upperRom = {
      id: "Upper",
      color: theme.palette.primary.main,
      data: [],
    };
    const assessedPain1 = {
      id: "Assessed Pain Scale",
      color: theme.palette.primary.main,
      data: [],
    };

    const muscleT1 = {
      id: "Muscle Tone",
      color: theme.palette.primary.main,
      data: [],
    };
    const muscleST1 = {
      id: "Muscle Strength Testing",
      color: theme.palette.primary.main,
      data: [],
    };
    const rimData1 = {
      id: "Relative Isometric Movement",
      color: theme.palette.primary.main,
      data: [],
    };
    const mltData1 = {
      id: "Muscle Length Testing",
      color: theme.palette.primary.main,
      data: [],
    };
    const limbData1 = {
      id: "Limb Girth Measurement",
      color: theme.palette.primary.main,
      data: [],
    };

    const sgGraphData1 = {
      id: "Specific Geriatric",
      color: theme.palette.primary.main,
      data: [],
    };
    const neuroGraphData1 = {
      id: "Neuro",
      color: theme.palette.primary.main,
      data: [],
    };
    const chestGraph1 = {
      id: "Chest Expansion Before",
      color: theme.palette.primary.main,
      data: [],
    };
    const chestGraph2 = {
      id: "Chest Expansion After",
      color: theme.palette.primary.main,
      data: [],
    };
    const functionalGraph1 = {
      id: "Functional Assessment",
      color: theme.palette.primary.main,
      data: [],
    };
    const touchGraph1 = {
      id: "Touch",
      color: theme.palette.primary.main,
      data: [],
    };
    const painGraph1 = {
      id: "Pain",
      color: theme.palette.primary.main,
      data: [],
    };
    const tempGraph1 = {
      id: "Temperature",
      color: theme.palette.primary.main,
      data: [],
    };
    const pressureGraph1 = {
      id: "Pressure",
      color: theme.palette.primary.main,
      data: [],
    };
    const sessionRom = {
      id: "Device Feedback ROM",
      color: theme.palette.primary.main,
      data: [],
    };

    Object.values(location.state.sessions).reduce(
      (
        acc,
        {
          createdAt,
          assessedPainScale,
          muscleTone,
          muscleStrengthTesting,
          muscleLengthTest,
          relativeIsometricMovement,
          limbGirthMeasurement,
          rom,
          specificGeriatric,
          neuro,
          chestExpansion,
          functionalAssessment,
          touch,
          pain,
          temperature,
          pressure,
        }
      ) => {
        const romArray = rom;
        lowerRom.data = [
          ...lowerRom.data,
          {
            x: moment(createdAt).format("DD-MMMM-YY"),
            y: Number.parseFloat(rom),
          },
        ];
        if (assessedPainScale) {
          assessedPain1.data = [
            ...assessedPain1.data,
            { x: moment(createdAt).format("DD-MMMM-YY"), y: assessedPainScale },
          ];
        }

        if (muscleTone) {
          muscleT1.data = [
            ...muscleT1.data,
            { x: moment(createdAt).format("DD-MMMM-YY"), y: muscleTone },
          ];
        }

        if (muscleStrengthTesting) {
          muscleST1.data = [
            ...muscleST1.data,
            {
              x: moment(createdAt).format("DD-MMMM-YY"),
              y: muscleStrengthTesting,
            },
          ];
        }

        if (relativeIsometricMovement) {
          rimData1.data = [
            ...rimData1.data,
            {
              x: moment(createdAt).format("DD-MMMM-YY"),
              y: relativeIsometricMovement,
            },
          ];
        }

        if (muscleLengthTest) {
          mltData1.data = [
            ...mltData1.data,
            {
              x: moment(createdAt).format("DD-MMMM-YY"),
              y: muscleLengthTest === "Yes" ? "T.P" : "N.P",
            },
          ];
        }

        if (limbGirthMeasurement) {
          limbData1.data = [
            ...limbData1.data,
            {
              x: moment(createdAt).format("DD-MMMM-YY"),
              y: limbGirthMeasurement,
            },
          ];
        }
        if (specificGeriatric) {
          sgGraphData1.data = [
            ...sgGraphData1.data,
            { x: moment(createdAt).format("DD-MMMM-YY"), y: specificGeriatric },
          ];
        }
        if (neuro) {
          neuroGraphData1.data = [
            ...neuroGraphData1.data,
            { x: moment(createdAt).format("DD-MMMM-YY"), y: neuro },
          ];
        }
        if (chestExpansion) {
          chestGraph1.data = [
            ...chestGraph1.data,
            { x: moment(createdAt).format("DD-MMMM-YY"), y: chestExpansion },
          ];
        }

        if (functionalAssessment) {
          functionalGraph1.data = [
            ...functionalGraph1.data,
            {
              x: moment(createdAt).format("DD-MMMM-YY"),
              y: functionalAssessment,
            },
          ];
        }
        if (touch) {
          touchGraph1.data = [
            ...touchGraph1.data,
            { x: moment(createdAt).format("DD-MMMM-YY"), y: touch },
          ];
        }
        if (pain) {
          painGraph1.data = [
            ...painGraph1.data,
            { x: moment(createdAt).format("DD-MMMM-YY"), y: pain },
          ];
        }
        if (pressure) {
          pressureGraph1.data = [
            ...pressureGraph1.data,
            { x: moment(createdAt).format("DD-MMMM-YY"), y: pressure },
          ];
        }
        if (temperature) {
          tempGraph1.data = [
            ...tempGraph1.data,
            { x: moment(createdAt).format("DD-MMMM-YY"), y: temperature },
          ];
        }
        upperRom.data = [
          ...upperRom.data,
          {
            x: moment(createdAt).format("DD-MMMM-YY"),
            y: Number.parseFloat(romArray ? romArray : 0),
          },
        ];
        // painPerception1.data = [
        //     ...painPerception1.data,
        //     { x: moment(createdAt).format("DD-MMMM-YY"), y: Number.parseFloat(painPerception) },
        // ];
        // difficulty1.data = [
        //     ...difficulty1.data,
        //     { x: moment(createdAt).format("DD-MMMM-YY"), y: Number.parseFloat(difficulty) },
        // ];

        return { createdAt: moment(createdAt).format("DD-MMMM-YY") };
      },
      { createdAt: moment(new Date()).format("DD-MMMM-YY") }
    );
    const rom = [lowerRom, sessionRom];
    const chestGraphFinal = [chestGraph1];
    return [
      rom,
      [painPerception1],
      [difficulty1],
      [assessedPain1],
      [muscleT1],
      [muscleST1],
      [rimData1],
      [mltData1],
      [limbData1],
      [sgGraphData1],
      [neuroGraphData1],
      chestGraphFinal,
      [functionalGraph1],
      [touchGraph1],
      [painGraph1],
      [tempGraph1],
      [pressureGraph1],
    ];
  }, [location.state.sessions]);
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const margin = isSmallScreen
    ? { top: 20, bottom: 50, left: 40 }
    : { top: 20, right: 50, bottom: 50, left: 70 };
  return (
    <div className='m-auto w-full flex flex-col items-center'>
      <div className='flex w-full justify-around gap-2'>
        <Button
          onClick={() => {
            navigate(-1);
          }}
          variant='standard'
          startIcon={<ArrowBack />}>
          Back
        </Button>

        <Button
          onClick={() => {
            _exportPdf();
          }}
          variant='contained'
          endIcon={<Download />}>
          Download
        </Button>
      </div>
      <div
        id='pdf-div'
        style={{ width: "800px" }}
        className='bg-gray-100 m-4 items-center flex flex-col text-gray-600'>
        {/* Header */}
        <div className=' p-4 '>
          <img src={logofull} className='h-12' />
          <span className='text-lg mt-2 font-bold items-center flex flex-col flex-1'>
            Patient Report
          </span>
        </div>
        <hr className='border-1 border-dashed border-gray-500 w-full' />
        <div className='bg-blue-300 flex gap-2 items-center justify-between w-full p-2'>
          <div className='flex items-center gap-2'>
            <span className='text-xs mt-2 items-center'>Referred By: </span>
            <span className='text-xs font-bold mt-2 items-center'>
              {location.state.refer}
            </span>
          </div>
          <div className='flex items-center gap-2'>
            <span className='text-xs mt-2 items-center'>Generated Date: </span>
            <span className='text-xs font-bold mt-2 items-center'>
              {moment(new Date()).format("DD MMMM YYYY")}
            </span>
          </div>
        </div>
        <hr className='border-1 border-dashed border-gray-500 w-full' />
        <div className='bg-blue-200 w-full'>
          {/* Details */}
          <div className='m-2 gap-2  flex w-full justify-evenly items-center'>
            {/* Patient */}
            <div className='flex-1 text-start justify-center border-dashed border-gray-500 flex flex-col'>
              <span className='text-sm mt-2 font-bold items-center'>
                Patient
              </span>
              <div className='flex gap-2 items-center'>
                <span className='text-xs mt-2 items-center'>Name: </span>
                <span className='text-xs font-bold mt-2 items-center'>
                  {userData.name}
                </span>
              </div>
              <div className='flex gap-2 items-center'>
                <span className='text-xs mt-2 items-center'>Age: </span>
                <span className='text-xs font-bold mt-2 items-center'>
                  {userData.age} years old
                </span>
              </div>
              <div className='flex gap-2 items-center'>
                <span className='text-xs mt-2 items-center'>Gender: </span>
                <span className='text-xs font-bold mt-2 items-center'>
                  {userData.gender.toUpperCase()}
                </span>
              </div>
              <div className='flex gap-2 items-center'>
                <span className='text-xs mt-2 items-center'>Mobile: </span>
                <span className='text-xs font-bold mt-2 items-center'>
                  +{location.state.userPhone.trim()}
                </span>
              </div>
            </div>

            <div className='w-4 h-40 border-dashed border-r border-gray-700' />

            {/* Pateint Details */}
            <div className='flex-1 text-start border-gray-500'>
              <span className='text-sm mt-2 font-bold'>Details</span>
              <div className='flex gap-2 items-center'>
                <span className='text-xs mt-2 items-center'>Height: </span>
                <span className='text-xs font-bold mt-2 items-center'>
                  {userData.height} {userData.heightMeasure}
                </span>
              </div>
              <div className='flex gap-2 items-center'>
                <span className='text-xs mt-2 items-center'>Weight: </span>
                <span className='text-xs font-bold mt-2 items-center'>
                  {userData.weight} {userData.weightMeasure}
                </span>
              </div>
              <div className='flex gap-2 items-center'>
                <span className='text-xs mt-2 items-center'>BMI: </span>
                <span className='text-xs font-bold mt-2 items-center'>
                  {userData.weightMeasure === "kg"
                    ? userData.heightMeasure === "cm"
                      ? (
                          userData.weight /
                          ((userData.height / 100) * (userData.height / 100))
                        ).toFixed(2)
                      : (userData.weight / (userData.height * 0.3048)).toFixed(
                          2
                        )
                    : userData.heightMeasure === "cm"
                    ? (
                        (userData.weight * 0.45359237) /
                        ((userData.height / 100) * (userData.height / 100))
                      ).toFixed(2)
                    : (
                        (userData.weight * 0.45359237) /
                        (userData.height * 0.3048)
                      ).toFixed(2)}
                </span>
              </div>
              <div className='flex gap-2 items-center'>
                <span className='text-xs mt-2 items-center'>Lifestyle: </span>
                <span className='text-xs font-bold mt-2 items-center'>
                  {userData.natureOfWork}
                </span>
              </div>
            </div>

            <div className='w-4 h-40 border-dashed border-r border-gray-700' />

            {/* Medical History */}
            <div className='flex-1 text-start border-gray-500'>
              <span className='text-sm mt-2 font-bold'>Medical History</span>
              <div className='flex gap-2 items-center'>
                <span className='text-xs mt-2 items-center'>
                  Comorbidities:{" "}
                </span>
                <span className='text-xs font-bold mt-2 items-center'>
                  {userData.comorbidities && userData.comorbidities.length > 0
                    ? userData.comorbidities.join(",")
                    : "- -"}
                </span>
              </div>
              <div className='flex gap-2 items-center'>
                <span className='text-xs mt-2 items-center'>BP: </span>
                <span className='text-xs font-bold mt-2 items-center'>
                  {userData.bp ? userData.bp : "- -"}
                </span>
              </div>
              <div className='flex gap-2 items-center'>
                <span className='text-xs mt-2 items-center'>Heart Rate: </span>
                <span className='text-xs font-bold mt-2 items-center'>
                  {userData.heartRate ? userData.heartRate : "- -"}
                </span>
              </div>
              <div className='flex gap-2 items-center'>
                <span className='text-xs mt-2 items-center'>Pulse Rate: </span>
                <span className='text-xs font-bold mt-2 items-center'>
                  {userData.pulse ? userData.pulse : "- -"}
                </span>
              </div>
            </div>
          </div>
          <hr className='border-1 border-dashed border-gray-500 w-full' />

          {/* Plan Details */}
          {subscription && (
            <div className=''>
              <span className='text-sm mt-2 font-bold items-center flex flex-col flex-1'>
                Plan Details
              </span>
              <div className='flex mt-4 w-full justify-evenly items-center'>
                <div className=' text-center border-gray-500'>
                  <span className='text-sm mt-2'>Name</span>
                  <div className='flex gap-2 items-center'>
                    <span className='text-sm font-bold mt-2 items-center'>
                      {subscription.plan.name}
                    </span>
                  </div>
                </div>

                <div className=' text-center border-gray-500'>
                  <span className='text-sm mt-2'>Starts At</span>
                  <div className='flex gap-2 items-center'>
                    <span className='text-sm font-bold mt-2 items-center'>
                      {moment(subscription.startsAt).format("DD MMMM YYYY")}
                    </span>
                  </div>
                </div>

                <div className=' text-center border-gray-500'>
                  <span className='text-sm mt-2'>Ends At</span>
                  <div className='flex gap-2 items-center'>
                    <span className='text-sm font-bold mt-2 items-center'>
                      {moment(subscription.endsAt).format("DD MMMM YYYY")}
                    </span>
                  </div>
                </div>

                <div className=' text-center border-gray-500'>
                  <span className='text-sm mt-2'>Days</span>
                  <div className='flex gap-2 items-center'>
                    <span className='text-sm font-bold mt-2 items-center'>
                      {subscription.days} Days
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* ROM */}

          {beforeData.rom && (
            <div className=''>
              <hr className='border-1 border-dashed mt-2 border-gray-500 w-full' />
              <div className='text-center p-2'>
                <span className='text-sm mt-2 font-bold'>
                  Range Of Movement (ROM)
                </span>
                <div className='flex justify-between items-top mt-2'>
                  <div className='h-48 w-full bg-blue-400'>
                    {rom && (
                      <ResponsiveLine
                        data={rom}
                        theme={{
                          axis: {
                            domain: {
                              line: {
                                stroke: theme.palette.secondary[500],
                              },
                            },
                            legend: {
                              text: {
                                fill: theme.palette.secondary[500],
                              },
                            },
                            ticks: {
                              line: {
                                stroke: theme.palette.secondary[500],
                                strokeWidth: 1,
                              },
                              text: {
                                fill: theme.palette.secondary[200],
                              },
                            },
                          },
                          legends: {
                            text: {
                              fill: theme.palette.secondary[200],
                              transform: "translateX(-10px)",
                            },
                          },
                          tooltip: {
                            container: {
                              color: theme.palette.primary.main,
                            },
                          },
                        }}
                        margin={margin}
                        xScale={{ type: "point" }}
                        yScale={{
                          type: "linear",
                          min: "auto",
                          max: "auto",
                          stacked: false,
                          reverse: false,
                        }}
                        yFormat=' >-.2f'
                        curve='catmullRom'
                        enableArea={true}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                          format: (v) => {
                            return v;
                          },
                          orient: "bottom",
                          tickSize: 5,
                          tickPadding: 5,

                          tickRotation: -10,
                          legend: "Month",
                          legendOffset: 36,
                          legendPosition: "middle",
                        }}
                        axisLeft={{
                          orient: "left",
                          tickValues: 5,
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: `Range Of Movement (ROM)`,
                          legendOffset: -60,
                          legendPosition: "middle",
                        }}
                        enableGridX={false}
                        enableGridY={false}
                        pointSize={10}
                        pointColor={{ theme: "background" }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: "serieColor" }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        // legends={

                        //     [
                        //         {
                        //             anchor: "top-right",
                        //             direction: "column",
                        //             justify: false,
                        //             translateX: 30,
                        //             translateY: 0,
                        //             itemsSpacing: 10,
                        //             itemDirection: "left-to-right",
                        //             itemWidth: 120,
                        //             itemHeight: 15,
                        //             itemOpacity: 0.75,
                        //             symbolSpacing:15,
                        //             symbolSize: 12,
                        //             symbolShape: "circle",
                        //             symbolBorderColor: "rgba(0, 0, 0, .5)",
                        //             effects: [
                        //                 {
                        //                     on: "hover",
                        //                     style: {
                        //                         itemBackground: "rgba(0, 0, 0, .03)",
                        //                         itemOpacity: 1,
                        //                     },
                        //                 },
                        //             ],
                        //         },
                        //     ]

                        // }
                      />
                    )}
                  </div>
                  <div className='p-4'>
                    <div className='flex items-center gap-2'>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>Before</span>
                        <span className='text-sm font-semibold'>
                          {beforeData.rom} deg
                        </span>
                      </div>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>After</span>
                        <span className='text-sm font-semibold'>
                          {afterData.rom} deg
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Assessed Pain Scaled */}

          {beforeData.assessedPainScale && (
            <div className=''>
              <hr className='border-1 border-dashed mt-2 border-gray-500 w-full' />
              <div className='text-center p-2'>
                <span className='text-sm mt-2 font-bold'>
                  Assessed Pain Scale
                </span>
                <div className='flex justify-between items-top mt-2'>
                  <div className='h-48 w-full bg-blue-400'>
                    {assessedPain && (
                      <ResponsiveLine
                        data={assessedPain}
                        theme={{
                          axis: {
                            domain: {
                              line: {
                                stroke: theme.palette.secondary[500],
                              },
                            },
                            legend: {
                              text: {
                                fill: theme.palette.secondary[500],
                              },
                            },
                            ticks: {
                              line: {
                                stroke: theme.palette.secondary[500],
                                strokeWidth: 1,
                              },
                              text: {
                                fill: theme.palette.secondary[200],
                              },
                            },
                          },
                          legends: {
                            text: {
                              fill: theme.palette.secondary[200],
                              transform: "translateX(-10px)",
                            },
                          },
                          tooltip: {
                            container: {
                              color: theme.palette.primary.main,
                            },
                          },
                        }}
                        margin={margin}
                        xScale={{ type: "point" }}
                        yScale={{
                          type: "linear",
                          min: "auto",
                          max: "auto",
                          stacked: false,
                          reverse: false,
                        }}
                        yFormat=' >-.2f'
                        curve='catmullRom'
                        enableArea={true}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                          format: (v) => {
                            return v;
                          },
                          orient: "bottom",
                          tickSize: 5,
                          tickPadding: 5,

                          tickRotation: -10,
                          legend: "Month",
                          legendOffset: 36,
                          legendPosition: "middle",
                        }}
                        axisLeft={{
                          orient: "left",
                          tickValues: 5,
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: `Assessed Pain Scale`,
                          legendOffset: -60,
                          legendPosition: "middle",
                        }}
                        enableGridX={false}
                        enableGridY={false}
                        pointSize={10}
                        pointColor={{ theme: "background" }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: "serieColor" }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        // legends={

                        //     [
                        //         {
                        //             anchor: "top-right",
                        //             direction: "column",
                        //             justify: false,
                        //             translateX: 30,
                        //             translateY: 0,
                        //             itemsSpacing: 10,
                        //             itemDirection: "left-to-right",
                        //             itemWidth: 120,
                        //             itemHeight: 15,
                        //             itemOpacity: 0.75,
                        //             symbolSpacing:15,
                        //             symbolSize: 12,
                        //             symbolShape: "circle",
                        //             symbolBorderColor: "rgba(0, 0, 0, .5)",
                        //             effects: [
                        //                 {
                        //                     on: "hover",
                        //                     style: {
                        //                         itemBackground: "rgba(0, 0, 0, .03)",
                        //                         itemOpacity: 1,
                        //                     },
                        //                 },
                        //             ],
                        //         },
                        //     ]

                        // }
                      />
                    )}
                  </div>
                  <div className='p-4'>
                    <div className='flex items-center gap-2'>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>Before</span>
                        <span className='text-sm font-semibold'>
                          {beforeData.assessedPainScale}/10
                        </span>
                      </div>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>After</span>
                        <span className='text-sm font-semibold'>
                          {afterData.assessedPainScale}/10
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Muscle Tone */}

          {beforeData.muscleTone && (
            <div className=''>
              <hr className='border-1 border-dashed mt-2 border-gray-500 w-full' />
              <div className='text-center p-2'>
                <span className='text-sm mt-2 font-bold'>Muscle Tone</span>
                <div className='flex justify-between items-top mt-2'>
                  <div className='h-48 w-full bg-blue-400'>
                    {muscleT && (
                      <ResponsiveLine
                        data={muscleT}
                        theme={{
                          axis: {
                            domain: {
                              line: {
                                stroke: theme.palette.secondary[500],
                              },
                            },
                            legend: {
                              text: {
                                fill: theme.palette.secondary[500],
                              },
                            },
                            ticks: {
                              line: {
                                stroke: theme.palette.secondary[500],
                                strokeWidth: 1,
                              },
                              text: {
                                fill: theme.palette.secondary[200],
                              },
                            },
                          },
                          legends: {
                            text: {
                              fill: theme.palette.secondary[200],
                              transform: "translateX(-10px)",
                            },
                          },
                          tooltip: {
                            container: {
                              color: theme.palette.primary.main,
                            },
                          },
                        }}
                        margin={margin}
                        xScale={{ type: "point" }}
                        yScale={{
                          type: "linear",
                          min: "auto",
                          max: "auto",
                          stacked: false,
                          reverse: false,
                        }}
                        yFormat=' >-.2f'
                        curve='catmullRom'
                        enableArea={true}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                          format: (v) => {
                            return v;
                          },
                          orient: "bottom",
                          tickSize: 5,
                          tickPadding: 5,

                          tickRotation: -10,
                          legend: "Month",
                          legendOffset: 36,
                          legendPosition: "middle",
                        }}
                        axisLeft={{
                          orient: "left",
                          tickValues: 5,
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: `Muscle Tone`,
                          legendOffset: -60,
                          legendPosition: "middle",
                        }}
                        enableGridX={false}
                        enableGridY={false}
                        pointSize={10}
                        pointColor={{ theme: "background" }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: "serieColor" }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        // legends={

                        //     [
                        //         {
                        //             anchor: "top-right",
                        //             direction: "column",
                        //             justify: false,
                        //             translateX: 30,
                        //             translateY: 0,
                        //             itemsSpacing: 10,
                        //             itemDirection: "left-to-right",
                        //             itemWidth: 120,
                        //             itemHeight: 15,
                        //             itemOpacity: 0.75,
                        //             symbolSpacing:15,
                        //             symbolSize: 12,
                        //             symbolShape: "circle",
                        //             symbolBorderColor: "rgba(0, 0, 0, .5)",
                        //             effects: [
                        //                 {
                        //                     on: "hover",
                        //                     style: {
                        //                         itemBackground: "rgba(0, 0, 0, .03)",
                        //                         itemOpacity: 1,
                        //                     },
                        //                 },
                        //             ],
                        //         },
                        //     ]

                        // }
                      />
                    )}
                  </div>
                  <div className='p-4'>
                    <div className='flex items-center gap-2'>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>Before</span>
                        <span className='text-sm font-semibold'>
                          {beforeData.muscleTone}
                        </span>
                      </div>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>After</span>
                        <span className='text-sm font-semibold'>
                          {afterData.muscleTone}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Muscle Strength Testing */}
          {beforeData.muscleStrengthTesting && (
            <div className=''>
              <hr className='border-1 border-dashed mt-2 border-gray-500 w-full' />
              <div className='text-center p-2'>
                <span className='text-sm mt-2 font-bold'>
                  Muscle Strength Testing
                </span>
                <div className='flex justify-between items-top mt-2'>
                  <div className='h-48 w-full bg-blue-400'>
                    {muscleST && (
                      <ResponsiveLine
                        data={muscleST}
                        theme={{
                          axis: {
                            domain: {
                              line: {
                                stroke: theme.palette.secondary[500],
                              },
                            },
                            legend: {
                              text: {
                                fill: theme.palette.secondary[500],
                              },
                            },
                            ticks: {
                              line: {
                                stroke: theme.palette.secondary[500],
                                strokeWidth: 1,
                              },
                              text: {
                                fill: theme.palette.secondary[200],
                              },
                            },
                          },
                          legends: {
                            text: {
                              fill: theme.palette.secondary[200],
                              transform: "translateX(-10px)",
                            },
                          },
                          tooltip: {
                            container: {
                              color: theme.palette.primary.main,
                            },
                          },
                        }}
                        margin={margin}
                        xScale={{ type: "point" }}
                        yScale={{
                          type: "linear",
                          min: "auto",
                          max: "auto",
                          stacked: false,
                          reverse: false,
                        }}
                        yFormat=' >-.2f'
                        curve='catmullRom'
                        enableArea={true}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                          format: (v) => {
                            return v;
                          },
                          orient: "bottom",
                          tickSize: 5,
                          tickPadding: 5,

                          tickRotation: -10,
                          legend: "Month",
                          legendOffset: 36,
                          legendPosition: "middle",
                        }}
                        axisLeft={{
                          orient: "left",
                          tickValues: 5,
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: `Muscle Strength Testing`,
                          legendOffset: -60,
                          legendPosition: "middle",
                        }}
                        enableGridX={false}
                        enableGridY={false}
                        pointSize={10}
                        pointColor={{ theme: "background" }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: "serieColor" }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                      />
                    )}
                  </div>
                  <div className='p-4'>
                    <div className='flex items-center gap-2'>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>Before</span>
                        <span className='text-sm font-semibold'>
                          {beforeData.muscleStrengthTesting}/5
                        </span>
                      </div>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>After</span>
                        <span className='text-sm font-semibold'>
                          {afterData.muscleStrengthTesting}/5
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Relative Isometric Movement */}
          {beforeData.relativeIsometricMovement && (
            <div className=''>
              <hr className='border-1 border-dashed mt-2 border-gray-500 w-full' />
              <div className='text-center p-2'>
                <span className='text-sm mt-2 font-bold'>
                  Relative Isometric Movement
                </span>
                <div className='flex gap-2 justify-between items-top mt-2'>
                  <div className='w-full bg-blue-400'>
                    <div className='h-48 '>
                      {rimData && (
                        <ResponsiveLine
                          data={rimData}
                          theme={{
                            axis: {
                              domain: {
                                line: {
                                  stroke: theme.palette.secondary[500],
                                },
                              },
                              legend: {
                                text: {
                                  fill: theme.palette.secondary[500],
                                },
                              },
                              ticks: {
                                line: {
                                  stroke: theme.palette.secondary[500],
                                  strokeWidth: 1,
                                },
                                text: {
                                  fill: theme.palette.secondary[200],
                                },
                              },
                            },
                            legends: {
                              text: {
                                fill: theme.palette.secondary[200],
                                transform: "translateX(-10px)",
                              },
                            },
                            tooltip: {
                              container: {
                                color: theme.palette.primary.main,
                              },
                            },
                          }}
                          margin={margin}
                          yScale={{
                            type: "point",
                            min: "auto",
                            max: "auto",
                            stacked: false,
                            reverse: false,
                          }}
                          yFormat={(value) => `${value}`}
                          curve='catmullRom'
                          enableArea={true}
                          axisTop={null}
                          axisRight={null}
                          axisBottom={{
                            format: (v) => {
                              return v;
                            },
                            orient: "bottom",
                            tickSize: 5,
                            tickPadding: 5,

                            tickRotation: -15,
                            legend: "Month",
                            legendOffset: 36,
                            legendPosition: "middle",
                          }}
                          axisLeft={{
                            format: (v) => {
                              return v === "Strong and Painful"
                                ? "S.Pl"
                                : v === "Strong and Painfree"
                                ? "S.Pr"
                                : v === "Weak and Painful"
                                ? "W.Pl"
                                : v === "Weak and Painfree"
                                ? "W.Pr"
                                : v === "Girth Equal (on both the sides)"
                                ? "G.E"
                                : v === "Girth Unequal (on both the sides)"
                                ? "G.U"
                                : v;
                            },
                            orient: "left",
                            tickValues: 5,
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: `Relative Isometric Movement`,
                            legendOffset: -60,
                            legendPosition: "middle",
                          }}
                          enableGridX={false}
                          enableGridY={false}
                          pointSize={10}
                          pointColor={{ theme: "background" }}
                          pointBorderWidth={2}
                          pointBorderColor={{ from: "serieColor" }}
                          pointLabelYOffset={-12}
                          useMesh={true}
                        />
                      )}
                    </div>
                    <div className='p-2 text-start font-semibold'>
                      <h5 className='text-sm'>S.Pl: Strong and Painful</h5>
                      <h5 className='text-sm'>S.Pr: Strong and Painfree</h5>
                      <h5 className='text-sm'>W.Pl: Weak and Painful</h5>
                      <h5 className='text-sm'>W.Pr: Strong and Painfree</h5>
                    </div>
                  </div>
                  <div className=''>
                    <div className='flex items-top gap-2'>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>Before</span>
                        <span className='text-sm font-semibold'>
                          {beforeData.relativeIsometricMovement}
                        </span>
                      </div>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>After</span>
                        <span className='text-sm font-semibold'>
                          {afterData.relativeIsometricMovement}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Muscle Length Testing */}
          {beforeData.muscleLengthTesting && (
            <div className=''>
              <hr className='border-1 border-dashed mt-2 border-gray-500 w-full' />
              <div className='text-center p-2'>
                <span className='text-sm mt-2 font-bold'>
                  Muscle Length Testing
                </span>
                <div className='flex justify-between items-top gap-2 mt-2'>
                  <div className='w-full bg-blue-400'>
                    <div className='h-48 '>
                      {mltData && (
                        <ResponsiveLine
                          data={mltData}
                          theme={{
                            axis: {
                              domain: {
                                line: {
                                  stroke: theme.palette.secondary[500],
                                },
                              },
                              legend: {
                                text: {
                                  fill: theme.palette.secondary[500],
                                },
                              },
                              ticks: {
                                line: {
                                  stroke: theme.palette.secondary[500],
                                  strokeWidth: 1,
                                },
                                text: {
                                  fill: theme.palette.secondary[200],
                                },
                              },
                            },
                            legends: {
                              text: {
                                fill: theme.palette.secondary[200],
                                transform: "translateX(-10px)",
                              },
                            },
                            tooltip: {
                              container: {
                                color: theme.palette.primary.main,
                              },
                            },
                          }}
                          margin={margin}
                          yScale={{
                            type: "point",
                            min: "auto",
                            max: "auto",
                            stacked: false,
                            reverse: false,
                          }}
                          yFormat={(value) => `${value}`}
                          curve='catmullRom'
                          enableArea={true}
                          axisTop={null}
                          axisRight={null}
                          axisBottom={{
                            format: (v) => {
                              return v;
                            },
                            orient: "bottom",
                            tickSize: 5,
                            tickPadding: 5,

                            tickRotation: -15,
                            legend: "Month",
                            legendOffset: 36,
                            legendPosition: "middle",
                          }}
                          axisLeft={{
                            format: (v) => {
                              return v === "Strong and Painful"
                                ? "S.Pl"
                                : v === "Strong and Painfree"
                                ? "S.Pr"
                                : v === "Weak and Painful"
                                ? "W.Pl"
                                : v === "Weak and Painfree"
                                ? "W.Pr"
                                : v === "Girth Equal (on both the sides)"
                                ? "G.E"
                                : v === "Girth Unequal (on both the sides)"
                                ? "G.U"
                                : v;
                            },
                            orient: "left",
                            tickValues: 5,
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: `Muscle Length Testing`,
                            legendOffset: -60,
                            legendPosition: "middle",
                          }}
                          enableGridX={false}
                          enableGridY={false}
                          pointSize={10}
                          pointColor={{ theme: "background" }}
                          pointBorderWidth={2}
                          pointBorderColor={{ from: "serieColor" }}
                          pointLabelYOffset={-12}
                          useMesh={true}
                        />
                      )}
                    </div>
                    <div className='p-2 text-start font-semibold'>
                      <h5 className='text-sm'>N.P: Tightness Not Present</h5>
                      <h5 className='text-sm'>T.P: Tightness Present</h5>
                    </div>
                  </div>
                  <div className=''>
                    <div className='flex items-center gap-2'>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>Before</span>
                        <span className='text-sm font-semibold'>
                          {beforeData.muscleLengthTesting}
                        </span>
                      </div>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>After</span>
                        <span className='text-sm font-semibold'>
                          {afterData.muscleLengthTesting}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Limb Girth Measurement */}

          {beforeData.limbGirthMeasurement && (
            <div className=''>
              <hr className='border-1 border-dashed mt-2 border-gray-500 w-full' />
              <div className='text-center p-2'>
                <span className='text-sm mt-2 font-bold'>
                  Limb Girth Measurement
                </span>
                <div className='flex justify-between items-top gap-2 mt-2'>
                  <div className=' w-full bg-blue-400'>
                    <div className='h-48'>
                      {limbData && (
                        <ResponsiveLine
                          data={limbData}
                          theme={{
                            axis: {
                              domain: {
                                line: {
                                  stroke: theme.palette.secondary[500],
                                },
                              },
                              legend: {
                                text: {
                                  fill: theme.palette.secondary[500],
                                },
                              },
                              ticks: {
                                line: {
                                  stroke: theme.palette.secondary[500],
                                  strokeWidth: 1,
                                },
                                text: {
                                  fill: theme.palette.secondary[200],
                                },
                              },
                            },
                            legends: {
                              text: {
                                fill: theme.palette.secondary[200],
                                transform: "translateX(-10px)",
                              },
                            },
                            tooltip: {
                              container: {
                                color: theme.palette.primary.main,
                              },
                            },
                          }}
                          margin={margin}
                          yScale={{
                            type: "point",
                            min: "auto",
                            max: "auto",
                            stacked: false,
                            reverse: false,
                          }}
                          yFormat={(value) => `${value}`}
                          curve='catmullRom'
                          enableArea={true}
                          axisTop={null}
                          axisRight={null}
                          axisBottom={{
                            format: (v) => {
                              return v;
                            },
                            orient: "bottom",
                            tickSize: 5,
                            tickPadding: 5,

                            tickRotation: -15,
                            legend: "Month",
                            legendOffset: 36,
                            legendPosition: "middle",
                          }}
                          axisLeft={{
                            format: (v) => {
                              return v === "Strong and Painful"
                                ? "S.Pl"
                                : v === "Strong and Painfree"
                                ? "S.Pr"
                                : v === "Weak and Painful"
                                ? "W.Pl"
                                : v === "Weak and Painfree"
                                ? "W.Pr"
                                : v === "Girth Equal (on both the sides)"
                                ? "G.E"
                                : v === "Girth Unequal (on both the sides)"
                                ? "G.U"
                                : v;
                            },
                            orient: "left",
                            tickValues: 5,
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: `Limb Girth Measurement`,
                            legendOffset: -60,
                            legendPosition: "middle",
                          }}
                          enableGridX={false}
                          enableGridY={false}
                          pointSize={10}
                          pointColor={{ theme: "background" }}
                          pointBorderWidth={2}
                          pointBorderColor={{ from: "serieColor" }}
                          pointLabelYOffset={-12}
                          useMesh={true}
                        />
                      )}
                    </div>
                    <div className='p-2 text-start font-semibold'>
                      <h5 className='text-sm'>G.U: Girth Unequal</h5>
                      <h5 className='text-sm'>G.E: Girth Equal</h5>
                    </div>
                  </div>
                  <div className=''>
                    <div className='flex flex-col items-top gap-2'>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>Before</span>
                        <span className='text-sm font-semibold'>
                          {beforeData.limbGirthMeasurement}
                        </span>
                      </div>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>After</span>
                        <span className='text-sm font-semibold'>
                          {afterData.limbGirthMeasurement}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Specific Geriatric */}

          {beforeData.specificGeriatric && (
            <div className=''>
              <hr className='border-1 border-dashed mt-2 border-gray-500 w-full' />
              <div className='text-center p-2'>
                <span className='text-sm mt-2 font-bold'>
                  Specific Geriatric
                </span>
                <div className='flex justify-between items-top mt-2'>
                  <div className='h-48 w-full bg-blue-400'>
                    {sgGraphData && (
                      <ResponsiveLine
                        data={sgGraphData}
                        theme={{
                          axis: {
                            domain: {
                              line: {
                                stroke: theme.palette.secondary[500],
                              },
                            },
                            legend: {
                              text: {
                                fill: theme.palette.secondary[500],
                              },
                            },
                            ticks: {
                              line: {
                                stroke: theme.palette.secondary[500],
                                strokeWidth: 1,
                              },
                              text: {
                                fill: theme.palette.secondary[200],
                              },
                            },
                          },
                          legends: {
                            text: {
                              fill: theme.palette.secondary[200],
                              transform: "translateX(-10px)",
                            },
                          },
                          tooltip: {
                            container: {
                              color: theme.palette.primary.main,
                            },
                          },
                        }}
                        margin={margin}
                        xScale={{ type: "point" }}
                        yScale={{
                          type: "linear",
                          min: "auto",
                          max: "auto",
                          stacked: false,
                          reverse: false,
                        }}
                        yFormat=' >-.2f'
                        curve='catmullRom'
                        enableArea={true}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                          format: (v) => {
                            return v;
                          },
                          orient: "bottom",
                          tickSize: 5,
                          tickPadding: 5,

                          tickRotation: -10,
                          legend: "Month",
                          legendOffset: 36,
                          legendPosition: "middle",
                        }}
                        axisLeft={{
                          orient: "left",
                          tickValues: 5,
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: `Specific Geriatric`,
                          legendOffset: -60,
                          legendPosition: "middle",
                        }}
                        enableGridX={false}
                        enableGridY={false}
                        pointSize={10}
                        pointColor={{ theme: "background" }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: "serieColor" }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                      />
                    )}
                  </div>
                  <div className='p-4'>
                    <div className='flex items-center gap-2'>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>Before</span>
                        <span className='text-sm font-semibold'>
                          {beforeData.specificGeriatric}/56
                        </span>
                      </div>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>After</span>
                        <span className='text-sm font-semibold'>
                          {afterData.specificGeriatric}/56
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Neuro */}

          {beforeData.neuro && (
            <div className=''>
              <hr className='border-1 border-dashed mt-2 border-gray-500 w-full' />
              <div className='text-center p-2'>
                <span className='text-sm mt-2 font-bold'>Neuro</span>
                <div className='flex justify-between items-top mt-2'>
                  <div className='h-48 w-full bg-blue-400'>
                    {neuroGraphData && (
                      <ResponsiveLine
                        data={neuroGraphData}
                        theme={{
                          axis: {
                            domain: {
                              line: {
                                stroke: theme.palette.secondary[500],
                              },
                            },
                            legend: {
                              text: {
                                fill: theme.palette.secondary[500],
                              },
                            },
                            ticks: {
                              line: {
                                stroke: theme.palette.secondary[500],
                                strokeWidth: 1,
                              },
                              text: {
                                fill: theme.palette.secondary[200],
                              },
                            },
                          },
                          legends: {
                            text: {
                              fill: theme.palette.secondary[200],
                              transform: "translateX(-10px)",
                            },
                          },
                          tooltip: {
                            container: {
                              color: theme.palette.primary.main,
                            },
                          },
                        }}
                        margin={margin}
                        xScale={{ type: "point" }}
                        yScale={{
                          type: "linear",
                          min: "auto",
                          max: "auto",
                          stacked: false,
                          reverse: false,
                        }}
                        yFormat=' >-.2f'
                        curve='catmullRom'
                        enableArea={true}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                          format: (v) => {
                            return v;
                          },
                          orient: "bottom",
                          tickSize: 5,
                          tickPadding: 5,

                          tickRotation: -10,
                          legend: "Month",
                          legendOffset: 36,
                          legendPosition: "middle",
                        }}
                        axisLeft={{
                          orient: "left",
                          tickValues: 5,
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: `Neuro`,
                          legendOffset: -60,
                          legendPosition: "middle",
                        }}
                        enableGridX={false}
                        enableGridY={false}
                        pointSize={10}
                        pointColor={{ theme: "background" }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: "serieColor" }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                      />
                    )}
                  </div>
                  <div className='p-4'>
                    <div className='flex items-center gap-2'>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>Before</span>
                        <span className='text-sm font-semibold'>
                          {beforeData.neuro}/56
                        </span>
                      </div>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>After</span>
                        <span className='text-sm font-semibold'>
                          {afterData.neuro}/56
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Chest */}
          {beforeData.chestExpansion && (
            <div className=''>
              <hr className='border-1 border-dashed mt-2 border-gray-500 w-full' />
              <div className='text-center p-2'>
                <span className='text-sm mt-2 font-bold'>
                  Chest Expansion Measurement
                </span>
                <div className='flex justify-between items-top gap-2 mt-2'>
                  <div className='h-48 w-full bg-blue-400'>
                    {chestGraph && (
                      <ResponsiveLine
                        data={chestGraph}
                        theme={{
                          axis: {
                            domain: {
                              line: {
                                stroke: theme.palette.secondary[500],
                              },
                            },
                            legend: {
                              text: {
                                fill: theme.palette.secondary[500],
                              },
                            },
                            ticks: {
                              line: {
                                stroke: theme.palette.secondary[500],
                                strokeWidth: 1,
                              },
                              text: {
                                fill: theme.palette.secondary[200],
                              },
                            },
                          },
                          legends: {
                            text: {
                              fill: theme.palette.secondary[200],
                              transform: "translateX(-10px)",
                            },
                          },
                          tooltip: {
                            container: {
                              color: theme.palette.primary.main,
                            },
                          },
                        }}
                        margin={margin}
                        yScale={{
                          type: "linear",
                          min: "auto",
                          max: "auto",
                          stacked: false,
                          reverse: false,
                        }}
                        yFormat={(value) => `${value}`}
                        curve='catmullRom'
                        enableArea={true}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                          format: (v) => {
                            return v;
                          },
                          orient: "bottom",
                          tickSize: 5,
                          tickPadding: 5,

                          tickRotation: -15,
                          legend: "Month",
                          legendOffset: 36,
                          legendPosition: "middle",
                        }}
                        axisLeft={{
                          format: (v) => {
                            return v;
                          },
                          orient: "left",
                          tickValues: 5,
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: `Chest Expansion`,
                          legendOffset: -60,
                          legendPosition: "middle",
                        }}
                        enableGridX={false}
                        enableGridY={false}
                        pointSize={10}
                        pointColor={{ theme: "background" }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: "serieColor" }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                      />
                    )}
                  </div>
                  <div className=''>
                    <div className='flex items-center gap-2'>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>Before</span>
                        <span className='text-sm font-semibold'>
                          {beforeData.chestExpansion}
                        </span>
                      </div>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>After</span>
                        <span className='text-sm font-semibold'>
                          {afterData.chestExpansion}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Functional Assessment */}

          {beforeData.functionalAssessment && (
            <div className=''>
              <hr className='border-1 border-dashed mt-2 border-gray-500 w-full' />
              <div className='text-center p-2'>
                <span className='text-sm mt-2 font-bold'>
                  Functional Assessment
                </span>
                <div className='flex justify-between items-top gap-2 mt-2'>
                  <div className=' w-full bg-blue-400'>
                    <div className='h-48'>
                      {functionalGraph && (
                        <ResponsiveLine
                          data={functionalGraph}
                          theme={{
                            axis: {
                              domain: {
                                line: {
                                  stroke: theme.palette.secondary[500],
                                },
                              },
                              legend: {
                                text: {
                                  fill: theme.palette.secondary[500],
                                },
                              },
                              ticks: {
                                line: {
                                  stroke: theme.palette.secondary[500],
                                  strokeWidth: 1,
                                },
                                text: {
                                  fill: theme.palette.secondary[200],
                                },
                              },
                            },
                            legends: {
                              text: {
                                fill: theme.palette.secondary[200],
                                transform: "translateX(-10px)",
                              },
                            },
                            tooltip: {
                              container: {
                                color: theme.palette.primary.main,
                              },
                            },
                          }}
                          margin={margin}
                          yScale={{
                            type: "point",
                            min: "auto",
                            max: "auto",
                            stacked: false,
                            reverse: false,
                          }}
                          yFormat={(value) => `${value}`}
                          curve='catmullRom'
                          enableArea={true}
                          axisTop={null}
                          axisRight={null}
                          axisBottom={{
                            format: (v) => {
                              return v;
                            },
                            orient: "bottom",
                            tickSize: 5,
                            tickPadding: 5,

                            tickRotation: -15,
                            legend: "Month",
                            legendOffset: 36,
                            legendPosition: "middle",
                          }}
                          axisLeft={{
                            format: (v) => {
                              return v === "Functionally Dependent"
                                ? "F.D"
                                : "F.I";
                            },
                            orient: "left",
                            tickValues: 5,
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: `Functional Assessment`,
                            legendOffset: -60,
                            legendPosition: "middle",
                          }}
                          enableGridX={false}
                          enableGridY={false}
                          pointSize={10}
                          pointColor={{ theme: "background" }}
                          pointBorderWidth={2}
                          pointBorderColor={{ from: "serieColor" }}
                          pointLabelYOffset={-12}
                          useMesh={true}
                        />
                      )}
                    </div>
                    <div className='p-2 text-start font-semibold'>
                      <h5 className='text-sm'>F.D: Functionally Dependent</h5>
                      <h5 className='text-sm'>F.I: Functionally Independent</h5>
                    </div>
                  </div>
                  <div className=''>
                    <div className='flex flex-col items-center gap-2'>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>Before</span>
                        <span className='text-sm font-semibold'>
                          {beforeData.functionalAssessment}
                        </span>
                      </div>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>After</span>
                        <span className='text-sm font-semibold'>
                          {afterData.functionalAssessment}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Touch */}
          {beforeData.touch && (
            <div className=''>
              <hr className='border-1 border-dashed mt-2 border-gray-500 w-full' />
              <div className='text-center p-2'>
                <span className='text-sm mt-2 font-bold'>Touch</span>
                <div className='flex justify-between items-top mt-2'>
                  <div className='h-48 w-full bg-blue-400'>
                    {touchGraph && (
                      <ResponsiveLine
                        data={touchGraph}
                        theme={{
                          axis: {
                            domain: {
                              line: {
                                stroke: theme.palette.secondary[500],
                              },
                            },
                            legend: {
                              text: {
                                fill: theme.palette.secondary[500],
                              },
                            },
                            ticks: {
                              line: {
                                stroke: theme.palette.secondary[500],
                                strokeWidth: 1,
                              },
                              text: {
                                fill: theme.palette.secondary[200],
                              },
                            },
                          },
                          legends: {
                            text: {
                              fill: theme.palette.secondary[200],
                              transform: "translateX(-10px)",
                            },
                          },
                          tooltip: {
                            container: {
                              color: theme.palette.primary.main,
                            },
                          },
                        }}
                        margin={margin}
                        xScale={{ type: "point" }}
                        yScale={{
                          type: "point",
                          min: "auto",
                          max: "auto",
                          stacked: false,
                          reverse: false,
                        }}
                        yFormat={(v) => `${v}`}
                        curve='catmullRom'
                        enableArea={true}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                          format: (v) => {
                            return v;
                          },
                          orient: "bottom",
                          tickSize: 5,
                          tickPadding: 5,

                          tickRotation: -10,
                          legend: "Month",
                          legendOffset: 36,
                          legendPosition: "middle",
                        }}
                        axisLeft={{
                          orient: "left",
                          tickValues: 5,
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: `Touch`,
                          legendOffset: -60,
                          legendPosition: "middle",
                        }}
                        enableGridX={false}
                        enableGridY={false}
                        pointSize={10}
                        pointColor={{ theme: "background" }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: "serieColor" }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        // legends={

                        //     [
                        //         {
                        //             anchor: "top-right",
                        //             direction: "column",
                        //             justify: false,
                        //             translateX: 30,
                        //             translateY: 0,
                        //             itemsSpacing: 10,
                        //             itemDirection: "left-to-right",
                        //             itemWidth: 120,
                        //             itemHeight: 15,
                        //             itemOpacity: 0.75,
                        //             symbolSpacing:15,
                        //             symbolSize: 12,
                        //             symbolShape: "circle",
                        //             symbolBorderColor: "rgba(0, 0, 0, .5)",
                        //             effects: [
                        //                 {
                        //                     on: "hover",
                        //                     style: {
                        //                         itemBackground: "rgba(0, 0, 0, .03)",
                        //                         itemOpacity: 1,
                        //                     },
                        //                 },
                        //             ],
                        //         },
                        //     ]

                        // }
                      />
                    )}
                  </div>
                  <div className='p-4'>
                    <div className='flex items-center gap-2'>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>Before</span>
                        <span className='text-sm font-semibold'>
                          {beforeData.touch}
                        </span>
                      </div>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>After</span>
                        <span className='text-sm font-semibold'>
                          {afterData.touch}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Pain */}

          {beforeData.pain && (
            <div className=''>
              <hr className='border-1 border-dashed mt-2 border-gray-500 w-full' />
              <div className='text-center p-2'>
                <span className='text-sm mt-2 font-bold'>Pain</span>
                <div className='flex justify-between items-top mt-2'>
                  <div className='h-48 w-full bg-blue-400'>
                    {painGraph && (
                      <ResponsiveLine
                        data={painGraph}
                        theme={{
                          axis: {
                            domain: {
                              line: {
                                stroke: theme.palette.secondary[500],
                              },
                            },
                            legend: {
                              text: {
                                fill: theme.palette.secondary[500],
                              },
                            },
                            ticks: {
                              line: {
                                stroke: theme.palette.secondary[500],
                                strokeWidth: 1,
                              },
                              text: {
                                fill: theme.palette.secondary[200],
                              },
                            },
                          },
                          legends: {
                            text: {
                              fill: theme.palette.secondary[200],
                              transform: "translateX(-10px)",
                            },
                          },
                          tooltip: {
                            container: {
                              color: theme.palette.primary.main,
                            },
                          },
                        }}
                        margin={margin}
                        xScale={{ type: "point" }}
                        yScale={{
                          type: "point",
                          min: "auto",
                          max: "auto",
                          stacked: false,
                          reverse: false,
                        }}
                        yFormat={(v) => `${v}`}
                        curve='catmullRom'
                        enableArea={true}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                          format: (v) => {
                            return v;
                          },
                          orient: "bottom",
                          tickSize: 5,
                          tickPadding: 5,

                          tickRotation: -10,
                          legend: "Month",
                          legendOffset: 36,
                          legendPosition: "middle",
                        }}
                        axisLeft={{
                          orient: "left",
                          tickValues: 5,
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: `Pain`,
                          legendOffset: -60,
                          legendPosition: "middle",
                        }}
                        enableGridX={false}
                        enableGridY={false}
                        pointSize={10}
                        pointColor={{ theme: "background" }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: "serieColor" }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        // legends={

                        //     [
                        //         {
                        //             anchor: "top-right",
                        //             direction: "column",
                        //             justify: false,
                        //             translateX: 30,
                        //             translateY: 0,
                        //             itemsSpacing: 10,
                        //             itemDirection: "left-to-right",
                        //             itemWidth: 120,
                        //             itemHeight: 15,
                        //             itemOpacity: 0.75,
                        //             symbolSpacing:15,
                        //             symbolSize: 12,
                        //             symbolShape: "circle",
                        //             symbolBorderColor: "rgba(0, 0, 0, .5)",
                        //             effects: [
                        //                 {
                        //                     on: "hover",
                        //                     style: {
                        //                         itemBackground: "rgba(0, 0, 0, .03)",
                        //                         itemOpacity: 1,
                        //                     },
                        //                 },
                        //             ],
                        //         },
                        //     ]

                        // }
                      />
                    )}
                  </div>
                  <div className='p-4'>
                    <div className='flex items-center gap-2'>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>Before</span>
                        <span className='text-sm font-semibold'>
                          {beforeData.pain}
                        </span>
                      </div>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>After</span>
                        <span className='text-sm font-semibold'>
                          {afterData.pain}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Temperature */}

          {beforeData.temperature && (
            <div className=''>
              <hr className='border-1 border-dashed mt-2 border-gray-500 w-full' />
              <div className='text-center p-2'>
                <span className='text-sm mt-2 font-bold'>Temperature</span>
                <div className='flex justify-between items-top mt-2'>
                  <div className='h-48 w-full bg-blue-400'>
                    {tempGraph && (
                      <ResponsiveLine
                        data={tempGraph}
                        theme={{
                          axis: {
                            domain: {
                              line: {
                                stroke: theme.palette.secondary[500],
                              },
                            },
                            legend: {
                              text: {
                                fill: theme.palette.secondary[500],
                              },
                            },
                            ticks: {
                              line: {
                                stroke: theme.palette.secondary[500],
                                strokeWidth: 1,
                              },
                              text: {
                                fill: theme.palette.secondary[200],
                              },
                            },
                          },
                          legends: {
                            text: {
                              fill: theme.palette.secondary[200],
                              transform: "translateX(-10px)",
                            },
                          },
                          tooltip: {
                            container: {
                              color: theme.palette.primary.main,
                            },
                          },
                        }}
                        margin={margin}
                        xScale={{ type: "point" }}
                        yScale={{
                          type: "point",
                          min: "auto",
                          max: "auto",
                          stacked: false,
                          reverse: false,
                        }}
                        yFormat={(v) => `${v}`}
                        curve='catmullRom'
                        enableArea={true}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                          format: (v) => {
                            return v;
                          },
                          orient: "bottom",
                          tickSize: 5,
                          tickPadding: 5,

                          tickRotation: -10,
                          legend: "Month",
                          legendOffset: 36,
                          legendPosition: "middle",
                        }}
                        axisLeft={{
                          orient: "left",
                          tickValues: 5,
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: `Temperature`,
                          legendOffset: -60,
                          legendPosition: "middle",
                        }}
                        enableGridX={false}
                        enableGridY={false}
                        pointSize={10}
                        pointColor={{ theme: "background" }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: "serieColor" }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        // legends={

                        //     [
                        //         {
                        //             anchor: "top-right",
                        //             direction: "column",
                        //             justify: false,
                        //             translateX: 30,
                        //             translateY: 0,
                        //             itemsSpacing: 10,
                        //             itemDirection: "left-to-right",
                        //             itemWidth: 120,
                        //             itemHeight: 15,
                        //             itemOpacity: 0.75,
                        //             symbolSpacing:15,
                        //             symbolSize: 12,
                        //             symbolShape: "circle",
                        //             symbolBorderColor: "rgba(0, 0, 0, .5)",
                        //             effects: [
                        //                 {
                        //                     on: "hover",
                        //                     style: {
                        //                         itemBackground: "rgba(0, 0, 0, .03)",
                        //                         itemOpacity: 1,
                        //                     },
                        //                 },
                        //             ],
                        //         },
                        //     ]

                        // }
                      />
                    )}
                  </div>
                  <div className='p-4'>
                    <div className='flex items-center gap-2'>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>Before</span>
                        <span className='text-sm font-semibold'>
                          {beforeData.temperature}
                        </span>
                      </div>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>After</span>
                        <span className='text-sm font-semibold'>
                          {afterData.temperature}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Pressure */}

          {beforeData.pressure && (
            <div className=''>
              <hr className='border-1 border-dashed mt-2 border-gray-500 w-full' />
              <div className='text-center p-2'>
                <span className='text-sm mt-2 font-bold'>Pressure</span>
                <div className='flex justify-between items-top mt-2'>
                  <div className='h-48 w-full bg-blue-400'>
                    {pressureGraph && (
                      <ResponsiveLine
                        data={pressureGraph}
                        theme={{
                          axis: {
                            domain: {
                              line: {
                                stroke: theme.palette.secondary[500],
                              },
                            },
                            legend: {
                              text: {
                                fill: theme.palette.secondary[500],
                              },
                            },
                            ticks: {
                              line: {
                                stroke: theme.palette.secondary[500],
                                strokeWidth: 1,
                              },
                              text: {
                                fill: theme.palette.secondary[200],
                              },
                            },
                          },
                          legends: {
                            text: {
                              fill: theme.palette.secondary[200],
                              transform: "translateX(-10px)",
                            },
                          },
                          tooltip: {
                            container: {
                              color: theme.palette.primary.main,
                            },
                          },
                        }}
                        margin={margin}
                        xScale={{ type: "point" }}
                        yScale={{
                          type: "point",
                          min: "auto",
                          max: "auto",
                          stacked: false,
                          reverse: false,
                        }}
                        yFormat={(v) => `${v}`}
                        curve='catmullRom'
                        enableArea={true}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                          format: (v) => {
                            return v;
                          },
                          orient: "bottom",
                          tickSize: 5,
                          tickPadding: 5,

                          tickRotation: -10,
                          legend: "Month",
                          legendOffset: 36,
                          legendPosition: "middle",
                        }}
                        axisLeft={{
                          orient: "left",
                          tickValues: 5,
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: `Pressure`,
                          legendOffset: -60,
                          legendPosition: "middle",
                        }}
                        enableGridX={false}
                        enableGridY={false}
                        pointSize={10}
                        pointColor={{ theme: "background" }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: "serieColor" }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        // legends={

                        //     [
                        //         {
                        //             anchor: "top-right",
                        //             direction: "column",
                        //             justify: false,
                        //             translateX: 30,
                        //             translateY: 0,
                        //             itemsSpacing: 10,
                        //             itemDirection: "left-to-right",
                        //             itemWidth: 120,
                        //             itemHeight: 15,
                        //             itemOpacity: 0.75,
                        //             symbolSpacing:15,
                        //             symbolSize: 12,
                        //             symbolShape: "circle",
                        //             symbolBorderColor: "rgba(0, 0, 0, .5)",
                        //             effects: [
                        //                 {
                        //                     on: "hover",
                        //                     style: {
                        //                         itemBackground: "rgba(0, 0, 0, .03)",
                        //                         itemOpacity: 1,
                        //                     },
                        //                 },
                        //             ],
                        //         },
                        //     ]

                        // }
                      />
                    )}
                  </div>
                  <div className='p-4'>
                    <div className='flex items-center gap-2'>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>Before</span>
                        <span className='text-sm font-semibold'>
                          {beforeData.pressure}
                        </span>
                      </div>
                      <div className='flex flex-col p-4 bg-gray-300 rounded-sm'>
                        <span className='text-sm font-semibold'>After</span>
                        <span className='text-sm font-semibold'>
                          {afterData.pressure}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <hr className='border-1 border-dashed border-gray-500 mt-2 w-full' />
        </div>

        {/* Footer */}
        <div className=' p-4 '>
          <img src={logofull} className='h-8' />
        </div>
        <hr className='border-1 border-dashed border-gray-500 mt-2 w-full' />
      </div>
    </div>
  );
}
