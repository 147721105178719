import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  Rating,
  useTheme,
  useMediaQuery,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputBase,
  Fab,
  Tabs,
  Tab,
} from "@mui/material";
import {
  useCreateSubscriptionMutation,
  useGetPlansQuery,
  useGetProductsQuery,
} from "state/api";
import { useHistory, useOutletContext } from "react-router-dom";
import MergePlan from "scenes/custom/merge";
import Header from "components/Header";
import FlexBetween from "components/FlexBetween";
import { Paid, Search } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { color } from "@mui/system";
import AssignPlanConfirmation from "components/AssignPlanConfirmation";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Product = ({ plan, user, selectedPlans, setSelectedPlans }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState();
  const [createSubscription, response] = useCreateSubscriptionMutation();
  const [days, setDays] = useState(45);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  // const days = numberOfWeeks === 4 ? 30 : numberOfWeeks === 12 ? 90 : 365
  const _todayStartOfDay = moment().startOf("day").utc(false);
  const _startsAt = _todayStartOfDay.toDate();
  const _endsAt = moment(_todayStartOfDay)
    .add(days + 1, "d")
    .endOf("day")
    .utc(false)
    .toDate();
  var morningSession = moment().utc(false);

  morningSession.set("hour", 8);
  morningSession.set("minute", 0);

  var eveningSession = moment().utc(false);
  eveningSession.set("hour", 18);
  eveningSession.set("minute", 0);
  const sessions = [{ session: morningSession }, { session: eveningSession }];

  const handleAssignPlan = () => {
    const subscription = {
      plan: plan._id,
      user: user,
      days: days,
      startsAt: _startsAt,
      endsAt: _endsAt,
      sessions: sessions,
      status: "active",
      planName: plan.name,
    };
    createSubscription(subscription)
      .unwrap()
      .then((data) => {
        setShowModal(false);
        setSuccess(true);
        setMessage("Assigned Plan Successfully!");

        setTimeout(() => {
          setSuccess(false);
          setMessage();
        }, 2000);
      })
      .catch((error) => {
        setShowModal(false);
        setError(true);
        setMessage(error.message);
      });
  };

  const handleAssignPlanAuto = () => {
    const subscription = {
      plan: plan._id,
      user: user,
      days: days,
      startsAt: _startsAt,
      endsAt: _endsAt,
      sessions: sessions,
      status: "active",
      autoScheduleAppointment: true,
      department: plan.department[0],
      painPoint: plan.painPoint[0],
      planName: plan.name,
    };
    setLoading(true);
    createSubscription(subscription)
      .unwrap()
      .then((data) => {
        setLoading(false);
        setShowModal(false);
        setSuccess(true);
        setMessage("Assigned Plan Successfully!");

        setTimeout(() => {
          setSuccess(false);
          setMessage();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        setShowModal(false);
        setError(true);
        console.log(error);
        setMessage(error.message);
      });
  };

  let exerciseCount = 0;
  let exTemp = [];
  for (let index = 0; index < plan.multiWeekPlan.length; index++) {
    const element = plan.multiWeekPlan[index];
    for (let j = 0; j < element.exercises.length; j++) {
      exTemp.push(element.exercises[j]);
    }
    // console.log(element.exercises.length)
  }
  const uniqueItems = exTemp.filter(
    (elem, index) =>
      exTemp.findIndex((obj) => obj.exercise === elem.exercise) === index
  );

  exerciseCount = uniqueItems.length;
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    const planId = plan._id;
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedPlans([...selectedPlans, plan]);
    } else {
      const updatedSelectedPlans = selectedPlans.filter(
        (existingItem) => existingItem !== plan
      );
      setSelectedPlans(updatedSelectedPlans);
    }
  };

  const getImage = () => {
    return plan.painPoint[0] === "ankle"
      ? "/Ankle.svg"
      : plan.painPoint[0] === "hip"
      ? "/Hip.svg"
      : plan.painPoint[0] === "knee"
      ? "/Knee.svg"
      : plan.painPoint[0] === "lowerBack"
      ? "/LowerBack.svg"
      : plan.painPoint[0] === "neck"
      ? "/Neck.svg"
      : plan.painPoint[0] === "shoulder"
      ? "/Shoulder.svg"
      : plan.painPoint[0] === "upperBack"
      ? "/UpperBack.svg"
      : plan.painPoint[0] === "elbow"
      ? "/Elbow.svg"
      : "/HandandWrist.svg";
  };
  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
      }}>
      {showModal === true && (
        <AssignPlanConfirmation
          loading={loading}
          setLoading={setLoading}
          handleAssignPlanAuto={handleAssignPlanAuto}
          plan={plan}
          days={days}
          setShowModal={setShowModal}
          showModal={showModal}
          handleAssignPlan={handleAssignPlan}
        />
      )}
      <CardContent>
        <div className='ml-4'>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={(e) => {
                  setIsChecked(e.target.checked); // Update the checkbox state
                  handleCheckboxChange(e); // Call handleCheckboxChange function
                }}
                color='primary'
                icon={
                  <span className='h-6 w-6 border border-gray-400 rounded-md'></span>
                }
                checkedIcon={
                  <span className='h-6 w-6 flex items-center justify-center bg-primary-500 border border-transparent rounded-md'>
                    <svg
                      className='h-4 w-4 text-white fill-current'
                      viewBox='0 0 24 24'>
                      <path d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z' />
                    </svg>
                  </span>
                }
              />
            }
            label='Select'
          />
        </div>
        <div className='flex justify-center items-center'>
          <img
            alt='profile'
            src={plan.thumbnail ? plan.thumbnail : getImage()}
            className='object-cover rounded-lg h-[250px] mb-2'
          />
        </div>

        <Typography
          sx={{
            fontSize: 14,
            color:
              theme.palette.mode === "light"
                ? theme.palette.secondary.main
                : theme.palette.secondary[700],
          }}
          gutterBottom
          style={{ textTransform: "capitalize" }}>
          Pain Point : <strong>{plan.painPoint.join(", ")}</strong>
        </Typography>
        <Typography variant='h5' component='div'>
          {plan.name}
        </Typography>
        <Typography
          sx={{
            color:
              theme.palette.mode === "light"
                ? theme.palette.secondary[200]
                : theme.palette.secondary[400],
          }}>
          Exercises in Plan : {exerciseCount}
        </Typography>
        {/* <Typography
                    sx={{ mb: "1.5rem" }}
                    color={theme.palette.secondary[400]}
                >
                    Active Subscriptions : {subscriptions}
                </Typography> */}
        {/* <Rating value={4} readOnly /> */}
        <Typography></Typography>
        {/* <Typography>Number of Weeks: {plan.multiWeekPlan.length}</Typography> */}

        {error && (
          <div className='w-full p-4 mt-2 rounded-lg bg-red-200 text-red-600 text-center'>
            {message}
          </div>
        )}
        {success && (
          <div className='w-full p-4 mt-2 rounded-lg bg-green-200 text-green-600 text-center'>
            {message}
          </div>
        )}
      </CardContent>
      <FlexBetween>
        <FlexBetween justifyItems='center' alignItems='center'>
          <CardActions>
            <FormControl>
              <InputLabel>Days</InputLabel>
              <Select
                value={days}
                label='Days'
                onChange={(e) => setDays(e.target.value)}>
                <MenuItem value={45}>45</MenuItem>
                <MenuItem value={90}>90</MenuItem>
                <MenuItem value={180}>180</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant='contained'
              size='medium'
              style={{ marginLeft: "8px" }}
              onClick={() => {
                setShowModal(true);
              }}>
              Assign Plan
            </Button>
          </CardActions>
        </FlexBetween>
        <CardActions>
          <Button
            variant='contained'
            size='medium'
            onClick={() => {
              navigate(
                `/custom/details?partner=${localStorage.getItem(
                  "user"
                )}&user=${user}&plan=${plan._id}`
              );
            }}>
            Customise
          </Button>
        </CardActions>
      </FlexBetween>
      {/* <Collapse
                in={isExpanded}
                timeout="auto"
                unmountOnExit
                sx={{
                    color: theme.palette.neutral[300]
                }}
            >
                <CardContent>
                    <Typography>id: {_id}</Typography>
                   
                    <Typography>Number of Active Subscriptions: {stat}</Typography>
                </CardContent>
            </Collapse> */}
    </Card>
  );
};

const AssignPlan = () => {
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const theme = useTheme();
  const [selectedPlans, setSelectedPlans] = useState(0);

  const { data, isLoading, error } = useGetPlansQuery(
    selectedPlans === 0 ? false : true
  );
  const query = useLocation().search;
  const user = new URLSearchParams(query).get("patient");
  const [department, setDepartment] = useState("orthopedic");
  const [painPoint, setPainPoint] = useState("lowerBack");
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const [selectedPlansMerge, setSelectedPlansMerge] = useState([]);
  const [userData] = useOutletContext();

  const handleMergePlan = () => {
    if (selectedPlansMerge.length < 2) {
      toast.error("Please select atleast 2 plans");
    } else {
      const mergedPlan = mergePlans(selectedPlansMerge);
      //    console.log(mergedPlan);
      // let multiWeekPlan = [];
      // for(let i=0; i< selectedPlansMerge.length;i++)
      // {
      //     let sp=selectedPlansMerge[i];

      // }
      navigate(
        `/custom/merge?partner=${localStorage.getItem("user")}&user=${user}`,
        { state: { mergedPlan } }
      );
    }
  };
  function mergePlans(plans) {
    // Find the maximum length of multiweekPlans array
    const maxMultiweekLength = Math.max(
      ...plans.map((plan) => plan.multiWeekPlan.length)
    );

    // Create an empty mergedPlan object
    const mergedPlan = {
      name: "",
      createdBy: userData.userData[0]._id,
      department: [department],
      painPoint: [painPoint],
      custom: true,
      multiWeekPlan: Array(maxMultiweekLength).fill({
        weekNumber: 0,
        isDaily: false,
        exercises: [],
      }),
    };

    // Merge exercises from each multiweekPlan
    let mwp = [];
    plans.forEach((plan) => {
      plan.multiWeekPlan.forEach((multiweekPlan, index) => {
        //   const mergedExercises = mergeUniqueExercises(mergedPlan.multiWeekPlan[index].exercises, multiweekPlan.exercises);
        //   mergedPlan.multiWeekPlan[index].exercises = mergedExercises;
        if (mwp[index]) {
          if (mwp[index].weekNumber === multiweekPlan.weekNumber) {
            console.log(
              mwp[index].weekNumber + "--" + multiweekPlan.weekNumber
            );
            // console.log(multiweekPlan.exercises)
            const mergedExercises = mergeUniqueExercises(
              mwp[index].exercises,
              multiweekPlan.exercises
            );
            mwp[index].exercises = mergedExercises;
          }
        } else {
          mwp.push({
            weekNumber: index + 1,
            isDaily: false,
            exercises: multiweekPlan.exercises,
          });
        }
        //   mwp.push({weekNumber:index+1,isDaily:false, exercises:mergedExercises})
      });
    });
    mergedPlan.multiWeekPlan = mwp;
    return mergedPlan;
  }

  function mergeUniqueExercises(existingExercises, newExercises) {
    const mergedExercises = [...existingExercises];

    newExercises.forEach((newExercise) => {
      if (
        !existingExercises.some(
          (existingExercise) =>
            existingExercise.exercise === newExercise.exercise
        )
      ) {
        mergedExercises.push(newExercise);
      }
    });

    return mergedExercises;
  }

  return (
    <Box m='1.5rem 2.5rem'>
      <Header title='Assign Plan' subtitle='Select product and assign plan!' />
      <ToastContainer
        autoClose={3000}
        position='top-center'
        hideProgressBar={true}
      />
      <FlexBetween className='flex flex-col md:flex-row'>
        <div className='flex flex-col md:flex-row'>
          <FlexBetween
            backgroundColor={theme.palette.background.alt}
            borderRadius='9px'
            gap='3rem'
            mt={2}
            p='0.1rem 1.5rem'>
            <InputBase
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder='Search...'
            />
            <Search />
          </FlexBetween>
        </div>

        <Tabs value={selectedPlans}>
          <Tab
            value={0}
            onClick={() => {
              setSelectedPlans(0);
            }}
            label='Library Plans'
          />
          <Tab
            value={1}
            onClick={() => {
              setSelectedPlans(1);
            }}
            label='Custom Plans'
          />
        </Tabs>
      </FlexBetween>
      <div className='flex flex-col mt-4 md:flex-row'>
        <Button
          sx={{ m: "8px" }}
          variant='contained'
          size='medium'
          onClick={() => {
            navigate(`/custom/create?user=${user}`);
          }}>
          Create Plan
        </Button>
        <Button
          sx={{ m: "8px" }}
          variant='contained'
          size='medium'
          onClick={handleMergePlan}>
          Merge Plan
        </Button>
        <FlexBetween sx={{ marginLeft: "8px" }}>
          <InputLabel>Department</InputLabel>
          <Select
            value={department}
            label='Department'
            sx={{ marginLeft: "8px" }}
            onChange={(e) => setDepartment(e.target.value)}>
            <MenuItem value={"orthopedic"}>Ortho</MenuItem>
            <MenuItem value={"sports_injury"}>Sports</MenuItem>
            <MenuItem value={"gynaecology"}>Gyno</MenuItem>
            <MenuItem value={"pcod"}>PCOD</MenuItem>
          </Select>
          {(department === "orthopedic" || department === "sports_injury") && (
            <FlexBetween>
              <InputLabel sx={{ marginLeft: "8px" }}>Pain Point</InputLabel>
              <Select
                sx={{ marginLeft: "8px" }}
                value={painPoint}
                label='Pain Point'
                onChange={(e) => setPainPoint(e.target.value)}>
                <MenuItem value={"lowerBack"}>Lowerback</MenuItem>
                <MenuItem value={"upperBack"}>Upperback</MenuItem>
                <MenuItem value={"shoulder"}>Shoulder</MenuItem>
                <MenuItem value={"knee"}>Knee</MenuItem>
                <MenuItem value={"hip"}>Hip</MenuItem>
                <MenuItem value={"neck"}>Neck</MenuItem>
                <MenuItem value={"ankle"}>Ankle</MenuItem>
                <MenuItem value={"elbow"}>Elbow</MenuItem>
                <MenuItem value={"wristAndHand"}>Wrist and Hand</MenuItem>
              </Select>
            </FlexBetween>
          )}
        </FlexBetween>
      </div>

      {data || !isLoading ? (
        <Box
          mt='20px'
          display='grid'
          gridTemplateColumns='repeat(3, minmax(0, 1fr))'
          justifyContent='space-between'
          rowGap='20px'
          columnGap='1.33%'
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}>
          {data.length > 0 &&
            data
              .filter(
                (p, i) =>
                  (department === "orthopedic" || department === "sports_injury"
                    ? p.department[0] === department &&
                      p.painPoint.includes(painPoint)
                    : p.department[0] === department) &&
                  p.name
                    .trim()
                    .toLowerCase()
                    .includes(search.trim().toLowerCase())
              )
              .map((p, i) => {
                return (
                  <Product
                    selectedPlans={selectedPlansMerge}
                    setSelectedPlans={setSelectedPlansMerge}
                    plan={p}
                    user={user}
                    key={p._id}
                  />
                );
              })}
          {data.length === 0 && (
            <div>
              <div>No Custom Plan Found</div>
              <Button
                variant='contained'
                style={{ marginTop: "14px" }}
                size='medium'
                onClick={() => {
                  navigate(`/custom/create?user=${user}`);
                }}>
                Create Plan
              </Button>
            </div>
          )}
        </Box>
      ) : (
        <div>
          {error && <div>No Custom Plan Found</div>}
          {!error && <div>Loading...</div>}
        </div>
      )}
    </Box>
  );
};

export default AssignPlan;
