import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  useTheme,
} from "@mui/material";
import {
  Search,
  GetApp,
  Print,
  ViewColumn,
  FilterList,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";
import { tokensDark, tokensLight } from "theme";
import { ToastContainer } from "react-toastify";
import { useGetPartnersQuery } from "state/api";
import { ArrowUpward } from "@mui/icons-material";
import { ArrowDownward } from "@mui/icons-material";

const Partners = () => {
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const tableRef = useRef(null);
  const theme = useTheme();

  const [filterCriteria, setFilterCriteria] = useState({
    name: "",
    account: "",
    status: "",
    countryCode: "",
    photoUrl: "",
    email: "",
    dateOfBirth: "",
    description: "",
    mobile: "",
    whatsapp: "",
    adhaarUrl: "",
    currentAddress: "",
    city: "",
    pincode: "",
    state: "",
    degree: "",
    degreeUrl: "",
    yearOfGraduation: "",
    mciReg: "",
    licenseNumber: "",
    yearsOfExperience: "",
    specialization: "",
    currentEngagement: "",
    pan: "",
    panUrl: "",
    accountHoldername: "",
    bankName: "",
    branchName: "",
    accountNumber: "",
    bankIFSC: "",
    onlineEvaluationFees: "",
    telePhysioSessionFee: "",
    homeVisits: "",
    homeVisitFee: "",
    consultationTimings: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    Instagram: "",
  });

  const { data: tableData = [], isLoading } = useGetPartnersQuery();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [sortOrder, setSortOrder] = useState("asc");
  const [filteredPartners, setFilteredPartners] = useState(tableData);
  useEffect(() => {
    if (tableData) {
      setFilteredPartners(tableData);
      setCurrentPage(1);
    }
  }, [tableData]);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const handleRowClick = (partner) => {
    if (selectedPartner === partner) {
      setSelectedPartner(null);
    } else {
      setSelectedPartner(partner);
    }
  };

  const handleSortOrderChange = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
  };
  const sortTableData = () => {
    const sortedData = [...tableData].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
    console.log(sortedData);
    setFilteredPartners(sortedData);
  };

  useEffect(() => {
    sortTableData();
  }, [sortOrder]);

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleFilterCriteriaChange = (event, field) => {
    const { value } = event.target;
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      [field]: value,
    }));

    const filteredData = tableData.filter((partner) => {
      const {
        name,
        account,
        status,
        countryCode,
        photoUrl,
        email,
        dateOfBirth,
        description,
        mobile,
        whatsapp,
        adhaarUrl,
        currentAddress,
        city,
        pincode,
        state,
        degree,
        degreeUrl,
        yearOfGraduation,
        mciReg,
        licenseNumber,
        yearsOfExperience,
        specialization,
        currentEngagement,
        pan,
        panUrl,
        accountHoldername,
        bankName,
        branchName,
        accountNumber,
        bankIFSC,
        onlineEvaluationFees,
        telePhysioSessionFee,
        homeVisits,
        homeVisitFee,
        consultationTimings,
        facebook,
        twitter,
        linkedin,
        Instagram,
      } = partner;
      const lowerCaseSearchQuery = event.target.value.toLowerCase();

      return (
        (name &&
          name.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (account &&
          account.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (status &&
          status.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (countryCode &&
          countryCode
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (photoUrl &&
          photoUrl.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (email &&
          email.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (dateOfBirth &&
          dateOfBirth
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (description &&
          description
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (mobile &&
          mobile.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (whatsapp &&
          whatsapp.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (adhaarUrl &&
          adhaarUrl.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (currentAddress &&
          currentAddress
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (city &&
          city.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (pincode &&
          pincode.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (state &&
          state.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (degree &&
          degree.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (degreeUrl &&
          degreeUrl.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (yearOfGraduation &&
          yearOfGraduation
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (mciReg &&
          mciReg.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (licenseNumber &&
          licenseNumber
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (yearsOfExperience &&
          yearsOfExperience
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (specialization &&
          specialization
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (currentEngagement &&
          currentEngagement
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (pan && pan.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (panUrl &&
          panUrl.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (accountHoldername &&
          accountHoldername
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (bankName &&
          bankName.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (branchName &&
          branchName.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (accountNumber &&
          accountNumber
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (bankIFSC &&
          bankIFSC.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (onlineEvaluationFees &&
          onlineEvaluationFees
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (telePhysioSessionFee &&
          telePhysioSessionFee
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (homeVisits &&
          homeVisits.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (homeVisitFee &&
          homeVisitFee
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (consultationTimings &&
          consultationTimings
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (facebook &&
          facebook.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (twitter &&
          twitter.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (linkedin &&
          linkedin.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (Instagram &&
          Instagram.toString().toLowerCase().includes(lowerCaseSearchQuery))
      );
    });

    setFilteredPartners(filteredData);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value);

    const filteredData = tableData.filter((partner) => {
      const {
        name,
        account,
        status,
        countryCode,
        photoUrl,
        email,
        dateOfBirth,
        description,
        mobile,
        whatsapp,
        adhaarUrl,
        currentAddress,
        city,
        pincode,
        state,
        degree,
        degreeUrl,
        yearOfGraduation,
        mciReg,
        licenseNumber,
        yearsOfExperience,
        specialization,
        currentEngagement,
        pan,
        panUrl,
        accountHoldername,
        bankName,
        branchName,
        accountNumber,
        bankIFSC,
        onlineEvaluationFees,
        telePhysioSessionFee,
        homeVisits,
        homeVisitFee,
        consultationTimings,
        facebook,
        twitter,
        linkedin,
        Instagram,
      } = partner;
      const lowerCaseSearchQuery = event.target.value.toLowerCase();

      return (
        (name &&
          name.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (account &&
          account.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (status &&
          status.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (countryCode &&
          countryCode
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (photoUrl &&
          photoUrl.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (email &&
          email.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (dateOfBirth &&
          dateOfBirth
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (description &&
          description
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (mobile &&
          mobile.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (whatsapp &&
          whatsapp.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (adhaarUrl &&
          adhaarUrl.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (currentAddress &&
          currentAddress
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (city &&
          city.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (pincode &&
          pincode.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (state &&
          state.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (degree &&
          degree.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (degreeUrl &&
          degreeUrl.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (yearOfGraduation &&
          yearOfGraduation
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (mciReg &&
          mciReg.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (licenseNumber &&
          licenseNumber
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (yearsOfExperience &&
          yearsOfExperience
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (specialization &&
          specialization
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (currentEngagement &&
          currentEngagement
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (pan && pan.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (panUrl &&
          panUrl.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (accountHoldername &&
          accountHoldername
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (bankName &&
          bankName.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (branchName &&
          branchName.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (accountNumber &&
          accountNumber
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (bankIFSC &&
          bankIFSC.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (onlineEvaluationFees &&
          onlineEvaluationFees
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (telePhysioSessionFee &&
          telePhysioSessionFee
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (homeVisits &&
          homeVisits.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (homeVisitFee &&
          homeVisitFee
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (consultationTimings &&
          consultationTimings
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (facebook &&
          facebook.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (twitter &&
          twitter.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (linkedin &&
          linkedin.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (Instagram &&
          Instagram.toString().toLowerCase().includes(lowerCaseSearchQuery))
      );
    });

    setFilteredPartners(filteredData);
    setCurrentPage(1);
  };

  const handleExportCSV = () => {
    const csvData = filteredPartners.map((partner) => [
      partner.name,
      partner.account,
      partner.status,
      partner.countryCode,
      partner.photoUrl,
      partner.email,
      partner.dateOfBirth,
      partner.description,
      partner.mobile,
      partner.whatsapp,
      partner.adhaarUrl,
      partner.currentAddress,
      partner.city,
      partner.pincode,
      partner.state,
      partner.degree,
      partner.degreeUrl,
      partner.yearOfGraduation,
      partner.mciReg,
      partner.licenseNumber,
      partner.yearsOfExperience,
      partner.specialization,
      partner.currentEngagement,
      partner.pan,
      partner.panUrl,
      partner.accountHoldername,
      partner.bankName,
      partner.branchName,
      partner.accountNumber,
      partner.bankIFSC,
      partner.onlineEvaluationFees,
      partner.telePhysioSessionFee,
      partner.homeVisits,
      partner.homeVisitFee,
      partner.consultationTimings,
      partner.facebook,
      partner.twitter,
      partner.linkedin,
      partner.Instagram,
    ]);

    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "partners.csv");
  };

  const handleResetFilterCriteria = () => {
    setFilterCriteria({
      name: "",
      account: "",
      status: "",
      countryCode: "",
      photoUrl: "",
      email: "",
      dateOfBirth: "",
      description: "",
      mobile: "",
      whatsapp: "",
      adhaarUrl: "",
      currentAddress: "",
      city: "",
      pincode: "",
      state: "",
      degree: "",
      degreeUrl: "",
      yearOfGraduation: "",
      mciReg: "",
      licenseNumber: "",
      yearsOfExperience: "",
      specialization: "",
      currentEngagement: "",
      pan: "",
      panUrl: "",
      accountHoldername: "",
      bankName: "",
      branchName: "",
      accountNumber: "",
      bankIFSC: "",
      onlineEvaluationFees: "",
      telePhysioSessionFee: "",
      homeVisits: "",
      homeVisitFee: "",
      consultationTimings: "",
      facebook: "",
      twitter: "",
      linkedin: "",
      instagram: "",
    });
    setFilteredPartners(tableData);
    setCurrentPage(1);
  };

  const handlePrint = () => {
    const content = tableRef.current.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
          <html>
            <head>
              <title>Print</title>
            </head>
            <body>
              ${content}
            </body>
          </html>
        `);
    printWindow.document.close();
    printWindow.print();
  };

  const isDarkMode = theme.palette.mode === "dark";
  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredPartners.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredPartners.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="flex flex-col justify-between mb-4">
      <ToastContainer
        containerId="toast"
        autoClose={2000}
        position="top-center"
        hideProgressBar={true}
      />
      <ButtonGroup>
        <IconButton>
          <Search />
        </IconButton>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => {
            handleSearch(e);
          }}
        />
        <IconButton onClick={handleExportCSV}>
          <GetApp />
        </IconButton>
        <IconButton onClick={handlePrint}>
          <Print />
        </IconButton>
        <IconButton onClick={handleFilterDialogOpen}>
          <FilterList />
        </IconButton>
        <IconButton onClick={() => navigate("/feedbacks/table")}>
          <ViewColumn />
        </IconButton>
      </ButtonGroup>

      <TableContainer component={Paper} ref={tableRef} className="mt-10">
        <Table>
          <TableHead>
            <TableRow
              style={{
                cursor: "pointer",
                backgroundColor: "#06334B",
                color: isDarkMode
                  ? tokensDark.secondary[800]
                  : tokensDark.secondary[700],
              }}
            >
              <TableCell>Name</TableCell>
              <TableCell>Account</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Country Code</TableCell>
              <TableCell>Photo URL</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Date of Birth</TableCell>
              {/* <TableCell>Description</TableCell> */}
              <TableCell>Mobile</TableCell>
              <TableCell>WhatsApp</TableCell>

              <TableCell>Current Address</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Pincode</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Degree</TableCell>
              <TableCell>Degree URL</TableCell>
              <TableCell>Year of Graduation</TableCell>
              <TableCell>MCI Registration</TableCell>
              <TableCell>License Number</TableCell>
              <TableCell>Years of Experience</TableCell>
              <TableCell>Specialization</TableCell>
              <TableCell>Current Engagement</TableCell>
              <TableCell>PAN</TableCell>
              <TableCell>PAN URL</TableCell>
              <TableCell>Account Holder Name</TableCell>
              <TableCell>Bank Name</TableCell>
              <TableCell>Branch Name</TableCell>
              <TableCell>Account Number</TableCell>
              <TableCell>Bank IFSC</TableCell>
              <TableCell>Online Evaluation Fees</TableCell>
              <TableCell>Telephysio Session Fee</TableCell>
              <TableCell>Home Visits</TableCell>
              <TableCell>Home Visit Fee</TableCell>
              <TableCell>Consultation Timings</TableCell>
              <TableCell>Facebook</TableCell>
              <TableCell>Twitter</TableCell>
              <TableCell>LinkedIn</TableCell>
              <TableCell>Instagram</TableCell>
              <TableCell onClick={handleSortOrderChange}>
                Aadhaar URL{" "}
                {sortOrder === "asc" ? <ArrowDownward /> : <ArrowUpward />}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentItems.map((partner) => (
              <TableRow
                key={partner._id}
                onClick={() => handleRowClick(partner)}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#095271",
                  color: isDarkMode
                    ? tokensDark.secondary[800]
                    : tokensDark.secondary[700],
                }}
              >
                <TableCell>{partner.name}</TableCell>
                <TableCell>{partner.account}</TableCell>
                <TableCell>{partner.status}</TableCell>
                <TableCell>{partner.countryCode}</TableCell>
                <TableCell>
                  <img src={partner.photoUrl} />
                </TableCell>
                <TableCell>{partner.email}</TableCell>
                <TableCell>{partner.dateOfBirth}</TableCell>
                {/* <TableCell>{partner.description}</TableCell> */}
                <TableCell>{partner.mobile}</TableCell>
                <TableCell>{partner.whatsapp}</TableCell>

                <TableCell>{partner.currentAddress}</TableCell>
                <TableCell>{partner.city}</TableCell>
                <TableCell>{partner.pincode}</TableCell>
                <TableCell>{partner.state}</TableCell>
                <TableCell>{partner.degree}</TableCell>
                <TableCell>{partner.degreeUrl}</TableCell>
                <TableCell>{partner.yearOfGraduation}</TableCell>
                <TableCell>{partner.mciReg}</TableCell>
                <TableCell>{partner.licenseNumber}</TableCell>
                <TableCell>{partner.yearsOfExperience}</TableCell>
                <TableCell>{partner.specialization}</TableCell>
                <TableCell>{partner.currentEngagement}</TableCell>
                <TableCell>{partner.pan}</TableCell>
                <TableCell>{partner.panUrl}</TableCell>
                <TableCell>{partner.accountHoldername}</TableCell>
                <TableCell>{partner.bankName}</TableCell>
                <TableCell>{partner.branchName}</TableCell>
                <TableCell>{partner.accountNumber}</TableCell>
                <TableCell>{partner.bankIFSC}</TableCell>
                <TableCell>{partner.onlineEvaluationFees}</TableCell>
                <TableCell>{partner.telePhysioSessionFee}</TableCell>
                <TableCell>{partner.homeVisits}</TableCell>
                <TableCell>{partner.homeVisitFee}</TableCell>
                <TableCell>{partner.consultationTimings}</TableCell>
                <TableCell>{partner.facebook}</TableCell>
                <TableCell>{partner.twitter}</TableCell>
                <TableCell>{partner.linkedin}</TableCell>
                <TableCell>{partner.instagram}</TableCell>
                <TableCell>{partner.adhaarUrl}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <ButtonGroup>
          <Button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </Button>
          {Array.from({ length: totalPages }, (_, i) => (
            <Button
              key={i + 1}
              variant={currentPage === i + 1 ? "contained" : "outlined"}
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </Button>
          ))}
          <Button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </Button>
        </ButtonGroup>
      </div>

      <Dialog open={openFilterDialog} onClose={handleFilterDialogClose}>
        <DialogTitle>Filter Partners</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            fullWidth
            variant="outlined"
            value={filterCriteria.name}
            onChange={(e) => handleFilterCriteriaChange(e, "name")}
          />

          <TextField
            margin="dense"
            label="Account"
            fullWidth
            variant="outlined"
            value={filterCriteria.account}
            onChange={(e) => handleFilterCriteriaChange(e, "account")}
          />

          <TextField
            margin="dense"
            label="Status"
            fullWidth
            variant="outlined"
            value={filterCriteria.status}
            onChange={(e) => handleFilterCriteriaChange(e, "status")}
          />

          <TextField
            margin="dense"
            label="Country Code"
            fullWidth
            variant="outlined"
            value={filterCriteria.countryCode}
            onChange={(e) => handleFilterCriteriaChange(e, "countryCode")}
          />

          <TextField
            margin="dense"
            label="Photo URL"
            fullWidth
            variant="outlined"
            value={filterCriteria.photoUrl}
            onChange={(e) => handleFilterCriteriaChange(e, "photoUrl")}
          />

          <TextField
            margin="dense"
            label="Email"
            fullWidth
            variant="outlined"
            value={filterCriteria.email}
            onChange={(e) => handleFilterCriteriaChange(e, "email")}
          />

          <TextField
            margin="dense"
            label="Date of Birth"
            fullWidth
            variant="outlined"
            value={filterCriteria.dateOfBirth}
            onChange={(e) => handleFilterCriteriaChange(e, "dateOfBirth")}
          />

          <TextField
            margin="dense"
            label="Description"
            fullWidth
            variant="outlined"
            value={filterCriteria.description}
            onChange={(e) => handleFilterCriteriaChange(e, "description")}
          />

          <TextField
            margin="dense"
            label="Mobile"
            fullWidth
            variant="outlined"
            value={filterCriteria.mobile}
            onChange={(e) => handleFilterCriteriaChange(e, "mobile")}
          />

          <TextField
            margin="dense"
            label="WhatsApp"
            fullWidth
            variant="outlined"
            value={filterCriteria.whatsapp}
            onChange={(e) => handleFilterCriteriaChange(e, "whatsapp")}
          />

          <TextField
            margin="dense"
            label="Aadhaar URL"
            fullWidth
            variant="outlined"
            value={filterCriteria.adhaarUrl}
            onChange={(e) => handleFilterCriteriaChange(e, "adhaarUrl")}
          />

          <TextField
            margin="dense"
            label="Current Address"
            fullWidth
            variant="outlined"
            value={filterCriteria.currentAddress}
            onChange={(e) => handleFilterCriteriaChange(e, "currentAddress")}
          />

          <TextField
            margin="dense"
            label="City"
            fullWidth
            variant="outlined"
            value={filterCriteria.city}
            onChange={(e) => handleFilterCriteriaChange(e, "city")}
          />

          <TextField
            margin="dense"
            label="Pincode"
            fullWidth
            variant="outlined"
            value={filterCriteria.pincode}
            onChange={(e) => handleFilterCriteriaChange(e, "pincode")}
          />

          <TextField
            margin="dense"
            label="State"
            fullWidth
            variant="outlined"
            value={filterCriteria.state}
            onChange={(e) => handleFilterCriteriaChange(e, "state")}
          />

          <TextField
            margin="dense"
            label="Degree"
            fullWidth
            variant="outlined"
            value={filterCriteria.degree}
            onChange={(e) => handleFilterCriteriaChange(e, "degree")}
          />

          <TextField
            margin="dense"
            label="Degree URL"
            fullWidth
            variant="outlined"
            value={filterCriteria.degreeUrl}
            onChange={(e) => handleFilterCriteriaChange(e, "degreeUrl")}
          />

          <TextField
            margin="dense"
            label="Year of Graduation"
            fullWidth
            variant="outlined"
            value={filterCriteria.yearOfGraduation}
            onChange={(e) => handleFilterCriteriaChange(e, "yearOfGraduation")}
          />

          <TextField
            margin="dense"
            label="MCI Reg"
            fullWidth
            variant="outlined"
            value={filterCriteria.mciReg}
            onChange={(e) => handleFilterCriteriaChange(e, "mciReg")}
          />

          <TextField
            margin="dense"
            label="License Number"
            fullWidth
            variant="outlined"
            value={filterCriteria.licenseNumber}
            onChange={(e) => handleFilterCriteriaChange(e, "licenseNumber")}
          />

          <TextField
            margin="dense"
            label="Years of Experience"
            fullWidth
            variant="outlined"
            value={filterCriteria.yearsOfExperience}
            onChange={(e) => handleFilterCriteriaChange(e, "yearsOfExperience")}
          />

          <TextField
            margin="dense"
            label="Specialization"
            fullWidth
            variant="outlined"
            value={filterCriteria.specialization}
            onChange={(e) => handleFilterCriteriaChange(e, "specialization")}
          />

          <TextField
            margin="dense"
            label="Current Engagement"
            fullWidth
            variant="outlined"
            value={filterCriteria.currentEngagement}
            onChange={(e) => handleFilterCriteriaChange(e, "currentEngagement")}
          />

          <TextField
            margin="dense"
            label="PAN"
            fullWidth
            variant="outlined"
            value={filterCriteria.pan}
            onChange={(e) => handleFilterCriteriaChange(e, "pan")}
          />

          <TextField
            margin="dense"
            label="PAN URL"
            fullWidth
            variant="outlined"
            value={filterCriteria.panUrl}
            onChange={(e) => handleFilterCriteriaChange(e, "panUrl")}
          />

          <TextField
            margin="dense"
            label="Account Holder Name"
            fullWidth
            variant="outlined"
            value={filterCriteria.accountHoldername}
            onChange={(e) => handleFilterCriteriaChange(e, "accountHoldername")}
          />

          <TextField
            margin="dense"
            label="Bank Name"
            fullWidth
            variant="outlined"
            value={filterCriteria.bankName}
            onChange={(e) => handleFilterCriteriaChange(e, "bankName")}
          />

          <TextField
            margin="dense"
            label="Branch Name"
            fullWidth
            variant="outlined"
            value={filterCriteria.branchName}
            onChange={(e) => handleFilterCriteriaChange(e, "branchName")}
          />

          <TextField
            margin="dense"
            label="Account Number"
            fullWidth
            variant="outlined"
            value={filterCriteria.accountNumber}
            onChange={(e) => handleFilterCriteriaChange(e, "accountNumber")}
          />

          <TextField
            margin="dense"
            label="Bank IFSC"
            fullWidth
            variant="outlined"
            value={filterCriteria.bankIFSC}
            onChange={(e) => handleFilterCriteriaChange(e, "bankIFSC")}
          />

          <TextField
            margin="dense"
            label="Online Evaluation Fees"
            fullWidth
            variant="outlined"
            value={filterCriteria.onlineEvaluationFees}
            onChange={(e) =>
              handleFilterCriteriaChange(e, "onlineEvaluationFees")
            }
          />

          <TextField
            margin="dense"
            label="Tele Physio Session Fee"
            fullWidth
            variant="outlined"
            value={filterCriteria.telePhysioSessionFee}
            onChange={(e) =>
              handleFilterCriteriaChange(e, "telePhysioSessionFee")
            }
          />

          <TextField
            margin="dense"
            label="Home Visits"
            fullWidth
            variant="outlined"
            value={filterCriteria.homeVisits}
            onChange={(e) => handleFilterCriteriaChange(e, "homeVisits")}
          />

          <TextField
            margin="dense"
            label="Home Visit Fee"
            fullWidth
            variant="outlined"
            value={filterCriteria.homeVisitFee}
            onChange={(e) => handleFilterCriteriaChange(e, "homeVisitFee")}
          />

          <TextField
            margin="dense"
            label="Consultation Timings"
            fullWidth
            variant="outlined"
            value={filterCriteria.consultationTimings}
            onChange={(e) =>
              handleFilterCriteriaChange(e, "consultationTimings")
            }
          />

          <TextField
            margin="dense"
            label="Facebook"
            fullWidth
            variant="outlined"
            value={filterCriteria.facebook}
            onChange={(e) => handleFilterCriteriaChange(e, "facebook")}
          />

          <TextField
            margin="dense"
            label="Twitter"
            fullWidth
            variant="outlined"
            value={filterCriteria.twitter}
            onChange={(e) => handleFilterCriteriaChange(e, "twitter")}
          />

          <TextField
            margin="dense"
            label="LinkedIn"
            fullWidth
            variant="outlined"
            value={filterCriteria.linkedin}
            onChange={(e) => handleFilterCriteriaChange(e, "linkedin")}
          />

          <TextField
            margin="dense"
            label="Instagram"
            fullWidth
            variant="outlined"
            value={filterCriteria.instagram}
            onChange={(e) => handleFilterCriteriaChange(e, "instagram")}
          />

          <Button variant="contained" onClick={handleFilterDialogClose}>
            Apply Filter
          </Button>

          <Button
            variant="contained"
            style={{ marginLeft: "8px" }}
            onClick={handleResetFilterCriteria}
          >
            Reset Filters
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Partners;
