import { Button, CircularProgress, Typography } from '@mui/material';
import { useState } from 'react';

function AssignPlanConfirmation({loading, setLoading, handleAssignPlanAuto, days, handleAssignPlan,showModal, setShowModal, plan }) {
   

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };
  
  return (
    <div key={plan._id}>
     
     
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            
            <div className="bg-white rounded-lg p-8 max-w-md w-full mx-auto">
            <div className='w-full p-4 mt-2 rounded-lg text-black text-center'>
            <Typography variant="h4">Are you sure?</Typography>
                <Typography variant="h4">Assign {plan.name} for {days} days?</Typography>
              
              {!loading && <div className='flex mt-4 justify-between'>
            
               <button
                className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-lg"
                onClick={handleCloseModal}
              >
                Close
              </button>
              <button
                className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg"
                onClick={()=>{handleAssignPlan()}}
              >
                Confirm
              </button>
                </div>}
                {!loading && <div className='p-2'><button
                className="bg-blue-500 hover:bg-blue-600 text-white mt-4 py-2 px-4 rounded-lg"
                onClick={()=>{handleAssignPlanAuto()}}
              >
                Assign Plan And Auto Schedule Appointments
              </button>
              <p className=' italic mt-2 text-sm'>Note that auto scheduling will schedule appointments at an interval of 15 days at 9:00am till the plan expires.</p>
              </div>
              }
              {
                loading && <CircularProgress/>
              }
              </div>
            
            </div>
           
          </div>
        </div>

    </div>
  );
}

export default AssignPlanConfirmation;
