import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  useTheme,
} from "@mui/material";
import {
  Search,
  GetApp,
  Print,
  ViewColumn,
  FilterList,
  ArrowUpward,
  ArrowDownward,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";
import { tokensDark, tokensLight } from "theme";
import { ToastContainer } from "react-toastify";
import { useGetInventoryQuery } from "state/api";

const Inventory = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const tableRef = useRef(null);
  const theme = useTheme();

  const [filterCriteria, setFilterCriteria] = useState({
    itemId: "",
    itemName: "",
    itemType: "",
  });

  const { data: tableData = [], isLoading } = useGetInventoryQuery(); // Fetch inventory data from the server
  const [filteredItems, setFilteredItems] = useState(tableData);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);

  useEffect(() => {
    console.log("Inventory", tableData);
    setFilteredItems(tableData);
    setCurrentPage(1);
  }, [tableData]);

  const handleRowClick = (item) => {
    if (selectedItem === item) {
      setSelectedItem(null);
    } else {
      setSelectedItem(item);
    }
  };
  const handleSortOrderChange = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
  };
  const sortTableData = () => {
    const sortedData = [...tableData].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
    console.log(sortedData);
    setFilteredItems(sortedData);
  };
  
  useEffect(() => {
    sortTableData();
  }, [sortOrder]);

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleFilterCriteriaChange = (event, field) => {
    const { value } = event.target;
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      [field]: value,
    }));

    const filteredData = tableData.filter((item) => {
      const { itemId, itemName, itemType } = item;
      const lowerCaseSearchQuery = event.target.value;

      return (
        itemId.toString().toLowerCase().includes(lowerCaseSearchQuery) ||
        itemName.toString().toLowerCase().includes(lowerCaseSearchQuery) ||
        itemType.toString().toLowerCase().includes(lowerCaseSearchQuery)
      );
    });

    setFilteredItems(filteredData);
    setCurrentPage(1); // Reset to the first page
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value);

    const filteredData = tableData.filter((item) => {
      const { itemId, itemName, itemType } = item;
      const lowerCaseSearchQuery = event.target.value;

      return (
        itemId.toString().toLowerCase().includes(lowerCaseSearchQuery) ||
        itemName.toString().toLowerCase().includes(lowerCaseSearchQuery) ||
        itemType.toString().toLowerCase().includes(lowerCaseSearchQuery)
      );
    });

    setFilteredItems(filteredData);
    setCurrentPage(1); // Reset to the first page
  };

  const handleDownload = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," + convertToCSV(filteredItems);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "inventory.csv");
    document.body.appendChild(link);
    link.click();
  };

  const convertToCSV = (data) => {
    const header = ["Item ID", "Item Name", "Item Type"];
    const rows = data.map((item) => [
      item.itemId,
      item.itemName,
      item.itemType,
    ]);

    const csvData = [header, ...rows]
      .map((row) => row.map((value) => `"${value}"`).join(","))
      .join("\n");
    return csvData;
  };

  const handlePrint = () => {
    const content = tableRef.current.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleResetFilterCriteria = () => {
    setFilterCriteria({
      itemId: "",
      itemName: "",
      itemType: "",
    });
    setFilteredItems(tableData);
    setCurrentPage(1); // Reset to the first page
  };

  const isDarkMode = theme.palette.mode === "dark";

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="flex flex-col justify-between mb-4">
      <ToastContainer
        containerId="toast"
        autoClose={2000}
        position="top-center"
        hideProgressBar={true}
      />
      <ButtonGroup>
        <IconButton>
          <Search />
        </IconButton>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearch}
        />
        <IconButton onClick={handleDownload}>
          <GetApp />
        </IconButton>
        <IconButton onClick={handlePrint}>
          <Print />
        </IconButton>
        <IconButton>
          <ViewColumn />
        </IconButton>
        <IconButton onClick={handleFilterDialogOpen}>
          <FilterList />
        </IconButton>
      </ButtonGroup>

      {/* Table */}
      <TableContainer
        ref={tableRef}
        component={Paper}
        sx={{
          backgroundColor: theme.palette.background.paper,
          marginTop: "50px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow
              style={{
                cursor: "pointer",
                backgroundColor: "#06334B",
                color: isDarkMode
                  ? tokensDark.secondary[800]
                  : tokensDark.secondary[700],
              }}
            >
              <TableCell>Item ID</TableCell>
              <TableCell>Item Name</TableCell>
              <TableCell onClick={handleSortOrderChange}>
                Item Type{" "}
                {sortOrder === "asc" ? <ArrowDownward /> : <ArrowUpward />}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  Loading...
                </TableCell>
              </TableRow>
            ) : (
              currentItems.map((item, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleRowClick(item)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#095271",
                    color: isDarkMode
                      ? tokensDark.secondary[800]
                      : tokensDark.secondary[700],
                  }}
                >
                  <TableCell>{item.itemId}</TableCell>
                  <TableCell>{item.itemName}</TableCell>
                  <TableCell>{item.itemType}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <ButtonGroup>
          <Button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </Button>
          {Array.from({ length: totalPages }, (_, i) => (
            <Button
              key={i + 1}
              variant={currentPage === i + 1 ? "contained" : "outlined"}
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </Button>
          ))}
          <Button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </Button>
        </ButtonGroup>
      </div>

      {/* Filter Dialog */}
      <Dialog open={openFilterDialog} onClose={handleFilterDialogClose}>
        <DialogTitle>Filter Criteria</DialogTitle>
        <DialogContent>
          <TextField
            label="Item ID"
            variant="outlined"
            size="small"
            value={filterCriteria.itemId}
            onChange={(e) => handleFilterCriteriaChange(e, "itemId")}
          />
          <TextField
            label="Item Name"
            variant="outlined"
            size="small"
            value={filterCriteria.itemName}
            onChange={(e) => handleFilterCriteriaChange(e, "itemName")}
          />
          <TextField
            label="Item Type"
            variant="outlined"
            size="small"
            value={filterCriteria.itemType}
            onChange={(e) => handleFilterCriteriaChange(e, "itemType")}
          />
          <Button onClick={handleResetFilterCriteria}>Reset</Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Inventory;
