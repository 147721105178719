import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  useTheme,
} from "@mui/material";
import {
  Search,
  GetApp,
  Print,
  ViewColumn,
  FilterList,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";
import { tokensDark, tokensLight } from "theme";
import { ToastContainer } from "react-toastify";
import { useGetUserFeedbacksQuery } from "state/api";
import { ArrowUpward } from "@mui/icons-material";
import { ArrowDownward } from "@mui/icons-material";
import moment from "moment";
const Feedbacks = () => {
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const tableRef = useRef(null);
  const theme = useTheme();

  const [filterCriteria, setFilterCriteria] = useState({
    user: "",
    order: "",
    time: "",
    plan: "",
    status: "",
    rating: "",
    painPerception: "",
    difficulty: "",
    rom: "",
    physioTest: "",
  });

  const { data: tableData = [], isLoading } = useGetUserFeedbacksQuery();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [filteredFeedbacks, setFilteredFeedbacks] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  useEffect(() => {
    setFilteredFeedbacks(tableData);
    setCurrentPage(1);
  }, [tableData]);

  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const handleRowClick = (feedback) => {
    if (selectedFeedback === feedback) {
      setSelectedFeedback(null);
    } else {
      setSelectedFeedback(feedback);
    }
  };
  const handleSortOrderChange = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
  };
  const sortTableData = () => {
    const sortedData = [...tableData].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
    console.log(sortedData);
    setFilteredFeedbacks(sortedData);
  };

  useEffect(() => {
    sortTableData();
  }, [sortOrder]);

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleFilterCriteriaChange = (event, field) => {
    const { value } = event.target;
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      [field]: value,
    }));

    const filteredData = tableData.filter((feedback) => {
      const {
       
        order,
        time,
        plan,
        status,
        rating,
        painPerception,
        difficulty,
        rom,
        physioTest,
      } = feedback;
      const{name}= feedback.user != null ? feedback.user : ' ';
      const lowerCaseSearchQuery = event.target.value.toLowerCase();
     
      return (
        (name && name.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (order && order.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (time && time.toString().toLowerCase().includes(lowerCaseSearchQuery) )||
        (plan && plan.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (status && status.toString().toLowerCase().includes(lowerCaseSearchQuery) )||
        (rating && rating.toString().toLowerCase().includes(lowerCaseSearchQuery) )||
        (painPerception && painPerception.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (difficulty && difficulty.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (rom && rom.toString().toLowerCase().includes(lowerCaseSearchQuery) )||
        (physioTest && physioTest.toString().toLowerCase().includes(lowerCaseSearchQuery))
      );
    });
    console.log(filteredData);
    setFilteredFeedbacks(filteredData);
    setCurrentPage(1);
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value);

    const filteredData = tableData.filter((feedback) => {
      
      const {
        
        order,
        time,
        plan,
        status,
        rating,
        painPerception,
        difficulty,
        rom,
        physioTest,
      } = feedback;
      const lowerCaseSearchQuery = event.target.value.toLowerCase();
      const{name}= feedback.user != null ? feedback.user : ' ';
      return (
        (name && name.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (order && order.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (time && time.toString().toLowerCase().includes(lowerCaseSearchQuery) )||
        (plan && plan.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (status && status.toString().toLowerCase().includes(lowerCaseSearchQuery) )||
        (rating && rating.toString().toLowerCase().includes(lowerCaseSearchQuery) )||
        (painPerception && painPerception.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (difficulty && difficulty.toString().toLowerCase().includes(lowerCaseSearchQuery)) ||
        (rom && rom.toString().toLowerCase().includes(lowerCaseSearchQuery) )||
        (physioTest && physioTest.toString().toLowerCase().includes(lowerCaseSearchQuery))
      );
    });
    
    setFilteredFeedbacks(filteredData);
    setCurrentPage(1);
  };

  const handleDownload = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," + convertToCSV(filteredFeedbacks);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "feedbacks.csv");
    document.body.appendChild(link);
    link.click();
  };

  const convertToCSV = (data) => {
    const header = [
      "User",
      "Order",
      "Time",
      "Plan",
      "Status",
      "Rating",
      "Pain Perception",
      "Difficulty",
      "ROM",
    ];
    const rows = data.map((feedback) => [
      feedback.user ? feedback.user.name : "",
      // feedback.order,
      feedback.time,
      feedback.plan.name,
      feedback.status,
      feedback.rating,
      feedback.painPerception,
      feedback.difficulty,
      feedback.rom,
    ]);

    const csvData = [header, ...rows]
      .map((row) => row.map((value) => `"${value}"`).join(","))
      .join("\n");
    return csvData;
  };

  const handlePrint = () => {
    const content = tableRef.current.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleResetFilterCriteria = () => {
    setFilterCriteria({
      user: "",
      order: "",
      time: "",
      plan: "",
      status: "",
      rating: "",
      painPerception: "",
      difficulty: "",
      rom: "",
      physioTest: "",
    });
    setFilteredFeedbacks(tableData);
    setCurrentPage(1);
  };

  const isDarkMode = theme.palette.mode === "dark";
  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = filteredFeedbacks.slice(
  indexOfFirstItem,
  indexOfLastItem
);

  
  const totalPages = Math.ceil(filteredFeedbacks.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  

  return (
    <div className="flex flex-col justify-between mb-4 p-4">
      <ToastContainer
        containerId="toast"
        autoClose={2000}
        position="top-center"
        hideProgressBar={true}
      />
      <ButtonGroup>
        <IconButton>
          <Search />
        </IconButton>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearch}
        />
        <IconButton onClick={handleDownload}>
          <GetApp />
        </IconButton>
        <IconButton onClick={handlePrint}>
          <Print />
        </IconButton>
        <IconButton onClick={handleFilterDialogOpen}>
          <FilterList />
        </IconButton>
        <IconButton onClick={() => navigate("/feedbacks/table")}>
          <ViewColumn />
        </IconButton>
      </ButtonGroup>

      <TableContainer component={Paper} style={{height:"500px"}} ref={tableRef} className="mt-10">
        <Table stickyHeader>
          <TableHead>
            <TableRow
              style={{
                cursor: "pointer",
                backgroundColor: theme.palette.background.alt,
                color: isDarkMode
                  ? tokensDark.secondary[800]
                  : tokensDark.secondary[700],
              }}
            >
              <TableCell style={{ backgroundColor: theme.palette.background.alt}}>User</TableCell>
              {/* <TableCell>Order</TableCell> */}
              <TableCell style={{ backgroundColor: theme.palette.background.alt}}>Time</TableCell>
              <TableCell style={{ backgroundColor: theme.palette.background.alt}}>Plan</TableCell>
              <TableCell style={{ backgroundColor: theme.palette.background.alt}}>Status</TableCell>
              <TableCell style={{ backgroundColor: theme.palette.background.alt}}>Rating</TableCell>
              <TableCell style={{ backgroundColor: theme.palette.background.alt}}>Pain Perception</TableCell>
              <TableCell style={{ backgroundColor: theme.palette.background.alt}}>Difficulty</TableCell>
              <TableCell style={{ backgroundColor: theme.palette.background.alt}}>ROM{" "}</TableCell>
              <TableCell style={{ backgroundColor: theme.palette.background.alt}} onClick={handleSortOrderChange}>Created At{" "}
              {sortOrder === "asc" ? <ArrowDownward /> : <ArrowUpward />}</TableCell>
            </TableRow>
            
          </TableHead>
          <TableBody>
            {currentItems.map((feedback) => (
              <TableRow
                key={feedback._id}
                onClick={() => handleRowClick(feedback)}
                style={{
                  cursor: "pointer",
                  backgroundColor: theme.palette.primary.light,
                  color: isDarkMode
                    ? tokensDark.secondary[800]
                    : tokensDark.secondary[700],
                }}
              >
                <TableCell>
                  {feedback.user ? feedback.user.name : "No Account"}
                </TableCell>
                {/* <TableCell>{feedback.order}</TableCell> */}
                <TableCell>{feedback.time}</TableCell>
                <TableCell>
                  {feedback.plan ? feedback.plan.name : "No Plan"}
                </TableCell>
                <TableCell>{feedback.status}</TableCell>
                <TableCell>{feedback.rating}</TableCell>
                <TableCell>{feedback.painPerception}</TableCell>
                <TableCell>{feedback.difficulty}</TableCell>
                <TableCell>{feedback.rom}</TableCell>
                <TableCell>{moment(feedback.createdAt).format("DD MMMM YYYY HH:mm")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <div className="justify-center mt-4   overflow-x-auto">
        <ButtonGroup>
          <Button
           style={{ color: isDarkMode? 'white': '#755a0e',border:"solid"}}
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </Button>
          {Array.from({ length: totalPages }, (_, i) => (
            <Button
            style={{ color: isDarkMode? 'white': '#755a0e',border:"solid"}}
              key={i + 1}
              variant={currentPage === i + 1 ? "contained" : "outlined"}
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </Button>
          ))}
          <Button
          style={{ color: isDarkMode? 'white': '#755a0e',border:"solid"}}
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </Button>
        </ButtonGroup>
      </div>

      <Dialog open={openFilterDialog} onClose={handleFilterDialogClose}>
        <DialogTitle>Filter Feedbacks</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="User"
            fullWidth
            variant="outlined"
            value={filterCriteria.user}
            onChange={(e) => handleFilterCriteriaChange(e, "user")}
          />
          <TextField
            margin="dense"
            label="Order"
            fullWidth
            variant="outlined"
            value={filterCriteria.order}
            onChange={(e) => handleFilterCriteriaChange(e, "order")}
          />
          <TextField
            margin="dense"
            label="Time"
            fullWidth
            variant="outlined"
            value={filterCriteria.time}
            onChange={(e) => handleFilterCriteriaChange(e, "time")}
          />
          <TextField
            margin="dense"
            label="Plan"
            fullWidth
            variant="outlined"
            value={filterCriteria.plan}
            onChange={(e) => handleFilterCriteriaChange(e, "plan")}
          />
          <TextField
            margin="dense"
            label="Status"
            fullWidth
            variant="outlined"
            value={filterCriteria.status}
            onChange={(e) => handleFilterCriteriaChange(e, "status")}
          />
          <TextField
            margin="dense"
            label="Rating"
            fullWidth
            variant="outlined"
            value={filterCriteria.rating}
            onChange={(e) => handleFilterCriteriaChange(e, "rating")}
          />
          <TextField
            margin="dense"
            label="Pain Perception"
            fullWidth
            variant="outlined"
            value={filterCriteria.painPerception}
            onChange={(e) => handleFilterCriteriaChange(e, "painPerception")}
          />
          <TextField
            margin="dense"
            label="Difficulty"
            fullWidth
            variant="outlined"
            value={filterCriteria.difficulty}
            onChange={(e) => handleFilterCriteriaChange(e, "difficulty")}
          />
          <TextField
            margin="dense"
            label="ROM"
            fullWidth
            variant="outlined"
            value={filterCriteria.rom}
            onChange={(e) => handleFilterCriteriaChange(e, "rom")}
          />
          <TextField
            margin="dense"
            label="Physio Test"
            fullWidth
            variant="outlined"
            value={filterCriteria.physioTest}
            onChange={(e) => handleFilterCriteriaChange(e, "physioTest")}
          />
          <Button variant="contained" onClick={handleFilterDialogClose}>
            Apply Filter
          </Button>

          <Button
            variant="contained"
            style={{ marginLeft: "8px" }}
            onClick={handleResetFilterCriteria}
          >
            Reset Filters
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Feedbacks;
