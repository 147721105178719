import React from 'react';
import './MedicalPage.css'; // Import the CSS file for styling
import { useGetMedicalRecordsUserQuery } from 'state/api';
import { useLocation, useNavigate } from 'react-router-dom';

export const MedicalPage = () => {
  // Dummy data for medical records
  const location = useLocation()
  const params = new URLSearchParams(location.search);
  //  const [userData] = useOutletContext()
    const patient = params.get("patient")
    const navigate = useNavigate()
  const {data, loading} = useGetMedicalRecordsUserQuery(patient)
  return (
    <div className="medical-page">
      <h1>Medical Records</h1>
      <div className="record-grid">
        {data && data.map((record) => (
          <div className="record" key={record.id} onClick={()=>{navigate(`/patient/medical-record?record=${record._id}`)}}>
            <img className="record-photo" src={record.document} alt="Medical Record" />
            <div className="record-details">
              <p className="record-patient-name">{record.name}</p>
              {/* <p className="record-details-text">{record.details}</p> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};