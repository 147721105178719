import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  useTheme,
} from "@mui/material";
import {
  Search,
  GetApp,
  Print,
  ViewColumn,
  FilterList,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";
import { tokensDark, tokensLight } from "theme";
import { ToastContainer } from "react-toastify";
import { useGetQuestionsQuery } from "state/api";
import { Pagination } from "@mui/material";
import { ArrowUpward } from "@mui/icons-material";
import { ArrowDownward } from "@mui/icons-material";
const Questions = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const tableRef = useRef(null);
  const theme = useTheme();

  const [filterCriteria, setFilterCriteria] = useState({
    question: "",
    painPoint: "",
    identifier: "",
    priority: "",
  });

  const { data: tableData = [], isLoading } = useGetQuestionsQuery();

  const [filteredQuestions, setFilteredQuestions] = useState(tableData);
  useEffect(() => {
    setFilteredQuestions(tableData);
  }, [tableData]);

  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("asc");
  const rowsPerPage = 20;

  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const handleRowClick = (question) => {
    if (selectedQuestion === question) {
      setSelectedQuestion(null);
    } else {
      setSelectedQuestion(question);
    }
  };
  const handleSortOrderChange = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
  };
  const sortTableData = () => {
    const sortedData = [...tableData].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
    console.log(sortedData);
    setFilteredQuestions(sortedData);
  };

  useEffect(() => {
    sortTableData();
  }, [sortOrder]);

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleFilterCriteriaChange = (event, field) => {
    const { value } = event.target;
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      [field]: value,
    }));

    const filteredData = tableData.filter((question) => {
      const {
        question: questionText,
        painPoint,
        identifier,
        priority,
      } = question;
      const lowerCaseSearchQuery = value.toLowerCase();

      return (
        questionText.toLowerCase().includes(lowerCaseSearchQuery) &&
        painPoint
          .toLowerCase()
          .includes(filterCriteria.painPoint.toLowerCase()) &&
        identifier
          .toLowerCase()
          .includes(filterCriteria.identifier.toLowerCase()) &&
        String(priority)
          .toLowerCase()
          .includes(filterCriteria.priority.toLowerCase())
      );
    });

    setFilteredQuestions(filteredData);
    setCurrentPage(1);
  };

  const handleResetFilterCriteria = () => {
    setFilterCriteria({
      question: "",
      painPoint: "",
      identifier: "",
      priority: "",
    });
    setFilteredQuestions(tableData);
    setCurrentPage(1);
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleExport = () => {
    const table = tableRef.current;
    const csv = [];
    const rows = table.getElementsByTagName("tr");

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const cols = row.querySelectorAll("td, th");
      const csvRow = [];

      for (let j = 0; j < cols.length; j++) {
        const col = cols[j].innerText.replace(",", "");
        csvRow.push(col);
      }

      csv.push(csvRow.join(","));
    }

    const csvContent = csv.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "questions.csv");
  };

  const handlePrint = () => {
    const content = tableRef.current.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value);

    const filteredData = tableData.filter((question) => {
      const {
        question: questionText,
        painPoint,
        identifier,
        priority,
      } = question;
      const lowerCaseSearchQuery = value.toLowerCase();

      return (
        questionText.toLowerCase().includes(lowerCaseSearchQuery) ||
        painPoint.toLowerCase().includes(lowerCaseSearchQuery) ||
        identifier.toLowerCase().includes(lowerCaseSearchQuery) ||
        String(priority).toLowerCase().includes(lowerCaseSearchQuery)
      );
    });

    setFilteredQuestions(filteredData);
  };

  const isDarkMode = theme.palette.mode === "dark";

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredQuestions.slice(indexOfFirstRow, indexOfLastRow);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <div className="flex flex-col justify-between mb-4">
      <ToastContainer
        containerId="toast"
        autoClose={2000}
        position="top-center"
        hideProgressBar={true}
      />
      <ButtonGroup>
        <IconButton>
          <Search />
        </IconButton>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearch}
        />
        <IconButton onClick={handleExport}>
          <GetApp />
        </IconButton>
        <IconButton onClick={handlePrint}>
          <Print />
        </IconButton>
        <IconButton>
          <ViewColumn />
        </IconButton>
        <IconButton onClick={handleFilterDialogOpen}>
          <FilterList />
        </IconButton>
      </ButtonGroup>

      <TableContainer
        ref={tableRef}
        component={Paper}
        sx={{
          backgroundColor: theme.palette.background.paper,
          marginTop: "50px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow
              style={{
                cursor: "pointer",
                backgroundColor: "#06334B",
                color: isDarkMode
                  ? tokensDark.secondary[800]
                  : tokensDark.secondary[700],
              }}
            >
              <TableCell>Question</TableCell>
              <TableCell>Pain Point</TableCell>
              <TableCell>Identifier</TableCell>
              <TableCell onClick={handleSortOrderChange}>
                Priority{" "}
                {sortOrder === "asc" ? <ArrowDownward /> : <ArrowUpward />}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentRows.map((question) => (
              <TableRow
                key={question.id}
                hover
                selected={selectedQuestion === question}
                onClick={() => handleRowClick(question)}
                className={selectedQuestion === question ? "selected-row" : ""}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#095271",
                  color: isDarkMode
                    ? tokensDark.secondary[800]
                    : tokensDark.secondary[700],
                }}
              >
                <TableCell>{question.question}</TableCell>
                <TableCell>{question.painPoint}</TableCell>
                <TableCell>{question.identifier}</TableCell>
                <TableCell>{question.priority}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        count={Math.ceil(filteredQuestions.length / rowsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        style={{ marginTop: "10px" }}
      />

      <Dialog open={openFilterDialog} onClose={handleFilterDialogClose}>
        <DialogTitle>Filter Criteria</DialogTitle>
        <DialogContent>
          <div className="flex flex-wrap">
            <TextField
              label="Question"
              variant="outlined"
              fullWidth
              value={filterCriteria.question}
              onChange={(event) =>
                handleFilterCriteriaChange(event, "question")
              }
              className="mb-2"
            />
            <TextField
              label="Pain Point"
              variant="outlined"
              fullWidth
              value={filterCriteria.painPoint}
              onChange={(event) =>
                handleFilterCriteriaChange(event, "painPoint")
              }
              className="mb-2"
            />
            <TextField
              label="Identifier"
              variant="outlined"
              fullWidth
              value={filterCriteria.identifier}
              onChange={(event) =>
                handleFilterCriteriaChange(event, "identifier")
              }
              className="mb-2"
            />
            <TextField
              label="Priority"
              variant="outlined"
              fullWidth
              value={filterCriteria.priority}
              onChange={(event) =>
                handleFilterCriteriaChange(event, "priority")
              }
              className="mb-2"
            />
          </div>
          <div className="flex justify-end">
            <Button
              color="secondary"
              onClick={handleResetFilterCriteria}
              className="mr-2"
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleFilterDialogClose}
            >
              Apply
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Questions;
