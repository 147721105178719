import { useState } from "react";
import Exercises from "./Exercises";
import PlayVideo from "./PlayVideo";

const TestMultiWeek = ({ week, handleExercises, allExercises,handleExRepeat }) => {
  const [showAllExercises, setShowAllExercises] = useState(false);
  const [selectedEx, setSelectedEx] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [exercises, setExercises] = useState(week.exercises);
  const [repeatEx, setRepeatEx] = useState(false)
  const [exes, setExes] = useState("")
  const handleNumExercisesChange = (event) => {
    const numExercises = event.target.value;
    const newExercises = Array.from({ length: numExercises }, () => ({
      exercise: allExercises[0]._id,
      reps: 0,
      sets: 0,
      times: 1,
    }));
    setExercises(newExercises);
  };
  const handleRepeat = () =>{
    const repeatExArr = exes.split(",")
   
    handleExRepeat(week.weekNumber-1, repeatExArr)
     setRepeatEx(false)
  }
  const handleRemoveExercise = (exercise) => {
    setExercises(exercises.filter((i) => i !== exercise));
    handleExercises(
      week,
      exercises.filter((i) => i !== exercise)
    );
  };

  const handleExerciseChange = (index, field, value) => {
    setExercises((prevState) =>
      prevState.map((exercise, i) => {
        if (index === i) {
          return { ...exercise, [field]: value };
        }
        return exercise;
      })
    );
  };
  const handleAddExercise = (exercise) => {
    // Check if the item already exists in the array
    let tempEx = exercises;
    const itemExists = tempEx.some(
      (element) => element.exercise === exercise.exercise
    );

    if (itemExists) {
      // If the item exists, update it
      const updatedArray = tempEx.map((element) =>
        element.exercise === exercise.exercise ? exercise : element
      );
      setExercises(updatedArray);
    } else {
      // If the item does not exist, add it to the array
      tempEx.push(exercise);
      setExercises(tempEx);
    }
    handleExercises(week, tempEx);
  };
  const getExerciseName = (exercise) => {
    console.log(
      allExercises.filter((e) => e._id === exercise.exercise)[0].name
    );
    return allExercises.filter((e) => e._id === exercise.exercise).length > 0
      ? allExercises.filter((e) => e._id === exercise.exercise)[0].name
      : "";
  };
  return (
    <div
      className="bg-transparent h-screen w-full p-4 text-[#ffe3a3] m-4"
      key={week.weekNumber}
    > {
      repeatEx && <div
      className="fixed h-full w-full inset-0 bg-opacity-30 bg-gray-500  overflow-y-auto z-20"
     
    >
      <div className="flex w-full h-full items-center justify-center p-4">
       <div className="p-4 bg-slate-600 flex flex-col gap-2 justify-center rounded-md">
        <span className="text-lg font-bold">Weeks to repeat the exercise</span>
       <input  onChange={(e)=>{setExes(e.target.value)}} placeholder="eg 1,2,4..." className="p-2 rounded-md focus:outline-none text-gray-700"/>
       <button onClick={()=>{
        handleRepeat()
       }} className="bg-gray-300 bg-opacity-40 rounded p-2">
        Repeate
       </button>
       <button onClick={()=>{setRepeatEx(false)}} className="bg-red-300 bg-opacity-40 rounded p-2">
        Cancel
       </button>
        </div>
        </div>
        </div>
    }
      <div>

        {showAllExercises && (
          <Exercises
            key={week.weekNumber}
            handleAddExercise={handleAddExercise}
            setShowModal={setShowAllExercises}
            showModal={showAllExercises}
            exercises={allExercises}
          />
        )}
        {showModal && (
          <PlayVideo
            key={selectedEx}
            setShowModal={setShowModal}
            showModal={showModal}
            videoUrl={
              allExercises.filter(
                (i) => i._id === exercises[selectedEx].exercise
              )[0].videoUrl
            }
          />
        )}
        <div className="flex justify-between mb-4">
          <h2 className="text-2xl font-bold" style={{color:'#a87732' ,fontWeight:'700'}}>Week {week.weekNumber}</h2>
          <div className="flex items-center gap-2">
          <button
            onClick={() => {
              setRepeatEx(true);
            }}
            style={{ color: "#a87732" }}
            className="bg-gray-300 bg-opacity-40 rounded p-2"
          >
            Repeat Exercise
          </button>
          <button
            onClick={() => {
              setShowAllExercises(true);
            }}
            style={{ color: "#a87732" }}
            className="bg-gray-300 bg-opacity-40 rounded  p-2"
          >
            Add Exercise
          </button>
          </div>
        </div>
        {/* <div className="mb-4">
    <label className="block font-bold mb-2" htmlFor="numExercises">
    Enter Number of Exercises
    </label>
    <input
         min={1}
               className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
               id="numExercises"
               type="number"
               placeholder="Number of Exercises"
               defaultValue={exercises.length}
               onChange={handleNumExercisesChange}
             />
    </div> */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {exercises.map((exercise, exerciseIndex) => (
            <div
              key={exerciseIndex}
              className="text-[#ffe3a3] p-4 rounded-md bg-[#21295c]"
            >
              <h3 className="text-lg font-bold mb-2">
                Exercise {exerciseIndex + 1}
              </h3>
              <div className="mb-4">
                <img
                  onClick={() => {
                    setSelectedEx(exerciseIndex);
                    setShowModal(true);
                  }}
                  src={
                    allExercises.filter((i) => i._id === exercise.exercise)[0]
                      .thumbnailUrl
                  }
                  className="h-56  cursor-pointer"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block font-bold  mb-2"
                  htmlFor={`exerciseName${exerciseIndex}`}
                >
                  {
                    allExercises.filter((i) => i._id === exercise.exercise)[0]
                      .name
                  }
                </label>

                {/* <select
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id={`exerciseName${exerciseIndex}`}
                  type="text"
                  placeholder="Exercise Name"
                  defaultValue={exercise.exercise}
                  onChange={(e) => handleExerciseChange(exerciseIndex, 'exercise', e.target.value)}
                >

                  {
                    allExercises.map((ex, i) => {
                      return <option value={ex._id} key={i}>{ex.name}</option>
                    })
                  }
                </select> */}
              </div>
              <div className="grid  grid-cols-2 gap-4">
                <div className="mb-4">
                  <label
                    className="block font-bold mb-2"
                    htmlFor={`exerciseReps${exerciseIndex}`}
                  >
                    Reps
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id={`exerciseReps${exerciseIndex}`}
                    type="number"
                    placeholder="Reps"
                    min={1}
                    value={exercise.reps}
                    onChange={(e) =>
                      handleExerciseChange(
                        exerciseIndex,
                        "reps",
                        Number.parseInt(e.target.value)
                      )
                    }
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block font-bold mb-2"
                    htmlFor={`exerciseSets${exerciseIndex}`}
                  >
                    Sets
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id={`exerciseSets${exerciseIndex}`}
                    type="number"
                    placeholder="Sets"
                    min={1}
                    value={exercise.sets}
                    onChange={(e) =>
                      handleExerciseChange(
                        exerciseIndex,
                        "sets",
                        Number.parseInt(e.target.value)
                      )
                    }
                  />
                </div>
              </div>
              <button
                onClick={() => {
                  handleRemoveExercise(exercise);
                }}
                className="bg-red-300 bg-opacity-40 rounded w-full p-2"
              >
                Remove Exercise
              </button>
              <button
                onClick={() => {
                  handleExercises(week, exercises);
                }}
                className="bg-gray-300 bg-opacity-40 mt-2 rounded w-full p-2"
              >
                Update Exercise
              </button>
            </div>
          ))}
        </div>
        {/* <button onClick={()=>{ handleExercises(week, exercises)}} style={{marginTop: "12px"}} className="bg-gray-300 bg-opacity-40 rounded px-4 py-2">Add Exercise</button> */}
      </div>
    </div>
  );
};

export default TestMultiWeek;
