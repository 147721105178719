import React from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  SettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  PublicOutlined,
  PointOfSaleOutlined,
  TodayOutlined,
  CalendarMonthOutlined,
  AdminPanelSettingsOutlined,
  TrendingUpOutlined,
  PieChartOutlined,
  Event,
  Subscriptions,
  Medication,
  MedicalInformation,
  AdminPanelSettings,
  SettingsAccessibilityOutlined,
  NextPlan,
  RunCircle,
  AccountCircle,
  Person,
  Handshake,
  ListAlt,
  RequestQuote,
  Inventory,
  LocalOffer,
  MoneyOff,
  HelpCenter,
  Map,
  LunchDining,
  Feedback,
  LocalHospital,
  NoteOutlined,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import profileImage from "assets/profileImage.png";

const navItems = [
  {
    text: "Dashboard",
    icon: <HomeOutlined />,
  },
  //  {
  //   text: "Products",
  //   icon: <ShoppingCartOutlined />,
  // },
  {
    text: "Patients",
    icon: <Groups2Outlined />,
  },
  // {
  //   text: "Doctors",
  //   icon: <Groups2Outlined />,
  // },
  {
    text: "Appointments",
    icon: <Event />,
  },
  {
    text: "Subscriptions",
    icon: <Subscriptions />,
  },
  {
    text: "Prescription",
    icon: <NoteOutlined />,
  },
  // {
  //   text: "Transactions",
  //   icon: <ReceiptLongOutlined />,
  // },

  // {
  //   text: "Diagnostic",
  //   icon: <Medication/>,
  // },

  {
    text: "Doctors",
    icon: <MedicalInformation />,
  },
  {
    text: "Physios",
    icon: <LocalHospital />,
  },

  // {
  //   text: "Admin",
  //   icon: <AdminPanelSettings/>,
  // },
  {
    text: "Referral",
    icon: <SettingsAccessibilityOutlined />,
  },
  {
    text: "Plan",
    icon: <NextPlan />,
  },
  {
    text: "Exercise",
    icon: <RunCircle />,
  },

  // {
  //   text: "Account",
  //   icon: <AccountCircle/>,
  // },
  // {
  //   text: "User",
  //   icon: <Person/>,
  // },
  // {
  //   text: "Partner",
  //   icon: <Handshake/>,
  // },
  {
    text: "Order",
    icon: <ListAlt />,
  },
  // {
  //   text: "Pricing",
  //   icon: <RequestQuote/>,
  // },

  // {
  //   text: "Inventory",
  //   icon: <Inventory/>,
  // },
  {
    text: "Discount",
    icon: <LocalOffer />,
  },
  {
    text: "Coupon",
    icon: <MoneyOff />,
  },
  // {
  //   text: "Question",
  //   icon: <HelpCenter/>,
  // },
  // {
  //   text: "Scenario Map",
  //   icon: <Map/>,
  // },
  // {
  //   text: "Food Item",
  //   icon: <LunchDining/>,
  // },
  {
    text: "Feedback",
    icon: <Feedback />,
  },
];

const Sidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    setActive(pathname.substring(1));
    if (!isNonMobile) {
      setIsSidebarOpen(false);
    }
  }, [pathname]);

  return (
    <Box component='nav'>
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant='persistent'
          anchor='left'
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSixing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}>
          <Box width='100%'>
            <Box m='1.5rem 2.5rem 1rem 3rem'>
              <Box
                color={theme.palette.secondary.main}
                className='flex xxs:flex-col sm:flex-row justify-center items-center'>
                <Box
                  className='flex flex-col items-center justify-center'
                  alignItems='center'
                  gap='0.5rem'>
                  {/* <img style={{scale: "50%"}} src={profileImage} /> */}
                  <Typography
                    variant='h4'
                    fontWeight='bold'
                    sx={{ color: theme.palette.secondary[200] }}
                    className='text-center'>
                    {user.name}
                    <div className='flex justify-center items-center py-2'>
                      {/* <Box 
                  component="img"
                  alt="profile"
                  src={user.photoUrl}
                  borderRadius='100%'
                  sx={{ objectFit: "cover", width: '80px', height: '80px',  }}
                /> */}
                    </div>
                  </Typography>
                  {/* <Typography variant="h8" fontWeight="bold" sx={{color: theme.palette.secondary[100] }}>
                    Kolkata
                  </Typography> */}
                </Box>

                {!isNonMobile ? (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft className='border border-2-bluegray rounded-full' />
                  </IconButton>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
            <List>
              {navItems.map(({ text, icon }) => {
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                      {text}
                    </Typography>
                  );
                }
                const lcText = text.toLowerCase();

                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(`/${lcText}`);
                        setActive(lcText);
                      }}
                      sx={{
                        backgroundColor:
                          active === lcText
                            ? theme.palette.secondary[300]
                            : "transparent",
                        color:
                          active === lcText
                            ? theme.palette.primary[600]
                            : theme.palette.secondary[100],
                      }}>
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[200],
                        }}>
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {active === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>

          <Box
            position='absolute'
            bottom='1.5rem'
            className={`${!isNonMobile ? "hidden" : "visible"}`}>
            <Divider />
            {/* <FlexBetween textTransform="none" gap="1rem" m="2.5rem 2rem 0 3rem">
              <Box
                component="img"
                alt="profile"
                src={user.photoUrl}
                height="40px"
                width="40px"
                borderRadius="50%"
                sx={{ objectFit: "cover" }}
              />
              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.9rem"
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {user.name}
                </Typography>
                <Typography
                  fontSize="0.8rem"
                  sx={{ color: theme.palette.secondary[200] }}
                >
                  {user.rolekey || ''}
                </Typography>
              </Box>
              <SettingsOutlined
                sx={{
                  color: theme.palette.secondary[300],
                  fontSize: "25px ",
                }}
              />
            </FlexBetween> */}
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
